import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ToastComponent } from '../components/toaster/toast/toast.component';
import { PersonaGroupResumedDTO } from '../domains/GroupCollaborator/group-collaborator-dto';

@Injectable({
  providedIn: 'root',
})
export class PersonaService {
  urlBase: string;

  constructor(
    private http: HttpClient,
    private toastComponent: ToastComponent
  ) {
    this.urlBase = environment.bff_web + '/personas';
  }

  async getFilteredPersonasByClient(
    clientIds: number[],
    name: string,
    personaType?: 'COLLABORATOR' | 'THIRD_PARTY',
    monthsLaidOff?: number,
    onlyActive?: boolean
  ): Promise<Array<PersonaGroupResumedDTO>> {
    try {
      const personaResponse: any = await lastValueFrom(
        this.http.get<Array<PersonaGroupResumedDTO>>(this.urlBase, {
          params: {
            clientIds,
            name: name ? name : '',
            personaType: personaType || '',
            monthsLaidOff: monthsLaidOff ? monthsLaidOff : '',
            onlyActive: onlyActive ?? true,
          },
        })
      );

      if (personaResponse) {
        return personaResponse;
      }
    } catch (error) {
      console.error(error);
    }
    throw new Error();
  }
}
