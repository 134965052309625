<app-search-table 
tableTitle="Usuários Cliente" 
addButtonLabel="Novo Usuário" 
[tableSourceData]="dataSource"
[columnsConfigurations]="displayedColumns" 
[selectConfiguration]="selectClient" 
(onSelect)="getUserClient($event)"
[deleteDialogInfo]="dialogInfo" 
[changeSituationDialogInfo]="changeSituationDialogInfo" 
[pageLength]="totalElements"
[pageIndex]="pageIndex" 
(onAddButtonClick)="redirectToNewUser()" 
(onFilter)="applyFilter($event)"
(onPageCount)="onPageChange($event)" 
(onEditButtonClick)="redirectToEditUser($event)"
(onViewButtonClick)="redirectToViewUser($event)" 
(sortDirection)="sortingTable($event)"
[menuOptionModel]="menuOptions" 
[disableToggleButton]="!canChangeSituation" 
[disableAddButton]="!canCreate || !selectedClient.isActive"
[disableDeleteButton]="!canDelete" [disableEditButton]="!canUpdate">
</app-search-table>

<div class="container-fluid d-sm-none">
  <div style="margin: 0px 10px 10px 10px">
    <div class="row">
      <div class="col-12">
        <ng-container>
          <div class="mobile-session" *ngFor="let user of dataSource.data; let i = index">
            <div class="card-row-mobile" *ngIf="paginationCard > i"
              (click)="canUpdate ? redirectToEditUser(user) : redirectToViewUser(user)">
              <div class="card-mobile-content">
                <div class="row margin-btm">
                  <label class="paragraph paragraph-1 semibold truncate-words">{{
                    selectedClient.tradingName
                    }}</label>
                </div>
                <div class="row margin-btm">
                  <label class="paragraph paragraph-1 truncate-words">{{ user.name }}</label>
                </div>
                <div class="row margin-btm">
                  <label class="paragraph paragraph-1 truncate-words">CPF: {{ user.cpf | mask : "000.000.000-00"
                    }}</label>
                </div>
                <div class="row margin-btm">
                  <label class="paragraph paragraph-1 truncate-words">{{ user.email }}</label>
                </div>

                <div class="row margin-btm">
                  <label class="paragraph paragraph-1 truncate-words">Último acesso: {{ user.lastLogin ?  user.lastLogin : "Nenhum acesso registrado" }} </label>
                </div>
              </div>
            </div>
          </div>
          <div class="row" class="pagination-card" *ngIf="dataSource.data.length >= paginationCard">
            <label class="paragraph paragraph-2 semibold primary-color" (click)="getUserClientMobileVision()">Mostrar
              mais</label>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>

<app-confirm-modal #confirmModal *ngIf="showConfirmModal"></app-confirm-modal>

<app-user-group-modal *ngIf="showUserGroupsModal" 
[visible]="showUserGroupsModal"
(onClose)="showUserGroupsModal = false" 
[user]="selectedUserControl.value"
></app-user-group-modal>

<app-user-client-profile-modal *ngIf="showUserProfilesModal" 
[visible]="showUserProfilesModal"
(onClose)="showUserProfilesModal = false" 
[user]="selectedUserControl.value"
></app-user-client-profile-modal>