import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, lastValueFrom, map, Observable, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ToastComponent } from '../../components/toaster/toast/toast.component';
import {
  ClientGroupPage,
  GroupUserClientCombobox,
  UserClientGroupRequest,
  UserClientGroupResponse,
} from '../../domains/user-client-group/user-client-group';

@Injectable({
  providedIn: 'root',
})
export class UserClientGroupService {
  urlBase: string;

  constructor(
    private http: HttpClient,
    private toastComponent: ToastComponent
  ) {
    this.urlBase = environment.bff_web + '/users-clients-groups';
  }

  async getAllGroupsPage(
    page: number,
    size: number,
    clientId?: number,
    search?: string,
    userId?: number
  ): Promise<ClientGroupPage> {
    try {
      const groups = await lastValueFrom(
        this.http.get<ClientGroupPage>(this.urlBase, {
          params: {
            page: page,
            pageSize: size,
            clientId: clientId ?? '',
            pesquisa: search ? search : '',
            userId: userId ?? '',
          },
        })
      );

      if (groups) {
        return groups;
      }
    } catch (error) {
      console.error(error);
    }
    throw new Error();
  }

  async getAllByUserId(
    userId: number
  ): Promise<Array<GroupUserClientCombobox>> {
    try {
      const groups = await lastValueFrom(
        this.http.get<Array<GroupUserClientCombobox>>(
          `${this.urlBase}/user/${userId}`
        )
      );
      if (groups) {
        return groups;
      }
    } catch (error) {
      console.error(error);
    }
    throw new Error();
  }

  async getAllByUserClientId(
    id: number
  ): Promise<Array<GroupUserClientCombobox>> {
    try {
      const groups = await lastValueFrom(
        this.http.get<Array<GroupUserClientCombobox>>(
          `${this.urlBase}/user-client/${id}`
        )
      );
      if (groups) {
        return groups;
      }
    } catch (error) {
      console.error(error);
    }
    throw new Error();
  }

  async getAllByUserIdAndUserClientId(
    userClientId: number | null,
    clientId: number
  ): Promise<Array<GroupUserClientCombobox>> {
    try {

      let params = {};
      if (userClientId !== null) {
        params = { ...params , userClientId: userClientId };
      }

      const groups = await lastValueFrom(
        this.http.get<Array<GroupUserClientCombobox>>(
          `${this.urlBase}/all-client-groups/${clientId}`,
          {
            params
          }
        )
      );
      if (groups) {
        return groups;
      }
    } catch (error) {
      console.error(error);
    }
    throw new Error();
  }

  async registerGroup(
    group: UserClientGroupRequest,
    image: File
  ): Promise<boolean> {
    try {
      const formData = new FormData();

      formData.append(
        'group',
        new Blob([JSON.stringify(group)], { type: 'application/json' })
      );

      formData.append('image', image);

      const success = await lastValueFrom(
        this.http.post(this.urlBase, formData)
      );

      if (success) {
        return true;
      }
    } catch (error) {
      this.toastComponent.showApiError(error);
      throw new Error();
    }

    return false;
  }

  async updateGroup(
    group: UserClientGroupRequest,
    image?: File
  ): Promise<boolean> {
    try {
      const formData = new FormData();

      formData.append(
        'group',
        new Blob([JSON.stringify(group)], { type: 'application/json' })
      );

      if (image) {
        formData.append('image', image);
      }
      const success = await lastValueFrom(
        this.http.put(this.urlBase + `/${group.id}`, formData)
      );

      if (success) {
        return true;
      }
    } catch (error) {
      this.toastComponent.showApiError(error);
      throw new Error();
    }

    return false;
  }

  findById(
    id: number,
    showUserData: boolean = false
  ): Observable<UserClientGroupResponse> {
    return this.http
      .get<UserClientGroupResponse>(
        this.urlBase + `/${id}?showUserData=${showUserData}`
      )
      .pipe(
        catchError((error) => {
          this.toastComponent.showApiError(error);
          return throwError(() => new Error(error));
        }),
        map((response) => response)
      );
  }

  async delete(id: number): Promise<boolean> {
    try {
      const success: HttpResponse<any> = await lastValueFrom(
        this.http.delete(this.urlBase + `/${id}`, {
          observe: 'response',
        })
      );
      if (success.status == 204) {
        return true;
      }
    } catch (error) {
      this.toastComponent.showApiError(error);
      throw new Error();
    }
    return false;
  }

  async changeSituationByGroupId(
    id: number,
    situation: boolean
  ): Promise<boolean> {
    let params = new HttpParams();
    params = params.set('situation', situation);

    try {
      await lastValueFrom(
        this.http.put(this.urlBase + `/change-situation?ids=${id}`, params)
      );
      return true;
    } catch (error) {
      console.error(error);
      throw new Error();
    }
  }

  async setFavorite(groupId: number, userId: number): Promise<boolean> {
    try {
      const success = await lastValueFrom(
        this.http.put(
          this.urlBase + `/${groupId}/change-user-favorite-group/${userId}`,
          {
            observe: 'response',
          }
        )
      );
      if (success) {
        return true;
      }
    } catch (error) {
      this.toastComponent.showApiError(error);
      throw new Error();
    }
    return false;
  }
}
