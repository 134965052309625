import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { NgxPermissionsService } from 'ngx-permissions';
import { map } from 'rxjs';
import { ProfilePermissions } from 'src/app/shared/constants/profile-permissions';
import { SearchColumnConfiguration } from '../../../shared/components/search/interfaces/search-table-column-configuration.interface';
import { Page } from '../../../shared/domains/base-page.interface';
import { ReportLogResponse } from '../../../shared/domains/report/report';
import { ReportService } from '../../../shared/services/report.service';
@Component({
  selector: 'app-report-log-view',
  templateUrl: './report-log-view.component.html',
  styleUrls: ['./report-log-view.component.scss'],
})
export class ReportLogViewComponent implements OnInit {
  dataSourceIR = new MatTableDataSource<any>();
  dataSourcePayslip = new MatTableDataSource<any>();
  totalElementsIR: number = 0;
  totalElementsPayslip: number = 0;
  pageIndexIR: number = 0;
  pageIndexPayslip: number = 0;
  pageLengthIR: number = 10;
  pageLengthPayslip: number = 10;
  paginationIRCard: number = 10;
  paginationPayslipCard: number = 10;

  clientId!: number;

  canReadIR: boolean = false;
  canReadPayslip: boolean = false;

  columnsIR: SearchColumnConfiguration[] = [
    {
      columnName: 'Nome',
      valueName: 'name',
      columnValueCssClass: 'column-20',
    },
    {
      columnName: 'Mensagem',
      valueName: 'message',
      columnValueCssClass: 'column-20',
    },
    {
      columnName: 'Ano',
      valueName: 'yearOrPaymentCode',
      columnValueCssClass: 'column-10',
    },
    {
      columnName: 'Colaboradores com erro',
      valueName: 'collaboratorError',
      columnValueCssClass: 'column-20',
    },
    {
      columnName: 'Data de criação',
      valueName: 'createdAtLabel',
      columnValueCssClass: 'column-20',
    },
    {
      columnName: 'Status',
      valueName: 'processStatusLabel',
      columnValueCssClass: 'column-10',
    },
  ];

  columnsPayslip: SearchColumnConfiguration[] = [
    {
      columnName: 'Nome',
      valueName: 'name',
      columnValueCssClass: 'column-20',
    },
    {
      columnName: 'Mensagem',
      valueName: 'message',
      columnValueCssClass: 'column-20',
    },
    {
      columnName: 'Cálculo',
      valueName: 'yearOrPaymentCode',
      columnValueCssClass: 'column-10',
    },
    {
      columnName: 'Colaboradores com erro',
      valueName: 'collaboratorError',
      columnValueCssClass: 'column-20',
    },
    {
      columnName: 'Data de criação',
      valueName: 'createdAtLabel',
      columnValueCssClass: 'column-20',
    },
    {
      columnName: 'Status',
      valueName: 'processStatusLabel',
      columnValueCssClass: 'column-10',
    },
  ];

  constructor(
    private reportService: ReportService,
    private route: ActivatedRoute,
    private permissionsService: NgxPermissionsService,
    private datePipe: DatePipe
  ) {
    this.route.queryParams
      .pipe(map((params) => params['clientId']))
      .subscribe((params) => {
        this.clientId = params;
      });
  }

  async ngOnInit() {
    this.canReadIR = await this.permissionsService.hasPermission(
      ProfilePermissions.CONFIGURATION_INTEGRATE_INCOME_CLIENT
    );
    this.canReadPayslip = await this.permissionsService.hasPermission(
      ProfilePermissions.CONFIGURATION_INTEGRATE_PAYSLIP_CLIENT
    );
    if (this.canReadIR)
      this.getLogs(this.pageIndexIR, this.pageLengthIR, 'INCOME');
    if (this.canReadPayslip)
      this.getLogs(
        this.pageIndexPayslip,
        this.pageLengthPayslip,
        'PAYSLIP'
      );
  }

  getLogs(page: number, size: number, type: 'INCOME' | 'PAYSLIP') {
    this.reportService
      .getLogs([this.clientId], type, page, size)
      .subscribe((response: Page<ReportLogResponse>) => {
        switch (type) {
          case 'INCOME':
            this.dataSourceIR = new MatTableDataSource(
              this._mapLog(response.content)
            );
            break;
          case 'PAYSLIP':
            this.dataSourcePayslip = new MatTableDataSource(
              this._mapLog(response.content)
            );
            break;
        }
      });
  }

  private _mapLog(reports: ReportLogResponse[]) {
    return reports.map((report) => {
      let processStatusLabel = '';
      switch (report.processStatusType) {
        case 'PROCESSED':
          processStatusLabel = 'Processado';
          break;
        case 'IN_PROCESS':
          processStatusLabel = 'Em processo';
          break;
        case 'ERROR':
          processStatusLabel = 'Erro';
          break;
        default:
          processStatusLabel = 'Status não identificado';
          break;
      }
      return {
        ...report,
        processStatusLabel,
        createdAtLabel: this.formatTimestamp(report.createdAt),
        collaboratorError: new String(report.collaboratorError)
      };
    });
  }

  getLogsMobileIR() {
    this.paginationIRCard += 10;
    this.getLogs(0, this.paginationIRCard, 'INCOME');
  }

  getLogsMobilePayslip() {
    this.paginationPayslipCard += 10;
    this.getLogs(0, this.paginationPayslipCard, 'PAYSLIP');
  }

  formatTimestamp(timestamp: string | null): string {
    if (timestamp) {
      return this.datePipe.transform(timestamp, 'dd/MM/yyyy') || '';
    } else {
      return '';
    }
  }

  onPageChangeIR(pageEvent: PageEvent) {
    this.pageLengthIR = pageEvent.pageSize;
    this.getLogs(this.pageIndexIR, this.pageLengthIR, 'INCOME');
  }

  onPageChangePayslip(pageEvent: PageEvent) {
    this.pageLengthPayslip = pageEvent.pageSize;
    this.getLogs(this.pageIndexPayslip, this.pageLengthPayslip, 'PAYSLIP');
  }
}
