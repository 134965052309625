<div class="container-fluid">
  <div style="margin: 10px; margin-top: 20px">
    <div class="row">
      <div class="col-9 col-sm-4">
        <label class="titles title-2 semibold extended-secondary-darker">Importação de Dados</label>
      </div>
    </div>

    <div class="row align-items-center" style="margin-top: 16px">
      <div class="col-12 col-sm-3">
        <app-client-auto-complete (onSelectClient)="selectClient($event.id)"></app-client-auto-complete>
      </div>
      <div class="col-12 col-sm-3">
        <mat-form-field appearance="outline" class="mat-input">
          <mat-icon matPrefix class="icon-search">search</mat-icon>
          <mat-label class="paragraph paragraph-2 mat-label">Pesquisar</mat-label>
          <input matInput [formControl]="searchDataImportFormControl" />
        </mat-form-field>
      </div>

      <div class="col-12 col-sm-1 mb-3" *ngIf="!globalFunctions.isMobile()">
        <button mat-button class="btn btn-tertiary subtitle subtitle-1 semibold primary-color d-flex justify-content-center w-auto" (click)="recarregarDados()" pTooltip="Atualizar Tabela" tooltipPosition="top">
          <mat-icon>autorenew</mat-icon>
        </button>
      </div>

      <div class="col-12 col-sm-5 d-flex align-items-center mb-3" [ngClass]="globalFunctions.isMobile() ? 'justify-content-start' : 'justify-content-end'">
        <div>
          <button mat-button class="btn btn-secondary subtitle subtitle-1 semibold primary-color d-flex justify-content-center w-auto mr-2"
            (click)="downloadFileModel()">
            Baixar Modelo
          </button>
        </div>
        <div>
          <button mat-button class="btn btn-primary subtitle subtitle-1 semibold white w-auto"
            (click)="fileInput.click()" *ngIf="!globalFunctions.isMobile()">
            Importar
          </button>
          <input type="file" #fileInput style="display: none" (change)="importFiles($event)" accept=".txt" multiple/>
        </div>
        <div class="mt-1" *ngIf="!globalFunctions.isMobile()">
            <app-custom-tooltip
            [text]="'Formato: TXT.\nTamanho máximo: 15MB.'"
            [tooltipStyleClass]="'info-img-tp'"
            [tooltipPosition]="'top'"
            [styleTooltip]="'margin-top: -3rem;'"
            [styleResponsive]="'margin-left: -45vw'">
            </app-custom-tooltip>
        </div>
        <div *ngIf="globalFunctions.isMobile()">
          <button mat-button class="btn btn-tertiary subtitle subtitle-1 semibold primary-color ml-2" (click)="recarregarDados()" pTooltip="Atualizar Tabela" tooltipPosition="top">
            <mat-icon>autorenew</mat-icon>
          </button>
        </div>
      </div>
      <div class="col-sm-1"></div>
    </div>

    <div class="row">
      <div class="col-12">
        <ng-container *ngIf="!globalFunctions.isMobile()">
          <table mat-table #dataImportTableSort="matSort" [dataSource]="dataImportDataSource" class="mat-elevation-z8"
            w-100 matSort>
            <ng-container matColumnDef="fileName">
              <th mat-header-cell *matHeaderCellDef mat-sort-header class="column-17">
                Nome do Arquivo
              </th>
              <td mat-cell *matCellDef="let dataImport" class="column-10 text-ellipsis truncate-words"
              [pTooltip]="dataImport.fileName" tooltipPosition="top">
                {{ dataImport.fileName }}
              </td>
            </ng-container>

            <ng-container matColumnDef="userName">
              <th mat-header-cell *matHeaderCellDef mat-sort-header class="column-20">
                Usuário
              </th>
              <td mat-cell *matCellDef="let dataImport" class="column-10 text-ellipsis" [pTooltip]="dataImport.userName" tooltipPosition="top">
                {{ dataImport.userName }}
              </td>
            </ng-container>

            <ng-container matColumnDef="createdDate">
              <th mat-header-cell *matHeaderCellDef mat-sort-header class="column-15">
                Data e Hora Registro
              </th>
              <td mat-cell *matCellDef="let dataImport" class="column-15" [pTooltip]="globalFunctions.formatDate(dataImport.createdDate)" tooltipPosition="top">
                {{ dataImport.createdDate | date : "dd/MM/yyyy HH:mm:ss" }}
              </td>
            </ng-container>

            <ng-container matColumnDef="importStart">
              <th mat-header-cell *matHeaderCellDef mat-sort-header class="column-15">
                Data e Hora Início
              </th>
              <td mat-cell *matCellDef="let dataImport" class="column-15"  [pTooltip]="globalFunctions.formatDate(dataImport.importStart)" tooltipPosition="top">
                {{ dataImport.importStart | date : "dd/MM/yyyy HH:mm:ss" }}
              </td>
            </ng-container>

            <ng-container matColumnDef="importEnd">
              <th mat-header-cell *matHeaderCellDef mat-sort-header class="column-15">
                Data e Hora Fim
              </th>
              <td mat-cell *matCellDef="let dataImport" class="column-15"  [pTooltip]="globalFunctions.formatDate(dataImport.importEnd)" tooltipPosition="top">
                {{ dataImport.importEnd | date : "dd/MM/yyyy HH:mm:ss" }}
              </td>
            </ng-container>

            <ng-container matColumnDef="status">
              <th mat-header-cell *matHeaderCellDef mat-sort-header class="column-15">
                Situação
              </th>
              <td mat-cell *matCellDef="let dataImport" class="column-15">
                <mat-chip-list>
                  <mat-chip class="custom-chip-size" disabled [ngClass]="getStyleObject(dataImport.status)">
                    {{ getLabel(dataImport.status) }}
                  </mat-chip>
                </mat-chip-list>
              </td>
            </ng-container>

            <ng-container matColumnDef="actions">
              <th mat-header-cell *matHeaderCellDef class="column-8"></th>
              <td mat-cell *matCellDef="let dataImport" class="column-18 p-0 d-flex justify-content-end">
                <button *ngIf="dataImport.urlReviewFile && dataImport.allowDownload" mat-icon-button (click)="
                    downloadErrorFile(
                      dataImport.fileName,
                      dataImport.urlReviewFile
                    )
                  " [matTooltip]="dataImport.allowDownload ? 'Críticas' : ''" tooltipPosition="top">
                  <mat-icon class="material-icons-outlined"
                    [ngClass]="{'icon-button-error':dataImport.allowDownload}">download</mat-icon>
                </button>
                <button *ngIf="dataImport.allowDownload"
                    mat-icon-button (click)="
                    downloadImportedFile(
                      dataImport.fileName,
                      dataImport.urlImportFile
                    )
                  " [matTooltip]="dataImport.allowDownload ? 'Importado' : ''" tooltipPosition="top">
                  <mat-icon class="material-icons-outlined"
                    [ngClass]="{'icon-secondary-color':dataImport.allowDownload}">download</mat-icon>
                </button>
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
          </table>
          <mat-paginator [pageSize]="10" [pageSizeOptions]="[5, 10, 25, 100]" [showFirstLastButtons]="true"
            style="margin-bottom: 100px">
          </mat-paginator>
        </ng-container>

        <ng-container *ngIf="globalFunctions.isMobile()">
          <div class="mobile-session" *ngFor="let obj of dataImportDataSource.data; let i = index;">
            <div class="card-row-mobile" *ngIf="paginationCard > i">
              <div class="card-mobile-content">
                <div class="row margin-btm first-row">
                  <label class="paragraph paragraph-1 semibold truncate-words">{{ obj.fileName }}</label>
                </div>
                <div class="row margin-btm">
                  <label class="paragraph paragraph-1">{{ obj.userName }}</label>
                </div>
                <div class="row margin-btm">
                  <label class="paragraph paragraph-1">{{ obj.createdDate | date : "dd/MM/yyyy HH:mm:ss" }}</label>
                </div>
                <div class="row margin-btm" style="margin-top: 6px;">
                  <div class="col-6" style="display: flex; margin-left: -15px;">
                    <mat-chip-list>
                      <mat-chip class="custom-chip-size" [ngClass]="getStyleObject(obj.status)">{{
                        getLabel(obj.status) }}
                      </mat-chip>
                    </mat-chip-list>
                  </div>
                  <div class="col-2">
                      <button *ngIf="obj.urlReviewFile" mat-icon-button (click)="
                      downloadErrorFile(
                        obj.fileName,
                        obj.urlReviewFile
                      )
                    " [matTooltip]="obj.allowDownload ? 'Críticas' : ''" tooltipPosition="top"
                    [disabled]="!obj.allowDownload">
                    <mat-icon class="material-icons-outlined"
                    [ngClass]="{'icon-button-error':obj.allowDownload}">download</mat-icon>
                    </button>
                  </div>
                  <div class="col-2">
                        <button mat-icon-button (click)="
                        downloadImportedFile(
                          obj.fileName,
                          obj.urlImportFile
                        )
                      " [matTooltip]="obj.allowDownload ? 'Importado' : ''" tooltipPosition="top"
                      [disabled]="!obj.allowDownload">
                      <mat-icon class="material-icons-outlined"
                        [ngClass]="{'icon-secondary-color':obj.allowDownload}">download</mat-icon>
                      </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row" class="pagination-card" *ngIf="dataImportDataSource.data.length > paginationCard">
            <label class="paragraph paragraph-2 semibold primary-color"
              (click)="paginationCard = paginationCard + 10;">Mostrar mais</label>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>

<app-confirm-modal #confirmModal></app-confirm-modal>
