import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { NgxPermissionsService, NgxRolesService } from 'ngx-permissions';
import { ClientUserComponent } from 'src/app/pages/client/main/client-user.component';
import { ConfigIntegrationComponent } from 'src/app/pages/client/new-client/config-integration/config-integration.component';
import { NewClientComponent } from 'src/app/pages/client/new-client/new-client.component';
import { ReportLogViewComponent } from 'src/app/pages/client/report-log-view/report-log-view.component';
import { ProfilePermissions } from '../constants/profile-permissions';
import { AdminGuard } from './admin.guard';

@Injectable({
  providedIn: 'root',
})
export class ClientGuard implements CanActivateChild, CanActivate {
  constructor(
    private permissionsService: NgxPermissionsService,
    private adminGuard: AdminGuard,
    private rolesService: NgxRolesService
  ) {}

  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean | UrlTree | Promise<boolean | UrlTree> {
    if (this.adminGuard.canActivate()) {
      return true;
    }

    if (childRoute.component == ClientUserComponent) {
      return this.permissionsService.hasPermission(
        ProfilePermissions.READ_CLIENT
      );
    }

    if (
      childRoute.component == NewClientComponent &&
      state.url.includes('edit-client')
    ) {
      return this.permissionsService.hasPermission(
        ProfilePermissions.UPDATE_CLIENT
      );
    }

    if (
      childRoute.component == NewClientComponent &&
      state.url.includes('view-client')
    ) {
      return this.permissionsService.hasPermission(
        ProfilePermissions.READ_CLIENT
      );
    }

    if (
      childRoute.component == ConfigIntegrationComponent &&
      state.url.includes('config-integration')
    ) {
      return this.permissionsService.hasPermission(
        ProfilePermissions.CONFIGURATION_INTEGRATION_CLIENT
      );
    }

    if (
      childRoute.component == ReportLogViewComponent &&
      state.url.includes('logs')
    ) {
      return (
        this.permissionsService.hasPermission(
          ProfilePermissions.CONFIGURATION_INTEGRATE_INCOME_CLIENT
        ) ||
        this.permissionsService.hasPermission(
          ProfilePermissions.CONFIGURATION_INTEGRATE_PAYSLIP_CLIENT
        )
      );
    }

    return false;
  }

  canActivate(): boolean | Promise<boolean> {
    if (this.adminGuard.canActivate()) {
      return true;
    }

    if (this.rolesService.getRole('CLIENT')) {
      return this.permissionsService.hasPermission(
        ProfilePermissions.READ_CLIENT
      );
    }

    return false;
  }
}
