<p-dialog [(visible)]="visible" [style]="{width: '800px', height: '350px'}" class="medium-l" [modal]="true"
[draggable]="false" [resizable]="false" [ngClass]="{'medium-mobile': globalFunctions.isMobile()}" (onHide)="close()">
  <ng-template pTemplate="header" class="mt-3 py-0">
    <span class="pl-2 subtitle subtitle-2 semibold mt-2">
      Perfis associados
    </span>
  </ng-template>
  <div class="row">
    <div class="col-12">
      <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" style="border-bottom: none !important" w-100 matSort>
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef class="column-90 semibold extended-secondary-darker header-content">
            Nome
          </th>
          <td mat-cell *matCellDef="let profile" class="column-90">
            <div class="d-flex justify-content-between pr-5 column-category truncate-words">
              <label>
                {{ profile.name }}
              </label>
            </div>
          </td>
        </ng-container>
        <ng-container matColumnDef="isActive">
          <th mat-header-cell *matHeaderCellDef class="column-10 semibold extended-secondary-darker header-content">
            Situação
          </th>
          <td mat-cell *matCellDef="let profile" class="column-10">
            <div class="d-flex align-items-center">
              <input type="checkbox" class="switchButton" [(ngModel)]="profile.isActive" disabled/>
              <label class="paragraph paragraph-1 label-st">
                {{ profile.isActive ? "Ativo" : "Inativo" }}
              </label>
            </div>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
    </div>
  </div>
</p-dialog>