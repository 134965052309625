<div class="container-fluid">
  <div class="margin-15 titles title-2 semibold extended-secondary-darker">
    Visualização de Logs
  </div>
  <mat-tab-group>
    <mat-tab label="Informe de rendimentos" *ngIf="canReadIR">
      <div class="col-sm-4 col-lg-2 mt-4">
        <button (click)="getLogs(0,pageLengthIR, 'INCOME')" mat-button class="btn btn-primary subtitle subtitle-1 semibold white">
          <mat-icon>replay</mat-icon> Atualizar
        </button>
      </div>
      <app-search-table
        tableTitle=""
        [showAddButton]="false"
        [tableSourceData]="dataSourceIR"
        [columnsConfigurations]="columnsIR"
        [pageLength]="totalElementsIR"
        [pageIndex]="pageIndexIR"
        [showSearchComponent]="false"
        [disableAddButton]="true"
        [disableDeleteButton]="true"
        [disableEditButton]="true"
        [disableToggleButton]="true"
        (onPageCount)="onPageChangeIR($event)"
        widthMobileVersion="xl"
      >
      </app-search-table>
      <div class="container-fluid d-xl-none">
        <div style="margin: 0px 10px 10px 10px">
          <div class="row">
            <div class="col-12">
              <ng-container>
                <div
                  class="mobile-session"
                  *ngFor="let log of dataSourceIR.data; let i = index"
                >
                  <div class="card-mobile" *ngIf="paginationIRCard > i">
                    <div class="margin-btm">
                      <label
                        class="paragraph paragraph-1 semibold truncate-line"
                        >Nome: {{ log.name }}</label
                      >
                    </div>
                    <div class="margin-btm">
                      <label class="paragraph paragraph-1 truncate-line"
                        >Mensagem: {{ log.message }}</label
                      >
                    </div>
                    <div class="margin-btm">
                      <label class="paragraph paragraph-1 truncate-line"
                        >Ano: {{ log.yearOrPaymentCode }}</label
                      >
                    </div>
                    <div class="margin-btm">
                      <label class="paragraph paragraph-1 truncate-line"
                        >Colaboradores com erro:
                        {{ log.collaboratorError }}</label
                      >
                    </div>
                    <div class="margin-btm">
                      <label class="paragraph paragraph-1 truncate-line"
                        >Data de criação: {{ log.createdAtLabel }}</label
                      >
                    </div>
                    <div class="margin-btm">
                      <label class="paragraph paragraph-1 truncate-line"
                        >Status: {{ log.processStatusLabel }}</label
                      >
                    </div>
                  </div>
                </div>
                <div
                  class="row"
                  class="pagination-card"
                  *ngIf="totalElementsIR > paginationIRCard"
                >
                  <label
                    class="paragraph paragraph-2 semibold primary-color"
                    (click)="getLogsMobileIR()"
                    >Mostrar mais</label
                  >
                </div>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
    </mat-tab>
    <mat-tab label="Holerite" *ngIf="canReadPayslip">
      <div class="col-sm-4 col-lg-2 mt-4">
        <button (click)="getLogs(0,pageLengthPayslip, 'PAYSLIP')" mat-button class="btn btn-primary subtitle subtitle-1 semibold white">
          <mat-icon>replay</mat-icon> Atualizar
        </button>
      </div>
      <app-search-table
        tableTitle=""
        [showAddButton]="false"
        [tableSourceData]="dataSourcePayslip"
        [columnsConfigurations]="columnsPayslip"
        [pageLength]="totalElementsPayslip"
        [pageIndex]="pageIndexPayslip"
        [showSearchComponent]="false"
        [disableAddButton]="true"
        [disableDeleteButton]="true"
        [disableEditButton]="true"
        [disableToggleButton]="true"
        (onPageCount)="onPageChangePayslip($event)"
        widthMobileVersion="xl"
      >
      </app-search-table>
      <div class="container-fluid d-xl-none">
        <div style="margin: 0px 10px 10px 10px">
          <div class="row">
            <div class="col-12">
              <ng-container>
                <div
                  class="mobile-session"
                  *ngFor="let log of dataSourcePayslip.data; let i = index"
                >
                <div class="card-mobile" *ngIf="paginationIRCard > i">
                  <div class="margin-btm">
                    <label
                      class="paragraph paragraph-1 semibold truncate-line"
                      >Nome: {{ log.name }}</label
                    >
                  </div>
                  <div class="margin-btm">
                    <label class="paragraph paragraph-1 truncate-line"
                      >Mensagem: {{ log.message }}</label
                    >
                  </div>
                  <div class="margin-btm">
                    <label class="paragraph paragraph-1 truncate-line"
                      >Código: {{ log.yearOrPaymentCode }}</label
                    >
                  </div>
                  <div class="margin-btm">
                    <label class="paragraph paragraph-1 truncate-line"
                      >Colaboradores com erro:
                      {{ log.collaboratorError }}</label
                    >
                  </div>
                  <div class="margin-btm">
                    <label class="paragraph paragraph-1 truncate-line"
                      >Data de criação: {{ log.createdAtLabel }}</label
                    >
                  </div>
                  <div class="margin-btm">
                    <label class="paragraph paragraph-1 truncate-line"
                      >Status: {{ log.processStatusLabel }}</label
                    >
                  </div>
                </div>
                </div>
                <div
                  class="row"
                  class="pagination-card"
                  *ngIf="totalElementsPayslip > paginationPayslipCard"
                >
                  <label
                    class="paragraph paragraph-2 semibold primary-color"
                    (click)="getLogsMobilePayslip()"
                    >Mostrar mais</label
                  >
                </div>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
    </mat-tab>
  </mat-tab-group>
</div>
