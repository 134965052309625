const clientSufix = 'CLIENT'; //Dados do Cliente
const clientUserSufix = 'CLIENT_USER'; //Usuários Clientes
const profileSufix = 'CLIENT_USER_PROFILE'; //Perfil de Usuários Clientes
const collaboratorSufix = 'COLLABORATOR'; //Colaborador
const benefitSufix = 'BENEFIT'; //Benefícios
const importSufix = 'IMPORT'; //Importação
const networkSufix = 'NETWORK';
const noticeSufix = 'NOTICE'; //Avisos
const clientNoticeSufix = 'CLIENT_NOTICE'; //Avisos cliente
const partnerSufix = 'PARTNER'; //Parceiros
const postSufix = 'POST'; //Posts
const deleteComment = 'DELETE_COMMENT'; //Posts
const readPrefix = 'READ'; //Visualizar
const createPrefix = 'CREATE'; //Incluir
const deletePrefix = 'DELETE'; //Excluir
const updatePrefix = 'UPDATE'; //Editar
const changeSituationPrefix = 'CHANGE_SITUATION'; //Ativar/Inativar
const availablePrefix = 'AVAILABLE_NOTICES'; // Avisos disponíveis
const surveySufix = 'SURVEY'; //Surveys
const eventSufix = 'EVENT'; //Eventos
const collaboratorGroupSufix = 'COLLABORATOR_GROUP'; //Grupo de usuários
const engajamento = 'GAMIFICATION'; //engajamento
const myAccountSufix = 'MY_ACCOUNT';
const resetPassWordPrefix = 'RESET_PASSWORD';
const partnerIntegration = 'PARTNERINTEGRATION';
const campaignPrefix = 'CAMPAIGN';
const rewardPrefix = 'REWARD';
const managementRewardPrefix = 'REWARD_MANAGEMENT';
const configurationPrefix = 'INTEGRATION_CONFIGURATION';
const integrateIcomePrefix = 'GENERATE_INCOME_REPORT';
const integratePayslipPrefix = 'GENERATE_PAYSLIP_REPORT';
const userGroupSufix = 'USER_GROUP'; //Grupo de usuários
const categorySufix = 'CATEGORY';
const rewardsSufix = 'REWARDS';
const collaboratorTransferSufix = 'COLLABORATOR_TRANSFER'; //Grupo de usuários
const communityForumSufix = 'COMMUNITY_FORUM'; //Fórum da Comunidade
const reportPrefix = 'REPORT'; //Denunciar
const thirdPartySufix = 'THIRD_PARTY'; //Terceiros
const usefulLinksSufix = 'USEFUL_LINK'; //Links Úteis
const documentRepositorySufix = 'DOCUMENT'; //Repositório de Documentos
const quotationPrefix = 'QUOTATION'; //Cotação
const adventurePrefix = 'ADVENTURE'; //Aventuras
const managementAdventurePrefix = 'ADVENTURE_MANAGEMENT'; //Gerencia de aventuras
const walletPrefix = 'WALLET'; //Carteira

export const ProfilePermissions = {
  //Dados do Cliente
  READ_CLIENT: `${readPrefix}:${clientSufix}`,
  UPDATE_CLIENT: `${updatePrefix}:${clientSufix}`,
  CONFIGURATION_INTEGRATION_CLIENT: `${configurationPrefix}:${clientSufix}`,
  CONFIGURATION_INTEGRATE_INCOME_CLIENT: `${integrateIcomePrefix}:${clientSufix}`,
  CONFIGURATION_INTEGRATE_PAYSLIP_CLIENT: `${integratePayslipPrefix}:${clientSufix}`,

  //Usuários Clientes
  READ_CLIENT_USER: `${readPrefix}:${clientUserSufix}`,
  CREATE_CLIENT_USER: `${createPrefix}:${clientUserSufix}`,
  UPDATE_CLIENT_USER: `${updatePrefix}:${clientUserSufix}`,
  PROFILE_ASSOCIATION_USER_CLIENT: `PROFILE_ASSOCIATION:${clientUserSufix}`,
  CHANGE_SITUATION_CLIENT_USER: `${changeSituationPrefix}:${clientUserSufix}`,
  DELETE_CLIENT_USER: `${deletePrefix}:${clientUserSufix}`,
  RESET_PASSWORD_CLIENT_USER: `RESET_PASSWORD:${clientUserSufix}`,

  //Perfil de Usuários Clientes
  READ_CLIENT_USER_PROFILE: `${readPrefix}:${profileSufix}`,
  CREATE_CLIENT_USER_PROFILE: `${createPrefix}:${profileSufix}`,
  UPDATE_CLIENT_USER_PROFILE: `${updatePrefix}:${profileSufix}`,
  LINKED_USERS_CLIENT_USER_PROFILE: `LINKED_USERS:${profileSufix}`,
  CHANGE_SITUATION_CLIENT_USER_PROFILE: `${changeSituationPrefix}:${profileSufix}`,
  DELETE_CLIENT_USER_PROFILE: `${deletePrefix}:${profileSufix}`,

  //Colaborador
  READ_COLLABORATOR: `${readPrefix}:${collaboratorSufix}`,
  CREATE_COLLABORATOR: `${createPrefix}:${collaboratorSufix}`,
  UPDATE_COLLABORATOR: `${updatePrefix}:${collaboratorSufix}`,
  CHANGE_SITUATION_COLLABORATOR: `${changeSituationPrefix}:${collaboratorSufix}`,
  DELETE_COLLABORATOR: `${deletePrefix}:${collaboratorSufix}`,
  RESET_PASSWORD: `RESET_PASSWORD:${collaboratorSufix}`,

  //Benefícios
  READ_BENEFIT: `${readPrefix}:${benefitSufix}`,
  CREATE_BENEFIT: `${createPrefix}:${benefitSufix}`,
  UPDATE_BENEFIT: `${updatePrefix}:${benefitSufix}`,
  DELETE_BENEFIT: `${deletePrefix}:${benefitSufix}`,

  //Network
  CREATE_NETWORK: `${createPrefix}:${networkSufix}`,
  UPDATE_NETWORK: `${updatePrefix}:${networkSufix}`,
  DELETE_NETWORK: `${deletePrefix}:${networkSufix}`,
  READ_NETWORK: `${readPrefix}:${networkSufix}`,
  SEND_COMMENT_NETWORK: `SEND_COMMENT:${networkSufix}`,
  READ_COMMENT_NETWORK: `READ_COMMENT:${networkSufix}`,

  //Importação
  IMPORT_IMPORT: `${importSufix}:${importSufix}`,

  //Avisos
  READ_NOTICES: `${readPrefix}:${noticeSufix}`,
  CREATE_NOTICES: `${createPrefix}:${noticeSufix}`,
  UPDATE_NOTICES: `${updatePrefix}:${noticeSufix}`,
  DELETE_NOTICES: `${deletePrefix}:${noticeSufix}`,

  // Avisos Cliente
  READ_CLIENT_NOTICES: `${readPrefix}:${clientNoticeSufix}`,
  CREATE_CLIENT_NOTICES: `${createPrefix}:${clientNoticeSufix}`,
  UPDATE_CLIENT_NOTICES: `${updatePrefix}:${clientNoticeSufix}`,
  DELETE_CLIENT_NOTICES: `${deletePrefix}:${clientNoticeSufix}`,
  AVAILABLE_CLIENT_NOTICES: `${availablePrefix}:${clientNoticeSufix}`,

  //Parceiros
  READ_PARTNER: `${readPrefix}:${partnerSufix}`,
  CREATE_PARTNER: `${createPrefix}:${partnerSufix}`,
  UPDATE_PARTNER: `${updatePrefix}:${partnerSufix}`,
  DELETE_PARTNER: `${deletePrefix}:${partnerSufix}`,

  //Posts
  READ_POST: `${readPrefix}:${postSufix}`,
  CREATE_POST: `${createPrefix}:${postSufix}`,
  UPDATE_POST: `${updatePrefix}:${postSufix}`,
  DELETE_POST: `${deletePrefix}:${postSufix}`,
  DELETE_COMMENT: `${deleteComment}:${postSufix}`,
  READ_COMMENT_REPROVED: `READ_COMMENT_REPROVED:${postSufix}`,
  READ_COMMENT_REPORTED: `READ_COMMENT_REPORTED:${postSufix}`,
  MODERATE_POST: `MODERATE_POST:${postSufix}`,

  //Surveys
  READ_SURVEY: `${readPrefix}:${surveySufix}`,
  CREATE_SURVEY: `${createPrefix}:${surveySufix}`,
  UPDATE_SURVEY: `${updatePrefix}:${surveySufix}`,
  DELETE_SURVEY: `${deletePrefix}:${surveySufix}`,
  CHANGE_SITUATION: `${changeSituationPrefix}:${surveySufix}`,

  //Eventos
  READ_EVENTS: `${readPrefix}:${eventSufix}`,
  CREATE_EVENTS: `${createPrefix}:${eventSufix}`,
  UPDATE_EVENTS: `${updatePrefix}:${eventSufix}`,
  DELETE_EVENTS: `${deletePrefix}:${eventSufix}`,

  //Grupo de colaboradores
  READ_USER_GROUP: `${readPrefix}:${collaboratorGroupSufix}`,
  CREATE_USER_GROUP: `${createPrefix}:${collaboratorGroupSufix}`,
  UPDATE_USER_GROUP: `${updatePrefix}:${collaboratorGroupSufix}`,
  DELETE_USER_GROUP: `${deletePrefix}:${collaboratorGroupSufix}`,
  CHANGE_SITUATION_GROUP: `${changeSituationPrefix}:${collaboratorGroupSufix}`,

  //Engajamento
  READ_GAMIFICATION: `${readPrefix}:${engajamento}`,
  CREATE_GAMIFICATION: `${createPrefix}:${engajamento}`,
  UPDATE_GAMIFICATION: `${updatePrefix}:${engajamento}`,
  DELETE_GAMIFICATION: `${deletePrefix}:${engajamento}`,
  EXTRACT_GAMIFICATION: `EXTRACT:${engajamento}`,
  CAMPAIGN_GAMIFICATION: `${campaignPrefix}:${engajamento}`,
  REWARD_GAMIFICATION: `${rewardPrefix}:${engajamento}`,
  REWARD_MANAGEMENT_GAMIFICATION: `${managementRewardPrefix}:${engajamento}`,
  QUOTATION_GAMIFICATION: `${quotationPrefix}:${engajamento}`,
  ADVENTURE_GAMIFICATION: `${adventurePrefix}:${engajamento}`,
  MANAGEMENT_ADVENTURE_GAMIFICATION: `${managementAdventurePrefix}:${engajamento}`,
  WALLET_GAMIFICATION: `${walletPrefix}:${engajamento}`,

  //Minha Conta
  READ_MY_ACCOUNT: `${readPrefix}:${myAccountSufix}`,
  UPDATE_MY_ACCOUNT: `${updatePrefix}:${myAccountSufix}`,
  RESET_PASSOWRD_MY_ACCOUNT: `${resetPassWordPrefix}:${myAccountSufix}`,

  //Integração Parceiros
  READ_PARTNERINTEGRATION: `${readPrefix}:${partnerIntegration}`,

  //Grupo de Usuários
  READ_USER_CLIENT_GROUP: `${readPrefix}:${userGroupSufix}`,
  CREATE_USER_CLIENT_GROUP: `${createPrefix}:${userGroupSufix}`,
  UPDATE_USER_CLIENT_GROUP: `${updatePrefix}:${userGroupSufix}`,
  DELETE_USER_CLIENT_GROUP: `${deletePrefix}:${userGroupSufix}`,

  //Categorias
  READ_CATEGORY: `${readPrefix}:${categorySufix}`,
  CREATE_CATEGORY: `${createPrefix}:${categorySufix}`,
  UPDATE_CATEGORY: `${updatePrefix}:${categorySufix}`,
  DELETE_CATEGORY: `${deletePrefix}:${categorySufix}`,

  //Recompensas
  READ_REWARDS: `${readPrefix}:${rewardsSufix}`,
  CREATE_REWARDS: `${createPrefix}:${rewardsSufix}`,
  UPDATE_REWARDS: `${updatePrefix}:${rewardsSufix}`,
  DELETE_REWARDS: `${deletePrefix}:${rewardsSufix}`,

  //Transferência de colaboradores
  READ_COLLABORATOR_TRASFER: `${readPrefix}:${collaboratorTransferSufix}`,
  CREATE_COLLABORATOR_TRASFER: `${createPrefix}:${collaboratorTransferSufix}`,

  //Fórum da Comunidade
  READ_COMMUNITY_FORUM: `${readPrefix}:${communityForumSufix}`,
  CREATE_TOPIC_COMMUNITY_FORUM: `CREATE_TOPIC:${communityForumSufix}`,
  UPDATE_TOPIC_COMMUNITY_FORUM: `UPDATE_TOPIC:${communityForumSufix}`,
  DELETE_TOPIC_COMMUNITY_FORUM: `DELETE_TOPIC:${communityForumSufix}`,
  CATEGORY_COMMUNITY_FORUM: `CATEGORY:${communityForumSufix}`,
  RULES_COMMUNITY_FORUM: `RULES:${communityForumSufix}`,
  ARCHIVE_UNARCHIVE_TOPIC_COMMUNITY_FORUM: `ARCHIVE_UNARCHIVE_TOPIC:${communityForumSufix}`,
  REPORT_COMMUNITY_FORUM: `${reportPrefix}:${communityForumSufix}`,
  APPROVE_REJECT_TOPIC_COMMUNITY_FORUM: `APPROVE_REJECT_TOPIC:${communityForumSufix}`,

  //Terceiros
  READ_THIRD_PARTY: `${readPrefix}:${thirdPartySufix}`,
  CREATE_THIRD_PARTY: `${createPrefix}:${thirdPartySufix}`,
  UPDATE_THIRD_PARTY: `${updatePrefix}:${thirdPartySufix}`,
  DELETE_THIRD_PARTY: `${deletePrefix}:${thirdPartySufix}`,
  CHANGE_SITUATION_THIRD_PARTY: `${changeSituationPrefix}:${thirdPartySufix}`,
  RESET_PASSWORD_THIRD_PARTY: `RESET_PASSWORD:${thirdPartySufix}`,

  //Links Úteis
  READ_USEFUL_LINK: `${readPrefix}:${usefulLinksSufix}`,
  CREATE_USEFUL_LINK: `${createPrefix}:${usefulLinksSufix}`,
  UPDATE_USEFUL_LINK: `${updatePrefix}:${usefulLinksSufix}`,
  DELETE_USEFUL_LINK: `${deletePrefix}:${usefulLinksSufix}`,
  CHANGE_SITUATION_USEFUL_LINK: `${changeSituationPrefix}:${usefulLinksSufix}`,

  //Repositório de Arquivos
  READ_DOCUMENT: `${readPrefix}:${documentRepositorySufix}`,
  CREATE_DOCUMENT: `${createPrefix}:${documentRepositorySufix}`,
  UPDATE_DOCUMENT: `${updatePrefix}:${documentRepositorySufix}`,
  DELETE_DOCUMENT: `${deletePrefix}:${documentRepositorySufix}`,
  CHANGE_VISIBILITY_DOCUMENT: `CHANGE_VISIBILITY:${documentRepositorySufix}`,
};
