import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatSort, MatSortable } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { NgxPermissionsService, NgxRolesService } from 'ngx-permissions';
import { debounceTime, filter, from, map, mergeMap, of } from 'rxjs';
import { ConfirmModalComponent } from 'src/app/shared/components/confirm-modal/confirm-modal.component';
import { ToastComponent } from 'src/app/shared/components/toaster/toast/toast.component';
import { AddressInfo } from 'src/app/shared/domains/address-info';
import { Client } from 'src/app/shared/domains/client';
import { ClientGeneralData } from 'src/app/shared/domains/client-general-data';

import { MatDialog } from '@angular/material/dialog';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { ProfilePermissions } from 'src/app/shared/constants/profile-permissions';
import { AttachmentDTO, FileDTO } from 'src/app/shared/domains/attachment';
import {
  AddressDTO,
  ClientDTO,
  DomainDTO,
} from 'src/app/shared/domains/clientDto';
import { Contact } from 'src/app/shared/domains/contact';
import { AddressType } from 'src/app/shared/enums/address-type';
import { ClientService } from 'src/app/shared/services/client.service';
import { DataBridgeService } from 'src/app/shared/services/data-bridge.service';
import { DataImportService } from 'src/app/shared/services/data-import.service';
import { GlobalFunctions } from 'src/app/shared/utils/global-functions';
import { ISENTA, brazilianStates } from 'src/app/shared/utils/global-variables';
import { environment } from 'src/environments/environment';

import { AuthService } from 'src/app/shared/services/auth.service';
import { ValidatorGlobal } from 'src/app/shared/utils/validator-global';

@Component({
  selector: 'app-new-client',
  templateUrl: './new-client.component.html',
  styleUrls: ['./new-client.component.scss'],
})
export class NewClientComponent implements OnInit, AfterViewInit {
  @ViewChild('confirmModal') confirmModal: ConfirmModalComponent | undefined;
  form: Form = new Form();
  states: Array<string> = brazilianStates;
  phoneMask: string = '(00) 0000-0000 || (00) 00000-0000';
  cnpjMask: string = '00.000.000/0000-00';
  cepMask: string = '00.000-000';
  contactApps: Array<string> = ['Telegram', 'WhatsApp'];
  searchCategoryTypesFormControl = new FormControl();
  searchClientFormControl = new FormControl();
  searchCategoriesFormControl = new FormControl();
  searchStateFormControl = new FormControl();
  filteredStates: Array<string> = brazilianStates;
  filteredClients: ClientDTO[] = [];
  prefixLink: string = environment.auto_registration_link_prefix;
  isLinkEditable = false;
  changeImage: boolean = false;
  changeAttachments: boolean = false;
  validatorRequired: boolean = false;
  paginationCardContact = 10;
  paginationCardClient = 10;
  canGoToConfigIntegration: boolean = false

  links = Object.keys(LinkType).map((key) => {
    const enumLabel: 'GRUPO_ECONOMICO' | 'MATRIZ_FILIAL' = key as any;
    return { label: LinkType[enumLabel], enumVal: key };
  });

  associatesClientsDataSource = new MatTableDataSource<any>();
  associatesClientsDisplayedColumns: string[] = [
    'cnpj',
    'corporateName',
    'city',
    'state',
    'isActive',
  ];
  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild('clientsTableSort') clientsTableSort: MatSort | undefined;

  contactDataSource = new MatTableDataSource<any>();
  contactDisplayedColumns: string[] = [
    'name',
    'phone',
    'role',
    'email',
    'actions',
  ];
  isEditContact = false;
  lockContactTypeEdition = false;
  greaterContactCode = 0;
  lastContactCodeUsed = 0;

  groupedCategories: any = [];
  cnpjExist!: boolean;

  //#region Form
  formValidator: FormGroup = this.formBuilder.group({
    logoImgUri: new FormControl(''),
    hasBeenImageDeleted: new FormControl(false),
    businessData: this.formBuilder.group({
      origin: new FormControl(
        { value: 'CADASTRO', disabled: true },
        Validators.required
      ),
      cnpj: new FormControl('', Validators.required),
      stateRegistration: new FormControl('', [
        Validators.required,
        Validators.maxLength(17),
      ]),
      isActive: new FormControl(false),
      corporateName: new FormControl(
        { value: '', disabled: true },
        Validators.required
      ),
      cnae: new FormControl(''),
      type: new FormControl(''),
      fantasyName: new FormControl(''),
      site: new FormControl(''),
      phone: new FormControl('', Validators.required),
      email: new FormControl('', [Validators.required, ValidatorGlobal.validateEmail()]),
      cep: new FormControl('', Validators.required),
      address: new FormControl('', Validators.required),
      number: new FormControl('', Validators.required),
      complement: new FormControl(''),
      district: new FormControl('', Validators.required),
      city: new FormControl('', Validators.required),
      state: new FormControl('', Validators.required)
    }),
    mailingAddress: this.formBuilder.group({
      equalsCnpj: new FormControl(false, Validators.required),
      cep: new FormControl({ value: '', disabled: true }, Validators.required),
      address: new FormControl(
        { value: '', disabled: true },
        Validators.required
      ),
      number: new FormControl(
        { value: '', disabled: true },
        Validators.required
      ),
      complement: new FormControl({ value: '', disabled: true }),
      district: new FormControl(
        { value: '', disabled: true },
        Validators.required
      ),
      city: new FormControl({ value: '', disabled: true }, Validators.required),
      state: new FormControl(
        { value: '', disabled: true },
        Validators.required
      ),
      isSameAddressAsBusinessAddress: new FormControl(true),
    }),
    complementaryData: this.formBuilder.group({
      domains: new FormControl(new Array<string>()),
    }),
    link: this.formBuilder.group({
      clientTypeLink: new FormControl('', Validators.required),
      client: new FormControl(),
      isOwn: new FormControl(false),
    }),
    contacts: new FormControl([], Validators.required),
    products: new FormControl([]),
  });

  addNewContactFormGroup: FormGroup = this.formBuilder.group({
    id: new FormControl(undefined),
    name: new FormControl('', Validators.required),
    phone: new FormControl('', Validators.required),
    contactApp: new FormControl(''),
    role: new FormControl('', Validators.required),
    email: new FormControl('', [Validators.required, ValidatorGlobal.validateEmail()]),
    type: new FormControl('', Validators.required),
  });

  //#endregion Form
  situation: boolean = false;
  exempt: string[] = [ISENTA];
  countInterval: any;
  count: number = -1;
  showModal: boolean = false;
  generalData: ClientGeneralData = new ClientGeneralData();
  inputValue = '';
  showModalSave: boolean = false;
  showModalContact: boolean = false;

  selectedImage: any;
  image!: File | undefined;
  lastImgUri!: string | null;
  attachmentsToSave: File[] = [];
  attachmentsOld: AttachmentDTO[] = [];
  attachments: FileDTO[] = [];
  showModalAssociateProducts = false;
  paginationProductsCard: number = 10;

  clientId = 0;
  canEdit: boolean = true;
  canDelete: boolean = true;
  canCreate: boolean = true;
  showModalViewProduct: boolean = false;

  productId: number = 0;
  showModalViewParticipatingBeneficiaries: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private dataBridgeService: DataBridgeService,
    private clientService: ClientService,
    private router: Router,
    private route: ActivatedRoute,
    private toastComponent: ToastComponent,
    public globalFunctions: GlobalFunctions,
    public permissionsService: NgxPermissionsService,
    private rolesService: NgxRolesService,
    public dialog: MatDialog,
    private dataImportService: DataImportService,
    private sanitizer: DomSanitizer,
    private authService: AuthService
  ) {
    //TODO: TENTAR USAR O FORKJOIN AQUI QUE ELE FAZ AS 2 REQUISIÇÕES EM SEGUIDA NÃO AFETANDO O LOADING DO PROJETO
    // forkJoin([
    //   this.clientService.getGeneralData(),
    //   route.queryParams.pipe(
    //   filter(params => params['id']),
    //   map((params) => Number(params['id'])),
    //   mergeMap(id => this.clientService.get(id)),
    //   )
    // ]).subscribe(
    //     (([generalData, client]) => {
    //     this.generalData = generalData;

    //     if(client) {
    //       this.fillClientData(client);
    //     }
    //   })
    // )

    this.searchClientFormControl.valueChanges.subscribe((filter) =>
      this.filterClients(filter)
    );

    this.searchStateFormControl.valueChanges.subscribe((filter) => {
      this.filteredStates = this.states.filter((state) =>
        state.toLowerCase().includes(filter.toLowerCase())
      );
    });

    from(this.clientService.getGeneralData())
      .pipe(
        mergeMap((generalData) => {
          this.generalData = generalData;
          this.filteredClients = generalData.companies;
          this.generalData.companies.sort((a, b) => {
            return this.globalFunctions.compareString(
              a.corporateName,
              b.corporateName
            );
          });

          return route.queryParams.pipe(
            map((params) => Number(params['id'])),
            mergeMap((id) => (id ? this.clientService.get(id) : of('')))
          );
        })
      )
      .subscribe((client) => {
        if (client) this.fillClientData(client as ClientDTO);
      });

    this.formValidator
      .get('businessData')
      ?.get('cnpj')
      ?.valueChanges.pipe(
        debounceTime(400),
        filter((cnpj) => cnpj.length === 14)
      )
      .subscribe(() => this.getDataByCnpj());
  }

  async ngOnInit() {
    this.canEdit = await this.permissionsService.hasPermission(
      ProfilePermissions.UPDATE_CLIENT
    );
    this.canGoToConfigIntegration = await this.permissionsService.hasPermission(
      ProfilePermissions.CONFIGURATION_INTEGRATION_CLIENT
    )
    this.form.businessData.origin = 'CADASTRO';
    this.disableForm();
  }

  private filterClients(filter: string) {
    this.filteredClients = this.generalData.companies.filter((client) =>
      client.corporateName.toLowerCase().includes(filter.toLowerCase())
    );
  }

  ngAfterViewInit() {
    this.contactDataSource.sort = this.sort;

    // Classificação personalizada
    this.contactDataSource.sortingDataAccessor = (item, property) => {
      if (property === 'name') {
        if (item.type === 'PRIMARY') {
          return 'A' + item.name; // Adiciona 'A' antes para que apareça primeiro
        } else if (item.type === 'SECONDARY') {
          return 'B' + item.name; // Adiciona 'B' antes para que apareça em segundo
        }
      }
      return item[property];
    };

    // Classifica a tabela inicialmente
    const sortState: MatSortable = {
      id: 'name',
      start: 'asc',
      disableClear: true,
    };

    this.sort.sort(<MatSortable>sortState);
  }

  onFileAdded(event: any): void {
    const newFiles = event.target.files;

    if (!newFiles || newFiles.length === 0) {
      this.toastComponent.showWarningCustomMessage(
        'Erro!',
        'Nenhum arquivo fornecido.'
      );
      return;
    }

    const validTypes = [
      'application/pdf',
      'application/msword',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      'application/vnd.ms-excel',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      'text/csv',
    ];

    const maxSize = 15 * 1024 * 1024;

    for (const file of newFiles) {
      if (!validTypes.includes(file.type)) {
        this.toastComponent.showWarningCustomMessage(
          'Formato Inválido!',
          'Por favor, selecione um arquivo PDF, DOC, DOCx, XLSx ou CSV.'
        );
        return;
      }

      if (file.size > maxSize) {
        this.toastComponent.showWarningCustomMessage(
          'Arquivo Grande!',
          'Por favor, selecione um arquivo menor que 15MB.'
        );
        return;
      }

      const fileUri = URL.createObjectURL(file);

      this.attachments.push({
        name: file.name,
        hasBeenDeleted: false,
        uri: fileUri,
        file: file,
      });
    }

    event.target.value = null;
    this.changeAttachments = true;
  }

  removeFile(index: number): void {
    const attachment = this.attachments[index];
    this.attachmentsOld.forEach((a) => {
      if (a.id === attachment.id) a.hasBeenDeleted = true;
    });

    this.attachments.splice(index, 1);
    this.changeAttachments = true;
  }

  onImgAdded(event: any): void {
    const file = event.target.files[0];
    this.handleFile(file);
  }

  onDragOver(event: any): void {
    event.preventDefault();
  }

  onDrop(event: any): void {
    event.preventDefault();
    const files = event.dataTransfer.files;
    if (files.length > 0) {
      this.handleFile(files[0]);
    }
  }

  downloadImage(imageUrl: string, imageName: string) {
    const downloadLink = document.createElement('a');
    downloadLink.href = imageUrl;
    downloadLink.download = imageName;
    document.body.appendChild(downloadLink);
    downloadLink.click();

    document.body.removeChild(downloadLink);
  }

  getFileName() {
    return `IMG_${this.formValidator.get('businessData.corporateName')?.value}`
      .replace(' ', '_')
      .toLocaleUpperCase();
  }

  handleFile(file: File): void {
    if (!file) {
      this.toastComponent.showWarningCustomMessage(
        'Erro!',
        'Nenhum arquivo fornecido.'
      );
      return;
    }
    const validTypes = ['image/png', 'image/jpeg'];
    const maxSize = 15 * 1024 * 1024;
    if (!validTypes.includes(file.type)) {
      this.toastComponent.showWarningCustomMessage(
        'Formato Inválido!',
        'Por favor, selecione um arquivo PNG.'
      );
      return;
    }

    if (file.size > maxSize) {
      this.toastComponent.showWarningCustomMessage(
        'Tamaho inválido',
        'Por favor, selecione um arquivo com tamanho máximo de 15mb.'
      );
      return;
    }

    this.removeImg(file);
    this.image = file;
    const reader = new FileReader();
    reader.onload = (e: Event) => {
      const target = e.target as FileReader;
      this.selectedImage = target.result as string;
    };
    reader.onerror = (error) => {
      console.error('Erro ao ler o arquivo:', error);
      this.toastComponent.showWarningCustomMessage(
        'Erro ao ler o arquivo!',
        'Por favor, tente novamente.'
      );
    };
    reader.readAsDataURL(file);
  }

  removeImg(fileInput: any) {
    if (
      this.clientId &&
      this.lastImgUri
    ) {
      this.formValidator.get('hasBeenImageDeleted')?.setValue(true);
    }
    this.changeImage = true;
    this.selectedImage = null;
    this.image = undefined;
    fileInput.value = '';
  }

  async downloadImportedFile(file: FileDTO) {
    if (file.uri?.length > 0) {
      if (file.uri.charAt(0) == '/') {
        file.uri = file.uri.substring(1);
      }
    }
    let a = document.createElement('a');
    let data: Blob;
    if (file.id) {
      data = await this.dataImportService.getBlob(file.uri);
      a.href = URL.createObjectURL(data);
      a.download = `${file.name}`;
      a.click();
    } else {
      if (file.file) {
        data = new Blob([file.file], { type: file.file.type });
        a.href = URL.createObjectURL(data);
        a.download = `${file.name}`;
        a.click();
      }
    }
  }

  async getImage(imgUrl: string | null) {
    if (imgUrl) {
      const blob = await this.dataImportService.getBlob(imgUrl);
      const file = new File([blob], 'img', { type: blob.type });
      const reader = new FileReader();
      reader.onload = (e: any) => {
        this.selectedImage = e.target.result;
      };
      reader.readAsDataURL(file);
    }
  }

  sanitizeImageUrl(imageUrl: string): SafeUrl {
    return this.sanitizer.bypassSecurityTrustUrl(imageUrl);
  }


  addressLinkEquals(isCNPJChange?: boolean) {
    if (!this.canEdit) return;

    if (!isCNPJChange) {
      this.formValidator
        .get('mailingAddress')
        ?.get('isSameAddressAsBusinessAddress')
        ?.setValue(
          !this.formValidator
            .get('mailingAddress')
            ?.get('isSameAddressAsBusinessAddress')?.value
        );
    }

    const mailingAddressFormGroup = this.formValidator.get('mailingAddress');
    const businessDataFormGroup = this.formValidator.get('businessData');

    const cepFormControl = mailingAddressFormGroup?.get('cep');
    const addressFormControl = mailingAddressFormGroup?.get('address');
    const numberFormControl = mailingAddressFormGroup?.get('number');
    const complementFormControl = mailingAddressFormGroup?.get('complement');
    const districtFormControl = mailingAddressFormGroup?.get('district');
    const cityFormControl = mailingAddressFormGroup?.get('city');
    const stateFormControl = mailingAddressFormGroup?.get('state');

    if (
      this.formValidator
        .get('mailingAddress')
        ?.get('isSameAddressAsBusinessAddress')?.value
    ) {
      cepFormControl?.setValue(businessDataFormGroup?.get('cep')?.value);
      cepFormControl?.disable();

      addressFormControl?.setValue(
        businessDataFormGroup?.get('address')?.value
      );
      addressFormControl?.disable();

      numberFormControl?.setValue(businessDataFormGroup?.get('number')?.value);
      numberFormControl?.disable();

      complementFormControl?.setValue(
        businessDataFormGroup?.get('complement')?.value
      );
      complementFormControl?.disable();

      districtFormControl?.setValue(
        businessDataFormGroup?.get('district')?.value
      );
      districtFormControl?.disable();

      cityFormControl?.setValue(businessDataFormGroup?.get('city')?.value);
      cityFormControl?.disable();

      stateFormControl?.setValue(businessDataFormGroup?.get('state')?.value);
      stateFormControl?.disable();
    } else {
      cepFormControl?.setValue('');
      cepFormControl?.enable();

      addressFormControl?.setValue('');
      addressFormControl?.enable();

      numberFormControl?.setValue('');
      numberFormControl?.enable();

      complementFormControl?.setValue('');
      complementFormControl?.enable();

      districtFormControl?.setValue('');
      districtFormControl?.enable();

      cityFormControl?.setValue('');
      cityFormControl?.enable();

      stateFormControl?.setValue('');
      stateFormControl?.enable();
    }
  }

  async checkCNPJ(): Promise<void> {
    const businessDataFormGroup = this.formValidator.get('businessData');
    const cnpj = businessDataFormGroup?.get('cnpj')?.value;
    const dadosClientes = await this.clientService.findByCnpj(cnpj);
    if (dadosClientes != null) {
      this.toastComponent.showWarningCustomMessage('Cliente já cadastrado', '');
      this.cnpjExist = true;
    } else {
      this.cnpjExist = false;
    }
  }

  async getDataByCnpj() {
    this.showModal = false;
    const businessDataFormGroup = this.formValidator.get('businessData');
    const cnpj = businessDataFormGroup?.get('cnpj')?.value;
    if (cnpj.length === 14) {
      let cnpjData;

      if (this.count > 0) {
        this.showModal = true;
        return;
      }

      try {
        await this.checkCNPJ();
        if (!this.cnpjExist) {
          cnpjData = await this.dataBridgeService.getByCNPJ(cnpj);
        }
      } catch (error: any) {

        if (error.status === 429) {
          this.openModal();
        }

        this.toastComponent.showApiError(error);
        this.form.cnpjValid = false;
        return;
      }

      if (cnpjData) {
        let cnae = '';
        if (cnpjData.main_activity)
          cnae =
            cnpjData.main_activity.length > 0
              ? cnpjData.main_activity[0].code
              : '.';

        businessDataFormGroup
          ?.get('address')
          ?.patchValue(cnpjData.address.streetAddress);
        businessDataFormGroup
          ?.get('corporateName')
          ?.patchValue(cnpjData.corporateName);
        businessDataFormGroup?.get('type')?.patchValue(cnpjData.type);
        businessDataFormGroup?.get('cnae')?.patchValue(cnae);
        businessDataFormGroup?.get('email')?.patchValue(cnpjData.email);
        businessDataFormGroup
          ?.get('fantasyName')
          ?.patchValue(cnpjData.fantasyName || '');
        businessDataFormGroup?.get('phone')?.patchValue(cnpjData.phone);
        businessDataFormGroup?.get('cep')?.patchValue(cnpjData.address.cep);
        businessDataFormGroup?.get('city')?.patchValue(cnpjData.address.city);
        businessDataFormGroup
          ?.get('district')
          ?.patchValue(cnpjData.address.district);
        businessDataFormGroup
          ?.get('number')
          ?.patchValue(cnpjData.address.number);
        businessDataFormGroup
          ?.get('complement')
          ?.patchValue(cnpjData.address.complement);
        businessDataFormGroup?.get('state')?.patchValue(cnpjData.address.uf);
        businessDataFormGroup?.get('site')?.patchValue(cnpjData.site);
        this.form.cnpjValid = true;
        this.count = -1;

        if (
          this.formValidator
            .get('mailingAddress')
            ?.get('isSameAddressAsBusinessAddress')?.value
        ) {
          this.addressLinkEquals(true);
        }

        if (!cnpjData.email) {
          this.formValidator.get('businessData')?.get('email')?.enable();
        }
      }
    } else if (this.form.businessData.cnpj.length < 14) {
      this.form.cnpjValid = false;
    }
  }

  async getDataByCep(isBusinessDataCep?: boolean) {
    let cep = isBusinessDataCep
      ? this.formValidator.value.businessData.cep
      : this.formValidator.value.mailingAddress.cep;

    if (cep && cep.length === 8) {
      let address;

      try {
        address = await this.dataBridgeService.getByCEP(cep);
      } catch (error: any) {
        this.toastComponent.showWarningCustomMessage(
          'Campos habilitados para preenchimento manual!',
          ''
        );
      }

      if (address && address.city == null && address.uf == null) {
        this.toastComponent.showWarningCustomMessage('CEP Inválido!', '');
        if (isBusinessDataCep) this.form.businessCepValid = false;
        else this.form.cepValid = false;
        return;
      }

      if (address && isBusinessDataCep) {
        this.formValidator
          .get('businessData')
          ?.get('city')
          ?.patchValue(address.city);
        this.formValidator
          .get('businessData')
          ?.get('complement')
          ?.patchValue(address.complement);
        this.formValidator
          .get('businessData')
          ?.get('district')
          ?.patchValue(address.district);
        this.formValidator
          .get('businessData')
          ?.get('number')
          ?.patchValue(address.number);
        this.formValidator
          .get('businessData')
          ?.get('state')
          ?.patchValue(address.uf);
        this.formValidator
          .get('businessData')
          ?.get('address')
          ?.patchValue(address.streetAddress);

        this.addressLinkEquals(true);
      } else if (address && !isBusinessDataCep) {
        this.formValidator
          .get('mailingAddress')
          ?.get('city')
          ?.patchValue(address.city);
        this.formValidator
          .get('mailingAddress')
          ?.get('complement')
          ?.patchValue(address.complement);
        this.formValidator
          .get('mailingAddress')
          ?.get('district')
          ?.patchValue(address.district);
        this.formValidator
          .get('mailingAddress')
          ?.get('number')
          ?.patchValue(address.number);
        this.formValidator
          .get('mailingAddress')
          ?.get('state')
          ?.patchValue(address.uf);
        this.formValidator
          .get('mailingAddress')
          ?.get('address')
          ?.patchValue(address.streetAddress);
      }
    }
  }

  inicializeTimer() {
    this.count = 60;
    this.countInterval = setInterval(() => {
      this.count--;
      if (this.count == 0) {
        this.showModal = false;
        clearInterval(this.countInterval);
      }
    }, 1000);
  }

  //#region Contatos
  initContacts() {
    this.formValidator.get('contacts')?.value.forEach((contact: any) => {
      contact.contactApp = Contact.formatContactApp(contact.contactApp);
      if (contact.id > this.greaterContactCode) {
        this.greaterContactCode = contact.id;
        this.lastContactCodeUsed = contact.id;
      }
    });

    this.contactDataSource = new MatTableDataSource(this.form.contacts);
    this.orderContacts();
  }

  showContactModal(contact?: Contact) {
    this.lockContactTypeEdition = false;
    const typeFormControl = this.addNewContactFormGroup.get('type');

    if (!contact) {
      this.isEditContact = false;
      this.addNewContactFormGroup.reset();
      typeFormControl?.setValue('DEFAULT');
      if (this.formValidator.get('contacts')?.value.length == 0) {
        this.lockContactTypeEdition = true;
        typeFormControl?.setValue('PRIMARY');
      }
      this.addNewContactFormGroup
        .get('id')
        ?.setValue(this.lastContactCodeUsed + 1);
      this.lastContactCodeUsed++;
    } else {
      this.isEditContact = true;
      if (contact.type == 'PRIMARY') {
        this.lockContactTypeEdition = true;
      }

      const nameFormControl = this.addNewContactFormGroup.get('name');
      const phoneFormControl = this.addNewContactFormGroup.get('phone');
      const contactAppFormControl =
        this.addNewContactFormGroup.get('contactApp');
      const roleFormControl = this.addNewContactFormGroup.get('role');
      const idFormControl = this.addNewContactFormGroup.get('id');
      const emailFormControl = this.addNewContactFormGroup.get('email');

      nameFormControl?.setValue(contact.name);
      phoneFormControl?.setValue(contact.phone);
      roleFormControl?.setValue(contact.role);
      emailFormControl?.setValue(contact.email);
      typeFormControl?.setValue(contact.type);
      idFormControl?.setValue(contact.id);
    }

    this.showModalContact = true;
  }

  confirmContactModal() {
    if (this.isEditContact) {
      this.editContact();
    } else {
      this.createContact();
    }

    this.setPrimaryOrSecondaryContact();
    this.orderContacts();
    this.closeContactModal();
    this.verifyIfHaveContacts();
  }

  editContact() {
    const addNewContactForm = this.addNewContactFormGroup?.value;
    const contacts = this.formValidator.get('contacts')?.value;
    let contact = contacts.find(
      (contact: { id: any }) => contact.id == addNewContactForm.id
    );

    contact.name = addNewContactForm.name;
    contact.phone = addNewContactForm.phone;
    contact.email = addNewContactForm.email;
    contact.contactApp = addNewContactForm.contactApp;
    contact.role = addNewContactForm.role;
    contact.type = addNewContactForm.type;

    this.formValidator.get('contacts')?.markAsDirty();
  }

  createContact() {
    const addNewContactForm = this.addNewContactFormGroup?.value;
    const contacts = this.formValidator.get('contacts')?.value;

    let contact = new Contact(
      addNewContactForm.name,
      addNewContactForm.phone,
      undefined,
      addNewContactForm.role,
      addNewContactForm.email,
      addNewContactForm.type
    );
    contact.id = addNewContactForm.id;
    contacts.push(contact);

    this.formValidator.get('contacts')?.patchValue(contacts);
    this.formValidator.get('contacts')?.markAsDirty();
  }

  setPrimaryOrSecondaryContact() {
    const addNewContactForm = this.addNewContactFormGroup?.value;
    const contacts = this.formValidator.get('contacts')?.value;

    if (
      addNewContactForm &&
      addNewContactForm.type == 'PRIMARY' &&
      contacts.length > 0 &&
      addNewContactForm.id != contacts[0].id
    ) {
      contacts[0].type = 'DEFAULT';
    } else if (
      addNewContactForm.type == 'SECONDARY' &&
      contacts.length > 1 &&
      addNewContactForm.id != contacts[1].id
    ) {
      contacts[1].type = 'DEFAULT';
    }
  }

  orderContacts() {
    const primaryContact: Contact[] = [];
    const secondaryContact: Contact[] = [];
    const defaultContacts: Contact[] = [];

    this.formValidator.get('contacts')?.value.forEach((contact: Contact) => {
      if (contact.type === 'PRIMARY') {
        primaryContact.push(contact);
      } else if (contact.type === 'SECONDARY') {
        secondaryContact.push(contact);
      } else {
        defaultContacts.push(contact);
      }
    });

    defaultContacts.sort((a, b) => {
      if (!a.id) return 1;
      if (!b.id) return -1;
      return a.id - b.id;
    });

    const sortedContacts = [
      ...primaryContact,
      ...secondaryContact,
      ...defaultContacts,
    ];
    this.formValidator.get('contacts')?.patchValue(sortedContacts);
    this.contactDataSource = new MatTableDataSource(
      this.formValidator.get('contacts')?.value
    );
  }

  closeContactModal() {
    this.showModalContact = false;
  }

  deleteContactModal(object: Contact) {
    this.confirmModal
      ?.showModal(
        'Deseja mesmo excluir este contato?',
        'Caso confirme, essa ação não poderá ser desfeita.'
      )
      .subscribe((isAccepted) => {
        if (isAccepted) {
          this.deleteContact(object);
          this.contactDataSource = new MatTableDataSource(
            this.formValidator.get('contacts')?.value
          );
        }
      });
    this.closeContactModal();
  }

  deleteContact(client: Contact) {
    const contacts: Contact[] = this.formValidator.get('contacts')?.value;

    if (client.type !== 'PRIMARY') {
      contacts.forEach((c: Contact, i: number) => {
        if (c.id === client.id) {
          this.formValidator.get('contacts')?.value.splice(i, 1);
          this.formValidator.get('contacts')?.markAsDirty();
        }
      });
    } else {
      this.toastComponent.showWarningCustomMessage(
        'O Contato Principal não pode ser excluído.',
        'Gentileza selecionar um novo Contato Principal para liberar a exclusão deste contato.'
      );
    }
  }
  //#endregion Contatos

  openModal() {
    this.showModal = true;
    this.inicializeTimer();
  }

  async getAssociatedClients(clientId: number) {
    const data = await this.clientService.getAssociatedClients(clientId);
    this.associatesClientsDataSource = new MatTableDataSource(data);
    this.configClientsTableSort();
  }

  private configClientsTableSort() {
    if (this.clientsTableSort) {
      this.clientsTableSort.disableClear = true;
      this.associatesClientsDataSource.sort = this.clientsTableSort;
      this.associatesClientsDataSource.sortingDataAccessor = (
        item: any,
        property
      ) => {
        if (property == 'isActive') {
          return item[property];
        }
        if (property == 'city') {
          return this.address(item.addresses)?.city?.toLowerCase();
        }
        if (property == 'state') {
          return this.address(item.addresses)?.uf?.toLowerCase();
        }
        return item[property]?.toLowerCase();
      };
    }
  }

  verifyIfHaveContacts() {
    this.formValidator.get('contacts')?.value.length === 0
      ? (this.validatorRequired = true)
      : (this.validatorRequired = false);
  }

  markFormFieldsAsTouched() {
    Object.values(this.formValidator.controls).forEach((control) => {
      if (control instanceof FormGroup) {
        Object.values(control.controls).forEach((innerControl) => {
          innerControl.markAsTouched();
        });
      } else {
        control.markAsTouched();
      }
    });
    this.verifyIfHaveContacts();
  }

  async saveClient() {
    this.markFormFieldsAsTouched();
    if (this.formValidator.valid) {
      const client = this.mapperClient();
      const formData = new FormData();
      formData.append(
        'client',
        new Blob([JSON.stringify(client)], { type: 'application/json' })
      );
      if (this.image) {
        formData.append('image', this.image, this.image.name);
      }

      if (this.attachmentsToSave && this.attachmentsToSave.length > 0) {
        this.attachmentsToSave.forEach((file) => {
          formData.append('attachments', file, file.name);
        });
      }

      if (this.clientId) {
        this.clientService.update(formData).subscribe({
          next: () => {
            this.toastComponent.showSuccessCustomMessage(
              'Sucesso!',
              'Cliente editado com sucesso!',
              3000
            );
            this.redirectTo('/clients');
          },
          error: (error: Error) => {
            if (
              error.message.includes(
                'Já existe este link de auto cadastro do colaborador cadastrado!'
              )
            ) {
              this.isLinkEditable = true;
            }
          },
        });
      } else {
        await this.clientService
          .save(formData)
          .then(() => {
            this.toastComponent.showSuccessCustomMessage(
              'Sucesso!',
              'Cliente cadastrado com sucesso!',
              3000
            );
            this.redirectTo('/clients');
          })
      }
    } else {
      this.toastComponent.showWarningCustomMessage(
        'Campo(s) obrigatório(s) não preenchido(s)!'
      );
    }
  }

  cleanClient() {
    if (!this.disableIsOwnCheckbox() && !this.formValidator.get('link')?.get('isOwn')?.value) {
      this.formValidator.get('link')?.get('client')?.setValue('');
    }
  }

  confirmDelete() {
    let client: Client = new Client();
    client.id = this.clientId;
    this.confirmModal
      ?.showModal(
        'Deseja mesmo excluir este cliente?',
        'Caso confirme, essa ação não poderá ser desfeita.'
      )
      .subscribe(async (isAccepted) => {
        if (isAccepted) {
          const success = await this.clientService.delete(client);
          if (success) {
            this.toastComponent.showSuccessCustomMessage(
              `Cliente excluído com sucesso`,
              '',
              3000
            );
            this.redirectToClients();
          }
        }
      });
  }

  redirectToClients() {
    this.redirectTo('/clients');
  }

  mapperClient(): ClientDTO {
    const clientDto = new ClientDTO();
    const rawForm = this.formValidator.getRawValue();
    this.attachmentsToSave = [];

    this.attachments.forEach((a) => {
      if (!a.id && a.file) {
        this.attachmentsToSave.push(a.file);
      }
    });

    clientDto.id = this.clientId == 0 ? null : this.clientId;
    // Business Data
    clientDto.cnpj = rawForm.businessData?.cnpj;
    clientDto.registerState = rawForm.businessData?.stateRegistration;
    clientDto.corporateName = rawForm.businessData?.corporateName;
    clientDto.type = rawForm.businessData?.type || '.';
    clientDto.cnae = rawForm.businessData?.cnae || '.';
    clientDto.tradingName = rawForm.businessData?.fantasyName;
    clientDto.phone = rawForm.businessData?.phone.replace(/[\(\)-\s]/g, '');
    clientDto.email = rawForm.businessData?.email;
    clientDto.site = this.removeInvisibleChars(rawForm.businessData?.site);
    clientDto.isActive = rawForm.businessData.isActive;

    clientDto.attachments = this.attachmentsOld;

    clientDto.logoImgUri = this.lastImgUri;
    clientDto.hasBeenImageDeleted = rawForm.hasBeenImageDeleted;

    clientDto.isSameCnpjAddress =
      rawForm.mailingAddress?.isSameAddressAsBusinessAddress;
    clientDto.clientTypeLink = rawForm.link?.clientTypeLink;
    clientDto.clientLinkId = rawForm.link?.isOwn ? null : rawForm.link?.client;
    clientDto.isAdministrator = rawForm.link?.isOwn;
    clientDto.origin = (rawForm.businessData?.origin as string).toUpperCase();

    const businessAddress: AddressDTO = {
      cep: this.globalFunctions.removeMaskCep(rawForm.businessData.cep),
      city: rawForm.businessData.city,
      complement: rawForm.businessData.complement,
      district: rawForm.businessData.district,
      number: rawForm.businessData.number,
      streetAddress: rawForm.businessData.address,
      typeAddress: AddressType.TAX,
      uf: rawForm.businessData.state,
    };
    clientDto.addresses.push(businessAddress);

    //Mailing Address
    const mailingAddress: AddressDTO = {
      cep: this.globalFunctions.removeMaskCep(rawForm.mailingAddress.cep),
      city: rawForm.mailingAddress.city,
      complement: rawForm.mailingAddress.complement,
      district: rawForm.mailingAddress.district,
      number: rawForm.mailingAddress.number,
      streetAddress: rawForm.mailingAddress.address,
      typeAddress: AddressType.CORRESPONDENCE,
      uf: rawForm.mailingAddress.state,
    };
    clientDto.addresses.push(mailingAddress);

    // Complementary Data
    clientDto.domains = rawForm.complementaryData.domains;

    //Contacts
    const contactsClient = this.formValidator.get('contacts')?.value;
    const contactsToMapper: Contact[] = contactsClient.map((contact: any) => {
      const clientContact: Contact = new Contact();
      clientContact.id =
        contact.id > this.greaterContactCode ? undefined : contact.id;
      clientContact.name = contact.name;
      clientContact.email = contact.email;
      clientContact.phone = contact.phone;
      clientContact.role = contact.role;
      clientContact.type = contact.type;

      return clientContact;
    });

    clientDto.contacts = contactsToMapper;
    return clientDto;
  }

  redirectTo(path: string) {
    this.router.navigate([path]);
  }

  addLabel(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;
    const domainsCtrl = this.formValidator
      .get('complementaryData')
      ?.get('domains');
    const domains = domainsCtrl?.value;

    if (
      (value || '').trim() &&
      !domains.map((d: DomainDTO) => d.domain).includes(value)
    ) {
      domains.push({ domain: value.trim() });
      domainsCtrl?.patchValue(domains);
    }

    if (input) {
      input.value = '';
    }

    this.inputValue = '';
  }

  removeLabel(index: number): void {
    const domainsCtrl = this.formValidator
      .get('complementaryData')
      ?.get('domains');
    const domains = domainsCtrl?.value;
    domains.splice(index, 1);
    domainsCtrl?.patchValue(domains);
  }

  address(addresses: AddressInfo[]): AddressInfo {

    const adress = addresses.find((address) => address.typeAddress === AddressType.TAX);
    return adress ?? new AddressInfo();
  }

  private async fillClientData(client: ClientDTO): Promise<void> {
    this.clientId = client.id as number;
    this.getAssociatedClients(this.clientId);
    const [mailingAddress, businessAddress] = client.addresses.sort((address) =>
      address.typeAddress === AddressType.TAX ? 1 : -1
    );

    this.lastImgUri = client.logoImgUri;
    await this.getImage(client.logoImgUri);

    this.attachments = client.attachments as FileDTO[];
    this.attachmentsOld = [...(this.attachments as AttachmentDTO[])];
    this.situation = client.isActive;

    this.form = new Form({
      businessData: new BusinessData({
        address: businessAddress?.streetAddress,
        cep: businessAddress?.cep,
        city: businessAddress?.city,
        complement: businessAddress?.complement,
        state: businessAddress?.uf,
        district: businessAddress?.district,
        number: businessAddress?.number,
        cnpj: client.cnpj,
        fantasyName: client.tradingName,
        corporateName: client.corporateName,
        cnae: client.cnae,
        type: client.type,
        email: client.email,
        isActive: client.isActive,
        site: client.site,
        phone: client.phone,
        stateRegistration: client.registerState,
        origin: client.origin
      }),
      mailingAddress: new MailingAddress({
        address: mailingAddress?.streetAddress,
        cep: mailingAddress?.cep,
        city: mailingAddress?.city,
        complement: mailingAddress?.complement,
        district: mailingAddress?.district,
        number: mailingAddress?.number,
        state: mailingAddress?.uf,
        equalsCnpj: client.isSameCnpjAddress,
      }),
      link: new Link({
        name: client.clientTypeLink,
        client: client.clientLinkId,
        isOwn: client.isAdministrator,
      }),
      complementaryData: new ComplementaryData({
        domains: client.domains?.map((domain) => domain) || [],
      }),
      addNewContact: new AddNewContact({
        id: this.lastContactCodeUsed,
      }),
      stateRegistrationValid: false,
      contacts: client.contacts,
    });

    this.formValidator = this.formBuilder.group({
      logoImgUri: new FormControl(''),
      hasBeenImageDeleted: new FormControl(false),
      businessData: this.formBuilder.group({
        origin: new FormControl(
          { value: this.form.businessData.origin, disabled: true },
          Validators.required
        ),
        cnpj: new FormControl(
          { value: this.form.businessData.cnpj, disabled: true },
          Validators.required
        ),
        stateRegistration: new FormControl(
          { value: this.form.businessData.stateRegistration, disabled: true },
          [Validators.required, Validators.maxLength(17)]
        ),
        isActive: new FormControl({
          value: this.form.businessData.isActive,
          disabled: !this.canEdit,
        }),
        corporateName: new FormControl(
          { value: this.form.businessData.corporateName, disabled: true },
          Validators.required
        ),
        type: new FormControl(this.form.businessData.type),
        cnae: new FormControl(this.form.businessData.cnae),
        fantasyName: new FormControl(
          this.form.businessData.fantasyName
        ),
        site: new FormControl(this.form.businessData.site),
        phone: new FormControl(
          this.form.businessData.phone,
          Validators.required
        ),
        email: new FormControl(
          this.form.businessData.email,
          Validators.required
        ),
        cep: new FormControl(this.form.businessData.cep, Validators.required),
        address: new FormControl(
          this.form.businessData.address,
          Validators.required
        ),
        number: new FormControl(
          this.form.businessData.number,
          Validators.required
        ),
        complement: new FormControl(this.form.businessData.complement),
        district: new FormControl(
          this.form.businessData.district,
          Validators.required
        ),
        city: new FormControl(this.form.businessData.city, Validators.required),
        state: new FormControl(
          this.form.businessData.state,
          Validators.required
        )
      }),
      mailingAddress: this.formBuilder.group({
        equalsCnpj: new FormControl(
          this.form.mailingAddress.equalsCnpj,
          Validators.required
        ),
        cep: new FormControl(
          {
            value: this.form.mailingAddress.cep,
            disabled: this.form.mailingAddress.equalsCnpj,
          },
          Validators.required
        ),
        address: new FormControl(
          {
            value: this.form.mailingAddress.address,
            disabled: this.form.mailingAddress.equalsCnpj,
          },
          Validators.required
        ),
        number: new FormControl(
          {
            value: this.form.mailingAddress.number,
            disabled: this.form.mailingAddress.equalsCnpj,
          },
          Validators.required
        ),
        complement: new FormControl({
          value: this.form.mailingAddress.complement,
          disabled: this.form.mailingAddress.equalsCnpj,
        }),
        district: new FormControl(
          {
            value: this.form.mailingAddress.district,
            disabled: this.form.mailingAddress.equalsCnpj,
          },
          Validators.required
        ),
        city: new FormControl(
          {
            value: this.form.mailingAddress.city,
            disabled: this.form.mailingAddress.equalsCnpj,
          },
          Validators.required
        ),
        state: new FormControl(
          {
            value: this.form.mailingAddress.state,
            disabled: this.form.mailingAddress.equalsCnpj,
          },
          Validators.required
        ),
        isSameAddressAsBusinessAddress: new FormControl(
          client.isSameCnpjAddress
        ),
      }),
      complementaryData: this.formBuilder.group({
        domains: new FormControl(this.form.complementaryData.domains),
      }),
      link: this.formBuilder.group({
        clientTypeLink: new FormControl({
          value: this.form.link.name,
          disabled: !this.rolesService.getRole('ADMIN'),
        }),
        client: new FormControl({
          value: this.form.link.client,
          disabled: true,
        }),
        isOwn: new FormControl({ value: this.form.link.isOwn, disabled: true }),
      }),
      contacts: new FormControl(this.form.contacts),
    });

    this.initContacts();

    if (!this.canEdit) {
      this.formValidator.disable();
    }

    const autoRegistrationLinkFormControl = this.formValidator.get(
      'complementaryData.beneficiaryAutoRegistration'
    );

    autoRegistrationLinkFormControl?.valueChanges.subscribe((value = '') => {
      if (!value.startsWith(this.prefixLink)) {
        autoRegistrationLinkFormControl.patchValue(this.prefixLink);
        return;
      }

      if (value.length === this.prefixLink.length) return;

      autoRegistrationLinkFormControl.setValue(
        this.prefixLink +
        value
          .slice(this.prefixLink.length, value.length)
          .replace(/\s/g, '_')
          .replace(/[^a-zA-Z\d_]/g, ''),
        { emitEvent: false }
      );
    });

    this.disableForm();
  }

  redirectNewProduct() {
    this.confirmModal
      ?.showModal(
        'Deseja mesmo ir para Novo Produto?',
        'As alterações realizadas que não foram salvas serão perdidas.'
      )
      .subscribe(async (isAccepted) => {
        if (isAccepted) {
          this.redirectTo('products/new-product');
        }
      });
  }

  copyAutoRegistrationLink() {
    if (!this.isLinkEditable) {
      let link = this.formValidator
        .get('complementaryData')
        ?.get('beneficiaryAutoRegistration')?.value;
      navigator.clipboard.writeText(link);
      this.toastComponent.showSuccessCustomMessage(
        '',
        'Link copiado para a área de transferência.',
        3000
      );
    }
  }

  get hasPermissions() {
    if (this.rolesService.getRole('ADMIN')) return true;
    if (!this.clientId && this.canCreate) return true;
    if (this.clientId && this.canEdit) return true;

    return false;
  }

  redirectToConfigIntegration() {
    const extras: NavigationExtras = {
      queryParams: { id: this.clientId }
    }
    this.router.navigate(['/clients/config-integration'], extras);
  }

  isDirtyCategory() {
    this.formValidator.markAsDirty();
  }

  isUserClient(): boolean {
    const userCache = this.authService.getUserFromCache();
    return userCache?.groups.some((x) => x.name === 'CLIENT') ?? false;
  }

  disableForm() {
    if (this.isUserClient()) {
      this.formValidator.get('businessData')?.disable();
      this.formValidator.get('complementaryData')?.disable();
    }
  }

  disableIsOwnCheckbox() {
    return !!this.formValidator.get('link')?.disabled || !this.hasPermissions;
  }

  removeInvisibleChars(value: string): string {
    return this.globalFunctions.removeInvisibleChars(value);
  }
}

//#region Classes extras
export class Form {
  businessData: BusinessData;
  mailingAddress: MailingAddress;
  complementaryData: ComplementaryData;
  link: Link;
  addNewContact: AddNewContact;
  cnpjValid: boolean;
  businessCepValid: boolean;
  cepValid: boolean;
  files: Array<File>;
  selectedImage: any;
  stateRegistrationValid: boolean;
  contacts: Array<Contact>;

  constructor(
    props: {
      businessData?: BusinessData;
      mailingAddress?: MailingAddress;
      complementaryData?: ComplementaryData;
      link?: Link;
      addNewContact?: AddNewContact;
      files?: Array<File>;
      selectedImage?: any;
      stateRegistrationValid?: boolean;
      contacts?: Array<Contact>;
    } = {}
  ) {
    this.businessData = props?.businessData || new BusinessData();
    this.mailingAddress = props?.mailingAddress || new MailingAddress();
    this.complementaryData =
      props?.complementaryData || new ComplementaryData();
    this.link = props?.link || new Link();
    this.addNewContact = props?.addNewContact || new AddNewContact();
    this.cnpjValid = true;
    this.businessCepValid = true;
    this.cepValid = true;
    this.stateRegistrationValid = true;
    this.files = props?.files || [];
    this.contacts = props?.contacts || [];
  }

  addContact(contact: Contact) {
    this.contacts.push(contact);
  }
}

export class BusinessData {
  origin: string;
  cnpj: string;
  stateRegistration: string;
  isActive: boolean;
  corporateName: string;
  type: string;
  cnae: string;
  fantasyName: string;
  site: string;
  phone: string;
  email: string;
  cep: string;
  address: string;
  number: string;
  complement: string;
  district: string;
  city: string;
  state: string;

  constructor(props?: {
    origin?: string;
    cnpj?: string;
    stateRegistration?: string;
    isActive?: boolean;
    corporateName?: string;
    type?: string;
    cnae?: string;
    fantasyName?: string;
    site?: string;
    phone?: string;
    email?: string;
    cep?: string;
    address?: string;
    number?: string;
    complement?: string;
    district?: string;
    city?: string;
    state?: string;
  }) {
    this.origin = props?.origin || 'CADASTRO';
    this.cnpj = props?.cnpj || '';
    this.stateRegistration = props?.stateRegistration || '';
    this.isActive = !!props?.isActive;
    this.corporateName = props?.corporateName || '';
    this.type = props?.type || '';
    this.cnae = props?.cnae || '';
    this.site = props?.site || '';
    this.fantasyName = props?.fantasyName || '';
    this.phone = props?.phone || '';
    this.email = props?.email || '';
    this.cep = props?.cep || '';
    this.address = props?.address || '';
    this.number = props?.number || '';
    this.complement = props?.complement || '';
    this.district = props?.district || '';
    this.city = props?.city || '';
    this.state = props?.state || '';
  }
}

export class MailingAddress {
  equalsCnpj: boolean;
  cep: string;
  address: string;
  number: string;
  complement: string;
  district: string;
  city: string;
  state: string;

  constructor(props?: {
    equalsCnpj?: boolean;
    cep?: string;
    address?: string;
    number?: string;
    complement?: string;
    district?: string;
    city?: string;
    state?: string;
  }) {
    this.equalsCnpj = props?.equalsCnpj || false;
    this.cep = props?.cep || '';
    this.address = props?.address || '';
    this.number = props?.number || '';
    this.complement = props?.complement || '';
    this.district = props?.district || '';
    this.city = props?.city || '';
    this.state = props?.state || '';
  }
}

export class ComplementaryData {
  domains: Array<DomainDTO>;

  constructor(
    props: {
      domains?: Array<DomainDTO>;
    } = {}
  ) {
    this.domains = props?.domains || [];
  }
}

export class Link {
  name: string;
  isOwn: boolean;
  client?: number;

  constructor(
    props: {
      name?: string;
      isOwn?: boolean;
      client?: number;
    } = {}
  ) {
    this.name = props.name || '';
    this.isOwn = props.isOwn || false;
    this.client = props.client;
  }
}

export class AddNewContact {
  id: number;
  name: string;
  phone: string;
  contactApp: string;
  role: string;
  email: string;
  type: string;

  constructor(
    props: {
      name?: string;
      phone?: string;
      contactApp?: string;
      role?: string;
      email?: string;
      type?: string;
      id: number;
    } = { id: 0 }
  ) {
    this.name = props?.name || '';
    this.phone = props?.phone || '';
    this.contactApp = props?.contactApp || '';
    this.role = props?.role || '';
    this.email = props?.email || '';
    this.type = props?.type || '';
    this.id = props.id;
  }
}

enum LinkType {
  GRUPO_ECONOMICO = 'Grupo Econômico',
  MATRIZ_FILIAL = 'Matriz e Filial',
}
//#endregion Classes extras
