import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EMPTY as GO_TO_ON_COMPLETE, Observable, catchError, map, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ToastComponent } from '../components/toaster/toast/toast.component';
import { Page } from '../domains/base-page.interface';
import {
  PayrollResponse,
  PayslipRequest,
} from '../domains/report/payroll.interface';
import {
  ReportIncomeRequest,
  ReportLogResponse,
} from '../domains/report/report';
import { ReportServiceInterface } from '../interfaces/report-service.interface';

@Injectable({
  providedIn: 'root',
})
export class ReportService implements ReportServiceInterface {
  urlBase: string;

  constructor(
    private http: HttpClient,
    private toastComponent: ToastComponent
  ) {
    this.urlBase = environment.bff_web + '/report';
  }
  process(request: ReportIncomeRequest): Observable<boolean> {
    return this.http
      .post(this.urlBase + '/process/income', request, {
        observe: 'response',
      })
      .pipe(
        catchError((error) => {
          this.toastComponent.showApiError(error);
          return GO_TO_ON_COMPLETE;
        }),
        map(() => true)
      );
  }

  processPayslip(request: PayslipRequest): Observable<boolean> {
    return this.http
      .post(this.urlBase + '/process/payslip', request, {
        observe: 'response',
      })
      .pipe(
        catchError((error) => {
          this.toastComponent.showApiError(error);
          return GO_TO_ON_COMPLETE;
        }),
        map(() => true)
      );
  }

  getPayrolls(clientId: number): Observable<PayrollResponse[]> {
    return this.http
      .get<PayrollResponse[]>(this.urlBase + '/payrolls', {
        observe: 'response',
        params: {
          clientId,
        },
      })
      .pipe(
        catchError((error) => {
          this.toastComponent.showErrorCustomMessage(error.error.message, '');
          return throwError(() => error);
        }),
        map((response) => response.body as PayrollResponse[])
      );
  }

  getLogs(
    clientIds: number[],
    type: 'INCOME' | 'PAYSLIP',
    page: number,
    size: number
  ): Observable<Page<ReportLogResponse>> {
    return this.http
      .get<Page<ReportLogResponse>>(this.urlBase + '/logs', {
        observe: 'response',
        params: {
          clientIds,
          type,
          page,
          size,
        },
      })
      .pipe(
        catchError((error) => {
          this.toastComponent.showApiError(error);
          return GO_TO_ON_COMPLETE;
        }),
        map((response) => response.body as Page<ReportLogResponse>)
      );
  }
}
