import { DatePipe } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { NavigationExtras, Router } from '@angular/router';
import { Subscription, map } from 'rxjs';
import { ToastComponent } from 'src/app/shared/components/toaster/toast/toast.component';
import { DataImport } from 'src/app/shared/domains/data-import/data-import';
import { User } from 'src/app/shared/domains/user';
import { AuthService } from 'src/app/shared/services/auth.service';
import { DataImportService } from 'src/app/shared/services/data-import.service';
import { WebSocketService } from 'src/app/shared/services/webSocketService.service';
import {
  ImportStatus,
  ImportStatusWithLabels,
  ImportStatuspTypes,
} from 'src/app/shared/types/import-status.type';
import { GlobalFunctions } from 'src/app/shared/utils/global-functions';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-data-import-search',
  templateUrl: './data-import-search.component.html',
  styleUrls: ['./data-import-search.component.scss'],
})
export class DataImportSearchComponent implements OnInit, OnDestroy {
  dataImportDataSource = new MatTableDataSource<any>();

  @ViewChild('myInput')
  myInputVariable: any;
  @ViewChild(MatPaginator) paginator: MatPaginator | undefined;
  @ViewChild('dataImportTableSort') dataImportTableSort!: MatSort;

  displayedColumns: string[] = [
    'fileName',
    'userName',
    'createdDate',
    'importStart',
    'importEnd',
    'status',
    'actions',
  ];
  clientSelectedFormControl = new FormControl();
  searchDataImportFormControl = new FormControl();
  searchFiltered = "";
  allDataImport: DataImport[] = [];
  dataImportFilteredByClient: DataImport[] = [];
  userCache: User | null | undefined;
  webSocketServiceSubscription: Subscription | undefined;
  paginationCard: number = 10;

  constructor(
    private toastComponent: ToastComponent,
    private router: Router,
    public globalFunctions: GlobalFunctions,
    private dataImportService: DataImportService,
    public datepipe: DatePipe,
    private webSocketService: WebSocketService,
    private authService: AuthService
  ) {
    this.searchDataImportFormControl.valueChanges
      .pipe(
        map((value: string) => value.trim().toLocaleLowerCase()),
        map((value: string) =>
          value.trim().replace(/[.-]/g, '').toLocaleLowerCase()
        )
      )
      .subscribe((searchedValue) => {
        this.searchFiltered = searchedValue;
        this.applyFilter(searchedValue)
      });

    this.clientSelectedFormControl.valueChanges.subscribe((clientId) => {
      this.getImports(clientId);
      this.filterByClient();
    });
  }
  ngOnDestroy(): void {
    // this.webSocketService.disconnect();
    this.webSocketServiceSubscription?.unsubscribe();
  }

  async ngOnInit() {
    this.userCache = this.authService.getUserFromCache();
    this.webSocketServiceSubscription = this.webSocketService
      .getMessageSubject()
      .subscribe((value) => {
        this.atualziarLista(value);
      });
  }

  selectClient(id: number | null) {
    this.clientSelectedFormControl.setValue(id);
    this.conectWebSocket();
  }

  conectWebSocket() {
    if (this.userCache?.id && this.clientSelectedFormControl.value) {
      this.webSocketService.disconnect();
      this.webSocketService.connect(
        this.clientSelectedFormControl.value,
        `${this.userCache.id}`
      );
    }

  }

  onMessage(message: any) {
    console.log(message);
  }

  async getImports(clientIds: number) {
    const data = await this.dataImportService.getByClients(clientIds);

    this.allDataImport = data;
    this.dataImportFilteredByClient = data;

    //this.dataImportDataSource = new MatTableDataSource(data);
    this.dataImportDataSource = new MatTableDataSource(data);
    this.configTable();
  }

  filterByClient() {
    this.dataImportFilteredByClient = this.allDataImport.filter(
      (item) => item.idClient == this.clientSelectedFormControl.value
    );
    this.dataImportDataSource = new MatTableDataSource(
      this.dataImportFilteredByClient
    );
    this.configTable();
    this.searchDataImportFormControl.setValue('');
  }

  private applyFilter(filter: string) {
    if (!filter) {
      this.dataImportDataSource.data = this.allDataImport;
    } else {
      this.dataImportDataSource.data = this.dataImportFilteredByClient.filter(
        (element) =>
          this.dateFormat(element.importEnd).includes(filter) ||
          element.fileName.toLowerCase().includes(filter) ||
          (element.id as number).toString().includes(filter) ||
          this.dateFormat(element.createdDate).includes(filter) ||
          element.userName.toLowerCase().includes(filter) ||
          this.dateFormat(element.importStart).includes(filter) ||
          this.getLabel(element.status).toLowerCase().includes(filter)
      );
    }
  }

  dateFormat(date: Date) {
    return this.datepipe.transform(date, 'dd/MM/yyyy hh:mm:ss') ?? '';
  }

  private configTable() {
    if (this.paginator) this.dataImportDataSource.paginator = this.paginator;
    if (this.dataImportTableSort) {
      this.dataImportDataSource.sort = this.dataImportTableSort;
      this.dataImportDataSource.sortingDataAccessor = (item, property) => {
        if (property == 'nameAndSurname') {
          return (
            item['name']?.toLowerCase() + ' ' + item['lastName']?.toLowerCase()
          );
        }

        return item[property]?.toLowerCase();
      };
    }
  }

  redirectTo(path: string, extras: NavigationExtras = {}) {
    this.router.navigate([path], extras);
  }

  async downloadErrorFile(nomeArquivo: string, link: string) {
    if (link?.length > 0) {
      if (link.charAt(0) == '/') {
        link = link.substring(1);
      }
    }
    const data = await this.dataImportService.getBlob(link);
    let a = document.createElement('a');
    a.href = URL.createObjectURL(data);
    a.download = `log_${nomeArquivo}.txt`;
    a.click();
  }

  async downloadImportedFile(nomeArquivo: string, link: string) {
    if (link?.length > 0) {
      if (link.charAt(0) == '/') {
        link = link.substring(1);
      }
    }

    const data = await this.dataImportService.getBlob(link);
    let a = document.createElement('a');
    a.href = URL.createObjectURL(data);
    a.download = `log_${nomeArquivo}.txt`;
    a.click();
  }

  async downloadFileModel() {
    const data = await this.dataImportService.getBlob(
      environment.bff_model_file
    );
    let a = document.createElement('a');
    a.href = URL.createObjectURL(data);
    a.download = `comunify-modelo-de-importacao.pdf`;
    a.click();
  }

  async importFiles(event: Event) {
    const element = event.currentTarget as HTMLInputElement;
    const fileList: FileList | null = element.files;

    if (fileList && fileList.length > 0) {
      const files: File[] = Array.from(fileList);
      const invalidFiles = files.filter(file => !file.name.endsWith('.txt'));

      if (invalidFiles.length > 0) {
        this.toastComponent.showWarningCustomMessage(
          'Arquivos inválidos!',
          'Alguns arquivos selecionados não são válidos para esta importação.'
        );
        return;
      }

      try {
        await this.dataImportService.importFile(
          this.userCache?.name ?? '',
          files
        );
      } catch (error) {
        console.error('Erro ao importar arquivos:', error);
      } finally {
        element.value = '';
      }
    }
  }

  getLabel(status: ImportStatus) {
    return ImportStatusWithLabels.find((x) => x.value == status)?.label ?? '';
  }

  getStyleObject(status: ImportStatus) {
    return {
      'waiting-for-processing':
        status == ImportStatuspTypes.AWAITING_PROCESSING.value,
      processing: status == ImportStatuspTypes.PROCESSING.value,
      'success-fully-processed':
        status == ImportStatuspTypes.SUCCESSFULLY_PROCESSED.value,
      'processed-with-failure':
        status == ImportStatuspTypes.PROCESSED_FAILED.value,
      erro: status == ImportStatuspTypes.ERRO.value,
    };
  }

  atualziarLista(dataImport: DataImport) {
    if (dataImport) {
      let indexOf = this.dataImportDataSource.data.findIndex(
        (x) => x.id == dataImport.id
      );
      if (indexOf >= -1) {
        this.dataImportDataSource.data[indexOf] = { ...dataImport };
      }

      this.dataImportDataSource.data = [...this.dataImportDataSource.data];
      this.checkAllowDownloadReview();
    }
  }

  checkAllowDownloadReview(): void {
    const userCacheId = this.userCache?.id;
    const data = this.dataImportDataSource?.data;

    if (data && data.length > 0) {
      data.forEach(item => item.allowDownload = item?.userId === userCacheId);
    }
  }

  async recarregarDados() {
    if (this.clientSelectedFormControl.value) {
      await this.getImports(this.clientSelectedFormControl.value);
      this.applyFilter(this.searchFiltered);
    }
  }
}
