import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { MatMenuTrigger } from '@angular/material/menu';
import { DomSanitizer, SafeUrl, Title } from '@angular/platform-browser';
import {
  NavigationEnd,
  NavigationExtras,
  NavigationStart,
  Router,
} from '@angular/router';
import { NgxRolesService } from 'ngx-permissions';
import { Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
import { NotificationsComponent } from './shared/components/notifications/notifications.component';
import { ToastComponent } from './shared/components/toaster/toast/toast.component';
import { ProfilePermissions } from './shared/constants/profile-permissions';
import { User } from './shared/domains/user';
import { UserResumed } from './shared/domains/userResumed';
import { LocalStorageKeys } from './shared/enums/storage-keys';
import { AuthServiceV2 } from './shared/services/auth-v2/auth-v2.service';
import { DataImportService } from './shared/services/data-import.service';
import { ImageService } from './shared/services/image.service';
import { LoadingService } from './shared/services/loading.service';
import { PartnerService } from './shared/services/partner.service';
import { SharedService } from './shared/services/shared.service';
import { UserClientService } from './shared/services/user-client.service';
import { WebSocketNotificationService } from './shared/services/webSocket-notification.service';
import { GlobalFunctions } from './shared/utils/global-functions';
import getMyAccountRouter from './shared/utils/myAccount-router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  routes: Array<RoutesName> = [{ name: 'Home', path: '/home' }];
  user: User = new User();
  permissions = ProfilePermissions;

  networkSubscription = new Subscription();
  networkTitle = '';

  forumTopicSubscription = new Subscription();
  communityForumTopicTitle = '';

  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger | undefined;

  @ViewChild('appNotification') appNotification: NotificationsComponent | undefined;
  notificationsCount: number = 0;
  getFirstNotification: boolean = false;

  logo = environment.logo_branca;
  logoMobile = environment.icone_branco;

  isUserClient: boolean = false;

  image!: string;

  showLoading:boolean = false;

  hasUser: boolean = false;
  imageToPreview?: SafeUrl;

  get hasProfile(): boolean {
    const profiles = this.authService.getLocalProfiles()
    return !!profiles && profiles.length > 1;
  }

  constructor(
    private matIconRegistry: MatIconRegistry,
    private router: Router,
    private authService: AuthServiceV2,
    private toastComponent: ToastComponent,
    public loadingService: LoadingService,
    private titleService: Title,
    private rolesService: NgxRolesService,
    public globalFunctions: GlobalFunctions,
    private sharedService: SharedService,
    private partnerService: PartnerService,
    private userClientService: UserClientService,
    private sanitizer: DomSanitizer,
    private dataImportService: DataImportService,
    private imageService: ImageService,
    private webSocketService: WebSocketNotificationService,
    private domSanitizer: DomSanitizer
  ) {
    this.matIconRegistry.addSvgIcon('trophy', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/trophy.svg'));
    this.imageService.changeImageObservable.subscribe(url => this.getImage(url));
    this.imageService.changeNameObservable.subscribe(name => this.changeName(name));

    this.loadingService.showLoading$.subscribe((show) => {
      this.showLoading = show;
    })
  }

  ngOnInit(): void {
    this.obterInformacaoUsuario();
    this.networkSubscription = this.sharedService
      .setNetworkSubject()
      .subscribe((response: string) => {
        this.networkTitle = response;
        if (this.networkTitle.length >= 27) {
          this.networkTitle = this.networkTitle.substring(0, 27) + "...";
        }
        this.carregarRotas();
      });

    this.forumTopicSubscription = this.sharedService
      .setCommunityForumTopicTitle()
      .subscribe((response: string) => {
        this.communityForumTopicTitle = response;
        if (this.communityForumTopicTitle.length >= 27) {
          this.communityForumTopicTitle = this.communityForumTopicTitle.substring(0, 27) + "...";
        }
        this.carregarRotas();
      });

    this.sharedService
      .setCarregarRotasSubject()
      .subscribe(() => {
        this.obterInformacaoUsuario();
        this.carregarRotas();
      });

    this.sharedService.getNotificationsCountMobile().subscribe((value) => {
      this.notificationsCount = value
    })

    this.webSocketService.conectWebSocket();

    this.router.events.subscribe(async (event) => {

      if (event instanceof NavigationStart) {
        if (!this.router.navigated) {
          const userCache = this.authService.getUserFromCache();
          if (userCache) {
            this.user = userCache;
            this.setGroupsAndAuthorities();
          }
        }
      }

      if (event instanceof NavigationEnd) {
        const userCache = this.authService.getUserFromCache();
        const token = this.authService.getToken();

        if (userCache && token) {
          this.carregarRotas();
          this.user = userCache;
          this.setGroupsAndAuthorities();

          if (!this.getFirstNotification) {
            this.getFirstNotification = true;
            this.sharedService.callNotification();
          }
        } else if (
          event.url !== '/' &&
          !event.url.includes('/login') &&
          !event.url.includes('/privacy-policy') &&
          !event.url.includes('/autocadastro') &&
          !event.url.includes('/dashboard')
        ) {
          this.unauthorized();
        }
      }
    });
  }

  obterInformacaoUsuario() {
    const userCache = this.authService.getUserFromCache();
    if (userCache) {
      this.user = userCache;
      this.hasUser = true;
      if (this.user.uri) {
        this.getImage(this.user.uri)
      };
    }
    this.isUserClient = false;
    if (this.user.groups.some((x) => x.name === 'CLIENT')) {
      this.isUserClient = true;
    }

  }

  private setGroupsAndAuthorities() {
    const groups = localStorage.getItem(LocalStorageKeys.WRH_CACHE_GROUPS);
    const authorities = localStorage.getItem(
      LocalStorageKeys.WRH_CACHE_AUTHORITIES
    );

    if (groups && authorities) {
      (JSON.parse(groups) as []).forEach((e) =>
        this.rolesService.addRoleWithPermissions(e, JSON.parse(authorities))
      );
    }
  }

  carregarRotas() {
    this.routes = [{ name: 'Home', path: '/home' }];
    this.titleService.setTitle('Home');
    this.getRoutesToDisplay();
  }

  unauthorized() {
    this.toastComponent.showWarningCustomMessage(
      'Atenção!',
      'Token ou dados do usuário inválidos em cache! Necessário fazer login novamente.'
    );
    this.authService.logout();
    this.router.navigate(['/login']);
    this.titleService.setTitle('Login');
  }

  isRouterLogin(): boolean {
    return this.router.url.includes('/login');
  }

  isRouterPrivacyPolicy(): boolean {
    return this.router.url.includes('/privacy-policy');
  }

  isRouterSelfRegister(): boolean {
    return this.router.url.includes('/autocadastro');
  }

  goToRouter(url: string) {
    this.router.navigate([url]);
  }

  async redirectToClientNoticeRoute(path: string) {
    try {
      const userCache = this.authService.getUserFromCache();
      if (userCache) {
        this.user = userCache;
      }
      if (this.user.groups.some((x) => x.name === 'CLIENT')
        && !this.user.groups.some((x) => x.name === 'ADMIN')) {
        const result = await this.userClientService.getResumedUserClientByUserIdAndClientId(
          this.user?.id ?? 0
        );

        const extras: NavigationExtras = {
          queryParams: { clientId: result.client.id },
        };

        await this.router.navigate([path], extras);
      } else {
        const extras: NavigationExtras = {
          queryParams: { clientId: this.user.id },
        };

        await this.router.navigate([path], extras);
      }
    } catch (error) {
      console.error('Erro durante a navegação:', error);
    }
  }

  async goToMyAccountRouter() {
    if (this.user.groups.some((x) => x.name === 'ADMIN')) {
      const extras: NavigationExtras = {
        queryParams: { userId: this.user.id },
      };
      this.router.navigate(
        [`/internal-users-account/${getMyAccountRouter()}`],
        extras
      );
    } else if (this.user.groups.some((x) => x.name === 'PCV')) {
      const result = await this.partnerService.getByUserId(this.user.id ?? 0);
      this.redirectToWithParams(
        `/partners-account/${getMyAccountRouter()}`,
        result.id
      );
    } else if (this.user.groups.some((x) => x.name === 'CLIENT')) {
      const result = await this.userClientService.getResumedUserClientByUserIdAndClientId(
        this.user.id ?? 0
      );
      const extras: NavigationExtras = {
        queryParams: { id: result.id },
      };
      this.router.navigate(
        [`/users-clients-account/${getMyAccountRouter()}`],
        extras
      );
    }
  }

  updateUserInfo(result: UserResumed) {
    this.user.id = result.id;
    this.user.isActive = result.isActive;
    this.user.userId = result.userId;
    this.user.name = result.name;
    this.user.email = result.email;
  }

  logout() {
    this.authService.logout();
    this.rolesService.flushRolesAndPermissions();
    this.router.navigate(['/login']);
    this.titleService.setTitle('Login');
    this.getFirstNotification = false;
    this.appNotification?.ngOnDestroy();
    this.webSocketService.disconnect()
  }

  getRoutesToDisplay() {
    let path = this.router.url;

    if (path !== '/home') {
      if (path[0] === '/') path = path.slice(1, path.length);

      const pathsRoute = path.split('/');

      for (let i = 0; i < pathsRoute.length; i++) {
        pathsRoute[i] = pathsRoute[i].replace(/\?{1}[\d\D=]+/g, '');
        const title = this.brPaths(pathsRoute[i]);
        this.titleService.setTitle(title);
        if (!this.isMyAccount(pathsRoute, i)) {
          this.routes.push({ path: pathsRoute[i], name: title });
        }
      }
    }
  }

  isMyAccount(pathsRoute: string[], index: number) {
    return (
      pathsRoute[index] === 'partners-account' ||
      pathsRoute[index] === 'internal-users-account' ||
      pathsRoute[index] === 'users-clients-account'
    );
  }

  //#region Breadcrumb
  brPaths(path: string): string {
    switch (path) {
      case 'login':
        return 'Login';
      case 'change-password':
        return 'Alterar Senha';
      case 'my-account':
        return 'Minha Conta';
      case 'client':
        return 'Editar Minha Conta';
      case 'notification-mobile':
        return 'Notificações';
      case 'internal-users':
        return 'Usuários Internos';
      case 'new-user':
        return 'Novo Usuário';
      case 'edit-user':
        return 'Editar Usuário';
      case 'notices':
        return 'Avisos';
      case 'new-notice':
        return 'Novo Aviso';
      case 'edit-notice':
        return 'Editar Aviso';
      case 'view-notice':
        return 'Visualizar Aviso';
      case 'clients':
        return this.isUserClient ? 'Dados Cliente' : 'Clientes';
      case 'new-client':
        return 'Novo Cliente';
      case 'edit-client':
        return 'Editar Cliente';
      case 'view-client':
        return 'Visualizar Cliente';
      case 'users-clients':
        return 'Usuários Cliente';
      case 'new-user-client':
        return 'Novo Usuário Cliente';
      case 'edit-user-client':
        return 'Editar Usuário Cliente';
      case 'view-user-client':
        return 'Visualizar Usuário Cliente';
      case 'user-profiles':
        return this.isUserClient ? 'Perfis de Usuários' : 'Perfis de Usuários Cliente';
      case 'new-profile':
        return 'Novo Perfil';
      case 'edit-profile':
        return 'Editar Perfil';
      case 'view-profile':
        return 'Visualizar Perfil';
      case 'collaborators':
        return 'Colaboradores';
      case 'new-collaborator':
        return 'Novo Colaborador';
      case 'edit-collaborator':
        return 'Editar Colaborador';
      case 'view-collaborator':
        return 'Visualizar Colaborador';
      case 'data-import':
        return 'Importação de Dados';
      case 'notices-client':
        return 'Avisos';
      case 'networks':
        return 'Networks';
      case 'new-network':
        return 'Novo Network';
      case 'edit-network':
        return 'Editar Network';
      case 'view-network':
        return 'Visualizar Network';
      case 'comments':
        return this.networkTitle;
      case 'my-presentation':
        return 'Minha Apresentação';
      case 'config-integration':
        return "Configurar Integração";
      case 'logs':
        return "Visualização de Logs";
      case 'partners':
        return 'Parceiros';
      case 'partners-account':
        return 'Minha conta';
      case getMyAccountRouter():
        return 'Minha conta';
      case 'new-partner':
        return 'Novo Parceiro';
      case 'edit-partner':
        return 'Editar Parceiro';
      case 'view-partner':
        return 'Visualizar Parceiro';
      case 'benefits':
        return 'Benefícios';
      case 'new-benefit':
        return 'Novo Benefício';
      case 'edit-benefit':
        return 'Editar Benefício';
      case 'view-benefit':
        return 'Visualizar Benefício';
      case 'privacy-policy':
        return 'Política de Privacidade';
      case 'posts':
        return 'Posts';
      case 'store-links':
        return 'Baixe nosso aplicativo';
      case 'surveys':
        return 'Enquetes';
      case 'post-moderation':
        return 'Configuração Moderação de Posts';
      case 'new-survey':
        return 'Nova Enquete';
      case 'edit-survey':
        return 'Editar Enquete';
      case 'view-survey':
        return 'Visualizar Enquete';
      case 'events':
        return 'Eventos';
      case 'user-groups':
        return 'Grupo de Pessoas';
      case 'register-user-group':
        return 'Novo Grupo';
      case 'edit-user-group':
        return 'Editar Grupo';
      case 'view-user-group':
        return 'Visualizar Grupo';
      case 'gamification':
        return 'Gamificação';
      case 'adventure':
        return 'Aventura';
      case 'missions':
          return 'Missões';
      case 'campaign-extract':
        return 'Extrato de campanha';
      case 'show-reported-comments':
        return 'Posts';
      case 'proposal-request':
        return 'Integração Parceiros';
      case 'user-client-group':
        return 'Grupos de Conteúdo';
      case 'new-user-client-group':
        return 'Novo Grupo';
      case 'edit-user-client-group':
        return 'Editar Grupo';
      case 'view-user-client-group':
        return 'Visualizar Grupo';
      case 'category':
        return 'Categorias';
      case 'new-category':
        return 'Nova Categoria';
      case 'edit-category':
        return 'Editar Categoria';
      case 'view-category':
        return 'Visualizar Categoria';
      case 'dashboard':
        return 'Dashboard'
      case 'collaborator-transfer':
        return 'Tranferência de colaboradores';
      case 'community-forum':
        return 'Fórum da Comunidade';
      case 'third-party-user':
        return 'Terceiros';
      case 'new-third-party-user':
        return 'Novo Terceiro';
      case 'edit-third-party-user':
        return 'Editar Terceiro';
      case 'view-third-party-user':
        return 'Visualizar Categoria';
      case 'topic':
        return this.communityForumTopicTitle;
      case 'document-repository':
        return 'Documentos';
      case 'by':
        return 'Post';
      case 'useful-link':
        return 'Links Úteis';
    }
    return `@-> ADICIONAR TRADUCAO ${path}`;
  }
  //#endregion Breadcrumb

  redirectTo(path: string) {
    this.router.navigate([path]);
  }

  redirectToWithParams(path: string, id: number) {
    const extras: NavigationExtras = {
      queryParams: { userId: id },
    };
    this.router.navigate([path], extras);
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    //Apenas para atualizar o tamanho da tela sem precisar de refresh.
  }

  openNotifications() {
    if (this.globalFunctions.isMobile()) {
      this.router.navigate(['/notification-mobile']);
      this.trigger?.closeMenu();
    }
  }

  get isHome() {
    return this.router.url.includes('/home');
  }

  async getImage(imgUrl: string) {
    if (imgUrl) {
      const blob = await this.dataImportService.getBlob(imgUrl);
      const file = new File([blob], 'img', { type: blob.type });
      const reader = new FileReader();
      reader.onload = (e: any) => {
        this.image = e.target.result;
        this.sanitizeImageUrl(this.image);
      };
      reader.readAsDataURL(file);
    } else {
      this.imageToPreview = undefined;
    }
  }

  sanitizeImageUrl(imageUrl: string) {
    this.imageToPreview = this.sanitizer.bypassSecurityTrustUrl(imageUrl);
  }

  changeName(name: string) {
    this.user.name = name;
  }
}

export class RoutesName {
  name: string;
  path: string;

  constructor() {
    this.name = '';
    this.path = '';
  }
}
