import { BreakpointObserver } from '@angular/cdk/layout';
import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatStepper, StepperOrientation } from '@angular/material/stepper';
import { MatTableDataSource } from '@angular/material/table';
import { debounceTime, map, Observable } from 'rxjs';
import { SearchColumnConfiguration } from 'src/app/shared/components/search/interfaces/search-table-column-configuration.interface';
import { ToastComponent } from 'src/app/shared/components/toaster/toast/toast.component';
import { ClientResumedDTO } from 'src/app/shared/domains/clients/client-resumed-dto';
import { ClientService } from 'src/app/shared/services/client.service';
import { GlobalFunctions } from 'src/app/shared/utils/global-functions';
import { ClientDTO } from '../../domains/clientDto';
import {
  GroupBeneficiaryResponseDTO,
  PersonaGroupResumedDTO,
  ReportIncomeDTO,
} from '../../domains/GroupCollaborator/group-collaborator-dto';
import { PersonaService } from '../../services/persona.service';

@Component({
  selector: 'app-associate-collaborator-modal',
  templateUrl: './associate-collaborator-modal.component.html',
  styleUrls: ['./associate-collaborator-modal.component.scss'],
})
export class AssociateCollaboratorModalComponent
  implements OnInit, AfterViewInit
{
  @Input()
  clientId: number | undefined;

  @Input()
  visible: boolean = false;

  @Input()
  selectedUsers: any[] = [];

  @Output()
  onSelectUsers = new EventEmitter<GroupBeneficiaryResponseDTO[]>();

  @Output()
  onSelectValues = new EventEmitter<ReportIncomeDTO>();

  @Output()
  onClose = new EventEmitter<boolean>();

  @ViewChild('stepper')
  stepper?: MatStepper;

  columnsConfiguration: SearchColumnConfiguration[] = [
    {
      columnName: '',
      isCheckbox: true,
      valueName: 'checked',
    },
    {
      columnName: 'Nome',
      valueName: 'name',
      columnValueCssClass: 'column-60',
      columnHeaderCssClass: 'column-60',
    },
  ];

  userColumnsConfiguration: SearchColumnConfiguration[] = [
    {
      columnName: '',
      isCheckbox: true,
      valueName: 'checked',
    },
    {
      columnName: 'Nome',
      valueName: 'fullName',
      columnValueCssClass: 'column-30',
      columnHeaderCssClass: 'column-30',
    },
    {
      columnName: 'Empresa',
      valueName: 'clientName',
      columnValueCssClass: 'column-20',
      columnHeaderCssClass: 'column-20',
    },
    {
      columnName: '',
      valueName: 'collaboratorTag',
      columnValueCssClass: 'column-20',
      columnHeaderCssClass: 'column-20',
      isTag: true,
    },
  ];

  stepperOrientation!: Observable<StepperOrientation>;

  clientDataSource = new MatTableDataSource<any>();
  allClients: any[] = [];
  searchClientControl = new FormControl('');
  usersDataSource = new MatTableDataSource<any>();
  searchUserControl = new FormControl('');

  year = new FormControl('', Validators.required);

  get selectedClients(): ClientResumedDTO[] {
    return this.clientDataSource.data.filter((x) => x.checked);
  }

  get allSelectedUsers(): GroupBeneficiaryResponseDTO[] {
    const usersDataSource = this.usersDataSource.data
      .filter((x) => x.checked)
      .map((value: any) => {
        return {
          id: value.id,
          clientName: value.clientName,
          name: value.fullName,
          clientId: value.clientId,
          personaType: value.personaType,
          checked: undefined,
          collaborator_key: value.collaborator_key,
        };
      });

    usersDataSource.push(...this.selectedUsers);
    return usersDataSource.filter(onlyUnique);
  }

  constructor(
    private toastComponent: ToastComponent,
    public globalFunctions: GlobalFunctions,
    breakpointObserver: BreakpointObserver,
    private personaService: PersonaService,
    private clientService: ClientService
  ) {
    this.stepperOrientation = breakpointObserver
      .observe('(min-width: 800px)')
      .pipe(map(({ matches }) => (matches ? 'horizontal' : 'vertical')));

    this.usersDataSource.filterPredicate = (data, filter: string) => {
      return data.fullName.toLowerCase().includes(filter);
    };

    this.searchUserControl.valueChanges
      .pipe(
        debounceTime(500),
        map((value: string) =>
          value.trim().replace(/[.-]/g, '').toLocaleLowerCase()
        )
      )
      .subscribe((searchedValue) => {
        this.usersDataSource.filter = searchedValue;
      });

    this.clientDataSource.filterPredicate = (data, filter: string) => {
      return (
        data.tradingName?.toLowerCase().includes(filter) ||
        data.corporateName?.toLowerCase().includes(filter)
      );
    };

    this.searchClientControl.valueChanges
      .pipe(
        debounceTime(500),
        map((value: string) =>
          value.trim().replace(/[.-]/g, '').toLocaleLowerCase()
        )
      )
      .subscribe((searchedValue) => {
        this.clientDataSource.filter = searchedValue;
      });
  }
  ngAfterViewInit(): void {
    this.stepper!._getIndicatorType = () => 'number';
  }

  async ngOnInit() {
    this.allClients = await this.clientService.getClientsCombobox();
    this.clientDataSource = new MatTableDataSource(
      this._mapClients(this.allClients)
    );
    if (this.clientId) this.getUsers(this.clientId);
  }

  private _mapClients(clients: ClientDTO[]) {
    return clients.map((client) => {
      return {
        ...client,
        name:
          (client.tradingName?.trim().length ?? 0) > 0
            ? client.tradingName
            : client.corporateName,
      };
    });
  }

  async goNext(stepper: MatStepper) {
    if (this.selectedClients.length === 0) {
      this.toastComponent.showWarningCustomMessage(
        'Selecione pelo menos uma empresa!'
      );
      return;
    }

    stepper.next();
  }

  async getUsers(clientId?: number | undefined) {
    const clientIds = this.selectedClients.map((x) => x.id);
    this.searchUserControl.setValue('');
    const today = new Date();
    this.usersDataSource = new MatTableDataSource(
      this._mapUsers(
        await this.personaService.getFilteredPersonasByClient(
          clientId ? [clientId] : clientIds,
          '',
          this.clientId ? 'COLLABORATOR' : undefined,
          clientId ? 12 + today.getMonth() + 1 : undefined,
          clientId ? undefined : false
        )
      )
    );
    this.usersDataSource.data.forEach((user) => {
      if (
        this.selectedUsers.some(
          (v) => v.id === user.id && v.personaType === user.personaType
        )
      ) {
        user.checked = true;
      }
    });
  }

  private _mapUsers(users: PersonaGroupResumedDTO[]) {
    return users.map((user) => {
      const client = this.allClients.find((c) => c.id === user.clientId);
      return {
        ...user,
        clientName:
          (client?.tradingName?.trim().length ?? 0) > 0
            ? client?.tradingName
            : client?.corporateName,
        collaboratorTag:
          user.personaType === 'COLLABORATOR' ? 'Colaborador' : 'Terceiro',
      };
    });
  }

  onConfirm() {
    if (!this.clientId) {
      this.onSelectUsers.emit(this.allSelectedUsers);
    } else {
      this.onSelectValues.emit({
        beneficiaries: this.allSelectedUsers,
        year: this.year.value,
      });
    }
    this.onClose.emit();
  }

  close() {
    this.onClose.emit();
  }
}

function onlyUnique(
  value: GroupBeneficiaryResponseDTO,
  index: number,
  array: GroupBeneficiaryResponseDTO[]
) {
  return (
    array.findIndex(
      (v) => v.id === value.id && v.personaType === value.personaType
    ) === index
  );
}
