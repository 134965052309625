import { BreakpointObserver } from '@angular/cdk/layout';
import { DatePipe } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatStepper, StepperOrientation } from '@angular/material/stepper';
import { MatTableDataSource } from '@angular/material/table';
import { catchError, debounceTime, map, Observable, of, take } from 'rxjs';
import {
  GroupBeneficiaryResponseDTO,
  PersonaGroupResumedDTO,
} from '../../domains/GroupCollaborator/group-collaborator-dto';
import { PayrollResponse } from '../../domains/report/payroll.interface';
import { PersonaService } from '../../services/persona.service';
import { ReportService } from '../../services/report.service';
import { GlobalFunctions } from '../../utils/global-functions';
import { SearchColumnConfiguration } from '../search/interfaces/search-table-column-configuration.interface';
import { ToastComponent } from '../toaster/toast/toast.component';

@Component({
  selector: 'app-payroll-select',
  templateUrl: './payroll-select.component.html',
  styleUrls: ['./payroll-select.component.scss'],
})
export class PayrollSelectComponent implements OnInit {
  @Input()
  clientId: number = 0;

  @Input()
  visible: boolean = false;

  @Input()
  selectedUsers: any[] = [];

  @Output()
  onClose = new EventEmitter<boolean>();

  @ViewChild('stepper')
  stepper?: MatStepper;

  userColumnsConfiguration: SearchColumnConfiguration[] = [
    {
      columnName: '',
      isCheckbox: true,
      valueName: 'checked',
    },
    {
      columnName: 'Nome',
      valueName: 'fullName',
      columnValueCssClass: 'column-30',
      columnHeaderCssClass: 'column-30',
    },
  ];

  payrollColumnsConfiguration: SearchColumnConfiguration[] = [
    {
      columnName: 'Código do cálculo',
      valueName: 'paymentCode',
      columnValueCssClass: 'column-30',
      columnHeaderCssClass: 'column-30',
    },
    {
      columnName: 'Tipo',
      valueName: 'typeCode',
      columnValueCssClass: 'column-30',
      columnHeaderCssClass: 'column-30',
    },
    {
      columnName: 'Data de pagamento',
      valueName: 'paymentDateLabel',
      columnValueCssClass: 'column-30',
      columnHeaderCssClass: 'column-30',
    },
    {
      actionsConfiguration: {
        useEditButton: false,
        useViewButton: false,
        useDeleteFeature: false,
        extraActions: [
          {
            iconName: 'payment',
            tooltip: 'Gerar',
            onClick: this.process.bind(this),
            css: 'margin-right: 8px;',
          },
        ],
      },
      columnName: '',
      valueName: 'actions',
      columnValueCssClass: 'p-0 d-flex justify-content-end',
    },
  ];

  stepperOrientation!: Observable<StepperOrientation>;

  usersDataSource = new MatTableDataSource<any>();
  allUsers: PersonaGroupResumedDTO[] = [];
  searchUserControl = new FormControl('');

  payrollDataSource = new MatTableDataSource<any>();
  allPayrolls: PayrollResponse[] = [];
  searchPayrollControl = new FormControl('');

  get allSelectedUsers(): GroupBeneficiaryResponseDTO[] {
    const usersDataSource = this.usersDataSource.data
      .filter((x) => x.checked)
      .map((value: any) => {
        return {
          id: value.id,
          clientName: value.clientName,
          name: value.fullName,
          clientId: value.clientId,
          personaType: value.personaType,
          checked: undefined,
          collaborator_key: value.collaborator_key,
        };
      });

    usersDataSource.push(...this.selectedUsers);
    return usersDataSource.filter(onlyUnique);
  }

  constructor(
    private toastComponent: ToastComponent,
    public globalFunctions: GlobalFunctions,
    breakpointObserver: BreakpointObserver,
    private personaService: PersonaService,
    private datePipe: DatePipe,
    private reportService: ReportService
  ) {
    this.stepperOrientation = breakpointObserver
      .observe('(min-width: 800px)')
      .pipe(map(({ matches }) => (matches ? 'horizontal' : 'vertical')));

    this.searchUserControl.valueChanges
      .pipe(
        debounceTime(500),
        map((value: string) =>
          value.trim().replace(/[.-]/g, '').toLocaleLowerCase()
        )
      )
      .subscribe((searchedValue) => {
        this.usersDataSource = new MatTableDataSource(
          this._mapUsers(
            this.allUsers.filter((element) =>
              element.fullName.toLowerCase().includes(searchedValue)
            )
          )
        );
        this.usersDataSource.data.forEach((user) => {
          if (
            this.selectedUsers.some(
              (v) => v.id === user.id && v.personaType === user.personaType
            )
          ) {
            user.checked = true;
          }
        });
      });

    this.searchPayrollControl.valueChanges
      .pipe(
        debounceTime(500),
        map((value: string) =>
          value.trim().replace(/[.-]/g, '').toLocaleLowerCase()
        )
      )
      .subscribe((searchedValue) => {
        this.payrollDataSource = new MatTableDataSource(
          this._mapPayrolls(
            this.allPayrolls.filter((element) =>
              element.typeCode.toLowerCase().includes(searchedValue)
            )
          )
        );
      });
  }

  ngAfterViewInit(): void {
    this.stepper!._getIndicatorType = () => 'number';
  }

  async ngOnInit() {
    this.getUsers();
    this._getPayrolls();
  }

  async goNext(stepper: MatStepper) {
    if (this.allSelectedUsers.length === 0) {
      this.toastComponent.showWarningCustomMessage(
        'Selecione pelo menos um colaborador!'
      );
      return;
    }

    stepper.next();
  }

  async getUsers() {
    this.allUsers = await this.personaService.getFilteredPersonasByClient(
      [this.clientId],
      '',
      'COLLABORATOR'
    );
    this.searchUserControl.setValue('');
    this.usersDataSource = new MatTableDataSource(
      this._mapUsers(this.allUsers)
    );
    this.usersDataSource.data.forEach((user) => {
      if (
        this.selectedUsers.some(
          (v) => v.id === user.id && v.personaType === user.personaType
        )
      ) {
        user.checked = true;
      }
    });
  }

  private async _getPayrolls() {
    this.reportService.getPayrolls(this.clientId)
    .pipe(catchError((error) => {
      this.close();
      return of();
    }))
    .subscribe((response) => {
      this.allPayrolls = response;
      this.searchPayrollControl.setValue('');
      this.payrollDataSource = new MatTableDataSource(
        this._mapPayrolls(this.allPayrolls)
      );
    });
  }

  private _mapUsers(users: PersonaGroupResumedDTO[]) {
    return users.map((user) => {
      return {
        ...user,
      };
    });
  }

  private _mapPayrolls(payrolls: PayrollResponse[]) {
    return payrolls.map((payroll) => {
      return {
        ...payroll,
        paymentDateLabel: this.formatTimestamp(payroll.paymentDate),
      };
    });
  }

  formatTimestamp(timestamp: string | null): string {
    if (timestamp) {
      return this.datePipe.transform(timestamp, 'dd/MM/yyyy') || '';
    } else {
      return '';
    }
  }

  close() {
    this.onClose.emit();
  }

  async process(payroll: PayrollResponse) {
    this.reportService
      .processPayslip({
        clientId: this.clientId,
        collaboratorKeys: this.allSelectedUsers.map(
          (user) => user.collaborator_key
        ),
        paymentCode: payroll.paymentCode,
      })
      .pipe(take(1))
      .subscribe((result) => {
        if (result) {
          this.toastComponent.showSuccessCustomMessage(
            `O holerite está sendo gerado. Esse processo pode levar alguns minutos.`,
            '',
            3000
          );
          this.close();
        }
      });
  }
}

function onlyUnique(
  value: GroupBeneficiaryResponseDTO,
  index: number,
  array: GroupBeneficiaryResponseDTO[]
) {
  return (
    array.findIndex(
      (v) => v.id === value.id && v.personaType === value.personaType
    ) === index
  );
}
