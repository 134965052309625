<p-dialog
  [(visible)]="visible"
  [style]="{ width: '950px', height: '700px' }"
  class="medium-l"
  (onHide)="close()"
  [modal]="true"
  [draggable]="false"
  [resizable]="false"
  [ngClass]="{ 'medium-mobile': globalFunctions.isMobile() }"
>
  <ng-template pTemplate="header" class="mt-3 py-0">
    <span class="pl-2 subtitle subtitle-2 mt-2">
      <b> {{ clientId ? "Selecionar Pessoas" : "Associar Pessoas" }}</b>
    </span>
  </ng-template>

  <mat-stepper
    [linear]="true"
    labelPosition="bottom"
    #stepper
    [orientation]="(stepperOrientation | async)!"
    [style]="globalFunctions.isMobile() ? 'margin-left: -30px;' : ''"
    (selectionChange)="!clientId ? getUsers() : null"
    [class.hide-stepper-line]="clientId"
  >
    <mat-step
      *ngIf="!clientId"
      [completed]="selectedClients.length > 0"
      style="margin-top: -30px"
    >
      <ng-template matStepLabel><b>Destinatários</b></ng-template>
      <form>
        <app-search-table
          *ngIf="visible"
          [columnsConfigurations]="columnsConfiguration"
          [tableSourceData]="clientDataSource"
          (onFilter)="searchClientControl.patchValue($event)"
          [showAddButton]="false"
          [disablePagination]="true"
          [onlySearchComponent]="true"
        >
        </app-search-table>
        <div class="container-fluid d-sm-none">
          <div style="margin: 0px 10px 10px 10px">
            <div class="row">
              <div class="col-12">
                <ng-container>
                  <div
                    class="mobile-session"
                    *ngFor="let client of clientDataSource.data; let i = index"
                  >
                    <div
                      class="card-mobile"
                      [ngClass]="client.checked ? 'card-selected' : ''"
                      (click)="client.checked = !client.checked"
                    >
                      <div class="margin-btm">
                        <label
                          class="paragraph paragraph-1 semibold truncate-line"
                          >{{ client.name }}</label
                        >
                      </div>
                    </div>
                  </div>
                </ng-container>
              </div>
            </div>
          </div>
        </div>
      </form>
      <div class="buttons d-flex justify-content-end">
        <div>
          <button
            (click)="goNext(stepper)"
            mat-button
            class="btn btn-primary subtitle subtitle-1 semibold white"
          >
            Próximo
          </button>
        </div>
      </div>
    </mat-step>

    <mat-step style="margin-left: -30px">
      <ng-template *ngIf="!clientId" matStepLabel><b>Pessoas</b></ng-template>

      <form [style]="clientId ? 'height: 460px !important' : 'height: 375px'">
        <div class="ml-4 year-content">
          <mat-form-field *ngIf="clientId" appearance="outline">
            <mat-label class="paragraph paragraph-2 mat-label">Ano</mat-label>
            <input type="number" matInput [formControl]="year" />
          </mat-form-field>
        </div>
        <app-search-table
          *ngIf="visible"
          [columnsConfigurations]="userColumnsConfiguration"
          [tableSourceData]="usersDataSource"
          (onFilter)="searchUserControl.patchValue($event)"
          [showAddButton]="false"
          [disablePagination]="true"
          [onlySearchComponent]="true"
        >
        </app-search-table>
        <div class="container-fluid d-sm-none">
          <div style="margin: 0px 10px 10px 10px">
            <div class="row">
              <div class="col-12">
                <ng-container>
                  <div
                    class="mobile-session"
                    *ngFor="let user of usersDataSource.data; let i = index"
                  >
                    <div
                      class="card-mobile"
                      [ngClass]="user.checked ? 'card-selected' : ''"
                      (click)="user.checked = !user.checked"
                    >
                      <div class="margin-btm">
                        <label
                          class="paragraph paragraph-1 semibold truncate-line"
                          >{{ user.fullName }}</label
                        >
                      </div>
                      <div class="margin-btm">
                        <label class="paragraph paragraph-1 truncate-line"
                          >Empresa: {{ user.clientName }}</label
                        >
                      </div>
                    </div>
                  </div>
                </ng-container>
              </div>
            </div>
          </div>
        </div>
      </form>
      <div
        class="buttons d-flex justify-content-end"
        [style]="globalFunctions.isMobile() ? 'margin-top: 25px;' : ''"
      >
        <div class="mr-2">
          <button *ngIf="!clientId" mat-button matStepperPrevious class="btn btn-secondary subtitle subtitle-1 semibold primary-color">
            Voltar
          </button>
        </div>
        <div>
          <div class="d-flex justify-content-end">
            <button
              [disabled]="clientId ? !year.value : false"
              (click)="onConfirm()"
              mat-button
              class="btn btn-primary subtitle subtitle-1 semibold white"
              [style]="globalFunctions.isMobile() ? 'margin-left: 10px;' : ''"
            >
              {{ clientId ? "Gerar relatório" : "Salvar" }}
            </button>
          </div>
        </div>
      </div>
    </mat-step>
  </mat-stepper>
</p-dialog>
