import { AddressInfo } from "./address-info";
import { Contact } from "./contact";

export class Client {
    id?: number;
    code: string;
    cnpj: string;
    corporateName: string;
    tradingName: string;
    phone: string;
    email: string;
    contacts: Array<Contact>;
    addresses: Array<AddressInfo>;
    isActive: boolean;
    checked?: boolean;
    clientId?: number;

    constructor(cnpj?: string, code?: string, corporateName?:string, tradingName?:string,  phone?:string, email?:string, contacts?: Array<Contact>, addresses?: Array<AddressInfo>, isActive?:boolean) {
        this.cnpj = cnpj || '';
        this.code = code || '';
        this.corporateName = corporateName || '';
        this.tradingName = tradingName || '';
        this.phone = phone || '';
        this.email = email || '';
        this.contacts = contacts || [];
        this.addresses = addresses || [];
        this.isActive = isActive || false;
    }
}