import { Component, OnInit, ViewChild } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxPermissionsService } from 'ngx-permissions';
import { map, mergeMap, of } from 'rxjs';
import { ConfirmModalComponent } from 'src/app/shared/components/confirm-modal/confirm-modal.component';
import { ToastComponent } from 'src/app/shared/components/toaster/toast/toast.component';
import { Masks } from 'src/app/shared/constants/masks';
import { ProfilePermissions } from 'src/app/shared/constants/profile-permissions';
import { ClientDTO } from 'src/app/shared/domains/clientDto';
import { Group } from 'src/app/shared/domains/group';
import { User } from 'src/app/shared/domains/user';
import { GroupUserClientCombobox } from 'src/app/shared/domains/user-client-group/user-client-group';
import { GroupDTO, GroupPage, UserClient } from 'src/app/shared/domains/user-client/user-client';
import { AuthService } from 'src/app/shared/services/auth.service';
import { ClientService } from 'src/app/shared/services/client.service';
import { DataImportService } from 'src/app/shared/services/data-import.service';
import { ImageService } from 'src/app/shared/services/image.service';
import { ProfileService } from 'src/app/shared/services/profile.service';
import { UserClientGroupService } from 'src/app/shared/services/user-client-group/user-client-group.service';
import { UserClientService } from 'src/app/shared/services/user-client.service';
import { GlobalFunctions } from 'src/app/shared/utils/global-functions';
import { ValidatorGlobal } from 'src/app/shared/utils/validator-global';

@Component({
  selector: 'app-user-client',
  templateUrl: './user-client.component.html',
  styleUrls: ['./user-client.component.scss'],
})
export class UserClientComponent implements OnInit {
  readonly masks = Masks;
  isEditionMode = false;

  @ViewChild('profileSort') profileSort!: MatSort;
  profileDataSource = new MatTableDataSource<Group>();
  contentGroupDataSource = new MatTableDataSource<GroupUserClientCombobox>();

  displayedColumns: string[] = ['checkbox', 'name'];
  allProfilesByClients: Group[] = [];
  allContentGroups: GroupUserClientCombobox[] = [];
  checkAll: boolean = false;
  checkAllContentGroups: boolean = false;
  groups: any = [];

  @ViewChild('confirmModal') confirmModal: ConfirmModalComponent | undefined;
  canDelete: boolean = true;
  canEdit: boolean = true;
  canResetPass: boolean = true;
  canCreate: boolean = true;
  canChangeSituation: boolean = true;
  user: User = new User();

  isClientForm: boolean = false;
  canChangeProfile: boolean = true;
  clientId: number | null = null;

  isMyAccount: boolean = false;

  selectedImage: any;
  image!: File | undefined;
  lastImgUri?: string | undefined;
  changeImage: boolean = false;

  form: FormGroup = this.formBuilder.group({
    userData: this.formBuilder.group({
      id: new FormControl(undefined),
      userId: new FormControl(undefined),
      clientId: new FormControl(undefined),
      clientName: new FormControl({ value: undefined, disabled: true }),
      name: new FormControl('', Validators.required),
      cpf: new FormControl('', [
        Validators.required,
        ValidatorGlobal.validateCpf(),
      ]),
      email: new FormControl('', [
        Validators.required,
        ValidatorGlobal.validateEmail(),
      ]),
      situation: new FormControl(false),
      hasBeenDeleted: new FormControl(false),
      uri: new FormControl('')
    }),
    groups: new FormControl([]),
    contentGroups: new FormControl([]),
  });

  constructor(
    private formBuilder: FormBuilder,
    private toastComponent: ToastComponent,
    public globalFunctions: GlobalFunctions,
    private route: ActivatedRoute,
    private router: Router,
    private permissionsService: NgxPermissionsService,
    private userClientService: UserClientService,
    private clientService: ClientService,
    private profileService: ProfileService,
    private sanitizer: DomSanitizer,
    private authService: AuthService,
    private dataImportService: DataImportService,
    private imageService: ImageService,
    private userClientGroupService: UserClientGroupService
  ) {
    this.route.url.subscribe(segments => {
      if (segments.length > 0) {
        this.isMyAccount = this.router['location']._platformLocation.location.href.includes('edit-myaccount');
      }
    })

    route.queryParams
      .pipe(
        map((params) => {
          return Number(params['id']);
        }),
        mergeMap((id) => (id ? this.userClientService.findById(id) : of('')))
      )
      .subscribe(async (user) => {
        if (user) {
          let userClient = user as UserClient;
          this.setEditMode(userClient);
          if (userClient.client.id && userClient.id) {
            await this.fulfillContentGroupsTable(userClient.id, userClient.client.id);
          }
          
        }
      });

    route.queryParams
      .pipe(
        map((params) => Number(params['clientId'])),
        mergeMap((id) => (id ? this.clientService.get(id) : of('')))
      )
      .subscribe(async (client: any) => {
        if (client) {
          this.clientId = client.id;
          let clientName = client.tradingName
            ? client.tradingName
            : client.corporateName;

          this.form.get('userData')?.get('clientId')?.setValue(client.id);
          this.form.get('userData')?.get('clientName')?.setValue(clientName);

          let params = {
            clientId: client.id,
            filter: [""],
            pageSize: 100,
            page: 0,
          };
          let groupList: GroupPage =
            await this.profileService.getFilteredGroupsByClient(params);

          const profiles: Group[] = groupList.content.map(this.mapGroupDTOToGroup)
          this.allProfilesByClients = profiles.filter((group) => {
            return group.isActive === true;
          });

          this.profileDataSource = new MatTableDataSource(
            this.allProfilesByClients
          );

          if (!this.isEditionMode) {
            await this.fulfillContentGroupsTable(0, client.id);
          }
          this.configTable();
        }
      });

  }  

  async ngOnInit() {
    this.canResetPass = await this.permissionsService.hasPermission(
      ProfilePermissions.RESET_PASSWORD_CLIENT_USER
    );

    this.canEdit = this.isMyAccount ?
     await this.permissionsService.hasPermission(ProfilePermissions.UPDATE_MY_ACCOUNT)
     : await this.permissionsService.hasPermission(ProfilePermissions.UPDATE_CLIENT_USER);

    this.canDelete = await this.permissionsService.hasPermission(
      ProfilePermissions.DELETE_CLIENT_USER
    );

    this.canCreate = await this.permissionsService.hasPermission(
      ProfilePermissions.CREATE_CLIENT_USER
    );

    this.canChangeSituation = await this.permissionsService.hasPermission(
      ProfilePermissions.CHANGE_SITUATION_CLIENT_USER
    );

    this.canChangeProfile = await this.permissionsService.hasPermission(
      ProfilePermissions.PROFILE_ASSOCIATION_USER_CLIENT
    );

    if (
      this.route.snapshot.routeConfig?.path?.includes('new-user-client') &&
      this.canCreate
    ) {
      this.canEdit = true;
    }

    const situationFormControl = this.form.get('userData')?.get('situation');
    if (this.canChangeSituation) situationFormControl?.enable();
    else situationFormControl?.disable();

    const userCache = this.authService.getUserFromCache();
    if (userCache) {
      this.user = userCache;
    }
  }

  mapGroupDTOToGroup(groupDTO: GroupDTO): Group {
    return {
      id: groupDTO.id ?? 0,
      name: groupDTO.name,
      isActive: groupDTO['isActive'],
      clientId: groupDTO['clientId'],
      description: groupDTO['description']
    };
  }

  private configTable() {
    if (this.profileSort) {
      this.profileDataSource.sort = this.profileSort;
    }
    this.profileDataSource.sortingDataAccessor = (item: any, property) => {
      return item[property]?.toLowerCase();
    };
  }

  async setEditMode(user: UserClient) {
    this.isEditionMode = true;
    this.isClientForm = this.user.id === user.id;

    const userData = this.form.get('userData');
    let clientName = user.client.tradingName
      ? user.client.tradingName
      : user.client.corporateName;

    this.clientId = user.client.id;

    userData?.get('id')?.setValue(user.id);
    userData?.get('userId')?.setValue(user.idUser);
    userData?.get('clientId')?.setValue(user.client.id);
    userData?.get('clientName')?.setValue(clientName);
    userData?.get('name')?.setValue(user.name);
    userData?.get('cpf')?.setValue(user.cpf);
    userData?.get('cpf')?.disable();
    userData?.get('email')?.setValue(user.email);
    userData?.get('situation')?.setValue(user.isActive);

    let params = {
      clientId: userData?.get('clientId')?.value,
      filter: [""],
      pageSize: 100,
      page: 0,
    };

    let groupList: GroupPage =
      await this.profileService.getFilteredGroupsByClient(params);

    const profiles: Group[] = groupList.content.map(this.mapGroupDTOToGroup)

    this.allProfilesByClients = profiles.filter((group) => {
      return group.isActive === true;
    });

    this.profileDataSource = new MatTableDataSource(this.allProfilesByClients);
    this.configTable();

    this.allProfilesByClients.forEach((group) => {
      const groupId = user.groupIds.find((g: number) => g === group.id);
      if (groupId) {
        group.checked = true;
        this.groups.push(groupId)
      }
    });

    if (user.uri) {
      await this.getImage(user.uri);
      this.lastImgUri = user.uri;
    }

    if (!this.canEdit) this.form.disable();

    const situationFormControl = userData?.get('situation');
    if (this.canChangeSituation) situationFormControl?.enable();
    else situationFormControl?.disable();

    this.verifyCheckToAll();
  }

  groupsHasChanged() {
    this.groupsFormValue();
    if (this.groups.length !== this.form.get('groups')?.value.length) {
      return true;
    }
    return false;
  }

  confirmDelete() {
    this.confirmModal
      ?.showModal(
        'Deseja mesmo excluir este usuário?',
        'Caso confirme, essa ação não poderá ser desfeita.'
      )
      .subscribe(async (isAccepted) => {
        if (isAccepted) {
          const success = await this.userClientService.delete(
            this.clientId ? this.clientId : this.form.get('id')?.value
          );
          if (success) {
            this.toastComponent.showSuccessCustomMessage(
              'Exclusão realizada com sucesso',
              '',
              3000
            );
            this.redirectTo('/users-clients');
          }
        }
      });
  }

  confirmCancel() {
    if (this.form.pristine && !this.groupsHasChanged()) {
      this.redirectTo('/users-clients');
    } else {
      this.confirmModal
        ?.showCancelModal('Deseja mesmo cancelar?', '', true)
        .subscribe((isAccepted) => {
          if (isAccepted) {
            this.redirectTo('/users-clients');
          }
        });
    }
  }

  async checkAllprofiles(value: boolean) {
    if (this.allProfilesByClients.length) {
      this.checkAll = value;
      this.allProfilesByClients.forEach((c) => (c.checked = this.checkAll));
      this.form.get('groups')?.markAsDirty();
    }
  }

  async checkAllGroups(value: boolean) {
    if (this.allContentGroups.length) {
      this.checkAllContentGroups = value;
      this.allContentGroups.forEach((c) => (c.userIsInTheGroup = this.checkAllContentGroups));
      this.form.get('contentGroups')?.markAsDirty();
    }
  }

  checkProfile(group: Group, value: boolean) {
    group.checked = value;
    this.form.get('groups')?.markAsDirty();
    this.verifyCheckToAll();
  }

  checkContentGroup(group: GroupUserClientCombobox, value: boolean) {
    group.userIsInTheGroup = value;
    this.form.get('contentGroups')?.markAsDirty();
    this.verifyCheckToAllContentGroups();
  }

  verifyCheckToAll() {
    this.checkAll = this.profileDataSource.data.every(e => e.checked);
  }

  verifyCheckToAllContentGroups() {
    this.checkAllContentGroups = this.contentGroupDataSource.data.every(e => e.userIsInTheGroup);
  }

  groupsFormValue() {
    const groups = this.form.get('groups');
    if (groups) {
      groups.patchValue(this.allProfilesByClients.filter((g) => g.checked) ?? []);
    }
  }

  contentGroupsFormValue() {
    const groups = this.form.get('contentGroups');
    if (groups) {
      groups.patchValue(this.allContentGroups.filter((g) => g.userIsInTheGroup) ?? []);
    }
  }

  markFormFieldsAsTouched() {
    Object.values(this.form.controls).forEach(control => {
      if (control instanceof FormGroup) {
        Object.values(control.controls).forEach(innerControl => {
          innerControl.markAsTouched();
        });
      } else {
        control.markAsTouched();
      }
    });
  }

  //#region Salvar ou atualizar
  async saveOrUpdate() {
    this.markFormFieldsAsTouched();
    if (this.form.valid) {
      this.groupsFormValue();
      this.contentGroupsFormValue();
      const user = await this.mapFormToDTO();

      if (user.id) {
        try {
          const result = await this.userClientService.update(user, this.image);
          this.toastComponent.showSuccessCustomMessage(
            'Sucesso!',
            'Usuário editado com sucesso.',
            3000
          );
          const loggedUser = this.authService.getUserFromCache();
          if (loggedUser?.id === user?.idUser) {
            this.imageService.changeImageClient(user.id);
          }
          this.redirectTo('/users-clients');
        } catch(e) {
          this.form.get('userData.situation')?.setValue(!user.isActive);
        }
      } else {
        if (await this.userClientService.save(user)) {
          this.toastComponent.showSuccessCustomMessage(
            'Sucesso!',
            'Usuário criado com sucesso.',
            3000
          );
          this.redirectTo('/users-clients');
        }
      }
    } else {
      this.toastComponent.showWarningCustomMessage(
        'Campo(s) obrigatório(s) não preenchido(s)!'
      );
    }
  }

  mapFormToDTO(): UserClient {
    const rawValue = this.form.getRawValue();
    const userData = rawValue?.userData;
    const groups = rawValue?.groups;
    const contentGroups = rawValue?.contentGroups;

    const groupIds = (groups as Group[]).map((group) => group.id);
    const contentGroupIds = (contentGroups as GroupUserClientCombobox[]).filter((group) => group.userIsInTheGroup).map((group) => group.id);

    const client = {
      id: userData.clientId,
    } as ClientDTO;

    return {
      id: userData.id,
      client: client,
      idUser: userData.userId,
      name: userData.name,
      cpf: userData.cpf,
      email: userData.email,
      isActive: userData.situation,
      groupIds,
      contentGroups: contentGroupIds.filter((id): id is number => id !== null),
      hasBeenDeleted: userData.hasBeenDeleted,
      uri: this.lastImgUri
    };
  }
  //#endregion Salvar ou atualizar

  redirectTo(path: string) {
    this.router.navigate([path]);
  }

  get hasPermissions() {
    if (!this.isEditionMode && this.canCreate) return true;
    if (this.isEditionMode && this.canEdit) return true;
    if (this.canChangeSituation) return true;

    return false;
  }

  isNotValidForm(): boolean {
    return this.form.invalid || !this.form.dirty || !this.hasPermissions;
  }

  onImgAdded(event: any): void {
    const file = event.target.files[0];
    this.handleFile(file);
  }

  removeImg(fileInput: any) {
    if (
      this.lastImgUri
    ) {
      this.form.get('userData.hasBeenDeleted')?.setValue(true);
    }
    this.changeImage = true;
    this.selectedImage = null;
    this.image = undefined;
    fileInput.value = '';
  }

  getFileName() {
    return `IMG_${this.form.get('userData.clientName')?.value}`
      .toLocaleUpperCase();
  }

  downloadImage(imageUrl: string, imageName: string) {
    const downloadLink = document.createElement('a');
    downloadLink.href = imageUrl;
    downloadLink.download = imageName;
    document.body.appendChild(downloadLink);
    downloadLink.click();

    document.body.removeChild(downloadLink);
  }

  onDragOver(event: any): void {
    event.preventDefault();
  }

  onDrop(event: any): void {
    event.preventDefault();
    const files = event.dataTransfer.files;
    if (files.length > 0) {
      this.handleFile(files[0]);
    }
  }

  handleFile(file: File): void {
    if (!file) {
      this.toastComponent.showWarningCustomMessage(
        'Erro!',
        'Nenhum arquivo fornecido.'
      );
      return;
    }
    //ADICIONAR VALIDAÇÃO DE JPEG
    const validTypes = ['image/png', 'image/jpeg'];
    //VERIFICAR DIMENSÕES DA FOTO PERMITIDA
    const maxSize = 15 * 1024 * 1024;
    if (!validTypes.includes(file.type)) {
      this.toastComponent.showWarningCustomMessage(
        'Formato Inválido!',
        'Por favor, selecione um arquivo JPEG ou PNG.'
      );
      return;
    }

    if (file.size > maxSize) {
      this.toastComponent.showWarningCustomMessage(
        'Tamaho inválido',
        'Por favor, selecione um arquivo com tamanho máximo de 15mb.'
      );
      return;
    }

    this.removeImg(file);
    this.image = file;
    const reader = new FileReader();
    reader.onload = (e: Event) => {
      const target = e.target as FileReader;
      this.selectedImage = target.result as string;
    };
    reader.onerror = (error) => {
      console.error('Erro ao ler o arquivo:', error);
      this.toastComponent.showWarningCustomMessage(
        'Erro ao ler o arquivo!',
        'Por favor, tente novamente.'
      );
    };
    reader.readAsDataURL(file);
  }

  sanitizeImageUrl(imageUrl: string): SafeUrl {
    return this.sanitizer.bypassSecurityTrustUrl(imageUrl);
  }

  async getImage(imgUrl: string) {
    this.form.get('userClient.uri')?.setValue(imgUrl);

    if (imgUrl) {
      const blob = await this.dataImportService.getBlob(imgUrl);
      const file = new File([blob], 'img', { type: blob.type });
      const reader = new FileReader();
      reader.onload = (e: any) => {
        this.selectedImage = e.target.result;
      };
      reader.readAsDataURL(file);
    }
  }

  resetPassWord() {
    this.confirmModal?.showModal('Resetar senha', 'Deseja mesmo resetar senha do usuário?')
      .subscribe(async isAccepted => {
        if (isAccepted) {
          try {
            const success = await this.userClientService.resetPasswords(
              [this.form.get('userData')?.get('userId')?.value ?? 0],
              this.clientId ?? 0
            );

            if (success) {
              this.toastComponent.showSuccessCustomMessage(`Senha resetada com sucesso`, '', 3000);
            }

          } catch (error: any) {
            if (error.status == 422) {
              this.toastComponent.showWarningCustomMessage('Ops!', error.error.message);
            }
          }
        }
      });
  }

  async fulfillContentGroupsTable(userId: number, clientId: number) { 
    
    let allGroups: GroupUserClientCombobox[] = [];

    if (this.isEditionMode && userId && clientId) {
      allGroups =
      await this.userClientGroupService.getAllByUserIdAndUserClientId(userId, clientId);
      this.allContentGroups = allGroups;
    } else if (!this.isEditionMode && clientId) {
      allGroups =
      await this.userClientGroupService.getAllByUserIdAndUserClientId(null, clientId);
      this.allContentGroups = allGroups;
    }
    else {
      this.toastComponent.showWarningCustomMessage('Buscar grupos de conteúdo', 'Para buscar os grupos é necessário o id do usuário');
    }

    this.contentGroupDataSource = new MatTableDataSource(
      allGroups
    );
  }
  
}
