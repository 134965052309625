<div class="container-fluid" [formGroup]="formValidator"
  *ngIf="!(globalFunctions.isMobile() && showModalAssociateProducts)">
  <div class="margin-15">
    <div class="d-flex align-items-center justify-content-between">
      <label class="titles title-2 semibold extended-secondary-darker" *ngIf="!clientId">
        Novo Cliente
      </label>
      <label class="titles title-2 semibold extended-secondary-darker" *ngIf="clientId && canEdit">
        Editar Cliente
      </label>
      <label class="titles title-2 semibold extended-secondary-darker" *ngIf="clientId && !canEdit">
        Visualizar Cliente
      </label>
    </div>

    <div class="row" style="margin-top: 20px; margin-bottom: 15px">
      <div class="col-12">
        <div class="d-flex p-0 justify-content-between">
          <label class="subtitle subtitle-2 semibold extended-secondary-darker">Dados Empresariais</label>
          <div class="d-flex align-items-center justify-content-between">
            <button
              mat-button
              class="btn btn-secondary subtitle subtitle-1 semibold primary-color"
              style="width: auto"
              (click)="redirectToConfigIntegration()"
              *ngIf="this.clientId && canGoToConfigIntegration"
              [disabled]="!situation"
            >
              Configurar Integrações
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="form-div" formGroupName="businessData">
      <div class="row">
        <div class="col-sm-2">
          <mat-form-field appearance="outline" [class]="(!form.cnpjValid || !!cnpjExist) ? 'mat-form-field-invalid' : 'mat-input'">
            <mat-label class="paragraph paragraph-2 mat-label">CNPJ</mat-label>
            <input matInput mask="00.000.000/0000-00" formControlName="cnpj" [mask]="cnpjMask"
              (input)="form.cnpjValid = true" />
            <mat-hint *ngIf="!form.cnpjValid" class="pass-13 mat-label-error">Número Inválido.</mat-hint>
            <mat-hint *ngIf="!!cnpjExist" class="pass-13 mat-label-error">Cliente já cadastrado.</mat-hint>

            <mat-icon *ngIf="count >= 0" matSuffix (click)="count == 0 ? getDataByCnpj() : 0" class="mat-icon click"
              [class]="count > 0 ? 'cnpj-reload-disabled' : 'cnpj-reload'">refresh</mat-icon>
          </mat-form-field>
        </div>

        <div class="col-sm-2">
          <mat-form-field appearance="outline" class="mat-input">
            <mat-label class="paragraph paragraph-2 mat-label">Inscrição Estadual</mat-label>
            <input matInput [maxlength]="17" formControlName="stateRegistration" [matAutocomplete]="auto" (keypress)="
                globalFunctions.isOnlyNumberRegisterState(
                  $event,
                  formValidator.get('businessData')?.get('stateRegistration')
                )
              " (keydown)="
                globalFunctions.isExemptRegisterState(
                  $event,
                  formValidator.get('businessData')?.get('stateRegistration')
                )
              " />
            <mat-autocomplete #auto="matAutocomplete" (optionSelected)="form.stateRegistrationValid = true">
              <mat-option *ngFor="let op of exempt" [value]="op">
                {{ op }}
              </mat-option>
            </mat-autocomplete>
            <mat-hint *ngIf="!form.stateRegistrationValid" class="pass-13 mat-label-error">Número Inválido.</mat-hint>
          </mat-form-field>
        </div>
        <div class="col-sm-2">
          <mat-form-field appearance="outline" class="mat-input">
            <mat-label class="paragraph paragraph-2 mat-label">CNAE</mat-label>
            <input matInput formControlName="cnae" />
          </mat-form-field>
        </div>
        <div class="col-sm-2">
          <mat-form-field appearance="outline" class="mat-input">
            <mat-label class="paragraph paragraph-2 mat-label">Tipo de Estabelecimento</mat-label>
            <input matInput formControlName="type" />
          </mat-form-field>
        </div>
        <div class="col-sm-2">
          <div class="paragraph paragraph-2 d-flex" style="margin: 3px">
            <label class="pass-13 secondary-neutral">Situação</label>
          </div>
          <div class="d-flex align-items-center" [style]="globalFunctions.isMobile() ? 'margin-bottom: 20px;' : ''">
            <input
              type="checkbox"
              name="togle"
              (click)="!hasPermissions ? $event.preventDefault() : ''"
              id="togle"
              class="switchButton"
              [style]="
                !hasPermissions ? 'cursor: not-allowed; opacity: 40%' : ''
              " formControlName="isActive" />
            <label for="togle" class="label-st">{{
              formValidator.get("businessData")?.get("isActive")?.value
              ? "Ativo"
              : "Inativo"
              }}</label>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-6">
          <mat-form-field appearance="outline" class="mat-input">
            <mat-label class="paragraph paragraph-2 mat-label">Razão Social</mat-label>
            <input matInput formControlName="corporateName" [disabled]="true" />
          </mat-form-field>
        </div>
        <div class="col-sm-6">
          <mat-form-field appearance="outline" class="mat-input">
            <mat-label class="paragraph paragraph-2 mat-label">Nome Fantasia</mat-label>
            <input matInput formControlName="fantasyName" />
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-4">
          <mat-form-field appearance="outline" class="mat-input">
            <mat-label class="paragraph paragraph-2 mat-label">Site</mat-label>
            <input matInput formControlName="site" />
          </mat-form-field>
        </div>
        <div class="col-sm-4">
          <mat-form-field appearance="outline" class="mat-input">
            <mat-label class="paragraph paragraph-2 mat-label">Telefone</mat-label>
            <input matInput [mask]="phoneMask" formControlName="phone" [disabled]="true" />
          </mat-form-field>
        </div>
        <div class="col-sm-4">
          <mat-form-field appearance="outline" class="mat-input">
            <mat-label class="paragraph paragraph-2 mat-label">E-mail</mat-label>
            <input matInput formControlName="email" />
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-2">
          <mat-form-field appearance="outline" [class]="
              !form.businessCepValid ? 'mat-form-field-invalid' : 'mat-input'
            ">
            <mat-label class="paragraph paragraph-2 mat-label">CEP</mat-label>
            <input matInput formControlName="cep" (change)="getDataByCep(true)" [mask]="cepMask"
              (input)="form.businessCepValid = true" />
            <mat-hint *ngIf="!form.businessCepValid" class="pass-13 mat-label-error">Número Inválido.</mat-hint>
          </mat-form-field>
        </div>

        <div class="col-sm-4">
          <mat-form-field appearance="outline" class="mat-input">
            <mat-label class="paragraph paragraph-2 mat-label">Logradouro</mat-label>
            <input matInput formControlName="address" (change)="addressLinkEquals(true)" />
          </mat-form-field>
        </div>

        <div class="col-sm-2">
          <mat-form-field appearance="outline" class="mat-input">
            <mat-label class="paragraph paragraph-2 mat-label">Número</mat-label>
            <input matInput formControlName="number" (change)="addressLinkEquals(true)" />
          </mat-form-field>
        </div>

        <div class="col-sm-4">
          <mat-form-field appearance="outline" class="mat-input">
            <mat-label class="paragraph paragraph-2 mat-label">Complemento</mat-label>
            <input matInput formControlName="complement" (change)="addressLinkEquals(true)" />
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-2">
          <mat-form-field appearance="outline" class="mat-input">
            <mat-label class="paragraph paragraph-2 mat-label">Bairro</mat-label>
            <input matInput formControlName="district" (change)="addressLinkEquals(true)" />
          </mat-form-field>
        </div>

        <div class="col-sm-4">
          <mat-form-field appearance="outline" class="mat-input">
            <mat-label class="paragraph paragraph-2 mat-label">Cidade</mat-label>
            <input matInput formControlName="city" (change)="addressLinkEquals(true)" />
          </mat-form-field>
        </div>
        <div class="col-sm-2">
          <mat-form-field appearance="outline" class="mat-input">
            <mat-label class="paragraph paragraph-2 mat-label">Estado</mat-label>
            <mat-select formControlName="state" [disableOptionCentering]="true" panelClass="mat-select-panel-below"
              (selectionChange)="addressLinkEquals(true)" (openedChange)="searchStateFormControl.setValue('')">
              <div class="search-select">
                <mat-form-field appearance="outline" class="mat-input p-0">
                  <mat-icon matPrefix class="icon-search">search</mat-icon>
                  <mat-label>Buscar Estado</mat-label>
                  <input matInput [formControl]="searchStateFormControl" (keydown.space)="$event.stopPropagation()" />
                </mat-form-field>
              </div>
              <mat-option *ngFor="let state of filteredStates" [value]="state">
                {{ state }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </div>

    <div class="form-div" formGroupName="mailingAddress">
      <div class="row mt-20 mb-15">
        <div class="col-sm-4 d-flex" [style]="globalFunctions.isMobile() ? 'flex-direction: column; align-items: flex-start': 'align-items: center'">
          <label class="subtitle subtitle-2 semibold extended-secondary-darker">Endereço de Correspondência</label>
          <div class="col-sm-2" [style]="globalFunctions.isMobile() ? 'margin: 0px 0px 20px -14px;' : ''">
            <mat-checkbox
              id="checkbox1"
              style="margin-top: -6px"
              [disabled]="!hasPermissions"
              [checked]="
                formValidator
                  .get('mailingAddress')
                  ?.get('isSameAddressAsBusinessAddress')?.value
              " (change)="addressLinkEquals()">
              <label for="checkbox1" class="paragraph paragraph-2 label-checkbox click" (click)="addressLinkEquals()">
                Mesmo endereço do CNPJ
              </label>
            </mat-checkbox>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-2">
          <mat-form-field appearance="outline" [class]="!form.cepValid ? 'mat-form-field-invalid' : 'mat-input'">
            <mat-label class="paragraph paragraph-2 mat-label">CEP</mat-label>
            <input matInput formControlName="cep" (change)="getDataByCep()" [mask]="cepMask"
              (input)="form.cepValid = true" />
            <mat-hint *ngIf="!form.cepValid" class="pass-13 mat-label-error">Número Inválido.</mat-hint>
          </mat-form-field>
        </div>

        <div class="col-sm-4">
          <mat-form-field appearance="outline" class="mat-input">
            <mat-label class="paragraph paragraph-2 mat-label">Logradouro</mat-label>
            <input matInput formControlName="address" />
          </mat-form-field>
        </div>

        <div class="col-sm-2">
          <mat-form-field appearance="outline" class="mat-input">
            <mat-label class="paragraph paragraph-2 mat-label">Número</mat-label>
            <input matInput formControlName="number" />
          </mat-form-field>
        </div>

        <div class="col-sm-4">
          <mat-form-field appearance="outline" class="mat-input">
            <mat-label class="paragraph paragraph-2 mat-label">Complemento</mat-label>
            <input matInput formControlName="complement" />
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-2">
          <mat-form-field appearance="outline" class="mat-input">
            <mat-label class="paragraph paragraph-2 mat-label">Bairro</mat-label>
            <input matInput formControlName="district" />
          </mat-form-field>
        </div>

        <div class="col-sm-4">
          <mat-form-field appearance="outline" class="mat-input">
            <mat-label class="paragraph paragraph-2 mat-label">Cidade</mat-label>
            <input matInput formControlName="city" />
          </mat-form-field>
        </div>
        <div class="col-sm-2">
          <mat-form-field appearance="outline" class="mat-input">
            <mat-label class="paragraph paragraph-2 mat-label">Estado</mat-label>
            <mat-select formControlName="state" [disableOptionCentering]="true" panelClass="mat-select-panel-below"
              (openedChange)="searchStateFormControl.setValue('')">
              <div class="search-select">
                <mat-form-field appearance="outline" class="mat-input p-0">
                  <mat-icon matPrefix class="icon-search">search</mat-icon>
                  <mat-label>Buscar Estado</mat-label>
                  <input matInput [formControl]="searchStateFormControl" (keydown.space)="$event.stopPropagation()" />
                </mat-form-field>
              </div>
              <mat-option *ngFor="let state of filteredStates" [value]="state">
                {{ state }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </div>

    <div class="form-div">
      <div class="row">
        <div class="col-sm-4 col-xl-3">
          <div class="anexo-area mb-4 mb-sm-0">
            <div class="margin-15">
              <div class="row d-flex align-items-start">
                <div class="col-5 d-flex align-items-center">
                  <label class="subtitle subtitle-2 semibold">Anexos</label>
                  <div class="ml-1">
                      <app-custom-tooltip
                      [text]="'Formatos: PDF, DOC, DOCx, XLSx e CSV.\nTamanho máximo: 15MB.'"
                      [tooltipStyleClass]="'info-img'"
                      [tooltipPosition]="'right'"
                      [styleTooltip]="'margin-top: -3rem;'"
                      [styleResponsive]="'margin-left: -20vw; width: 85vw; top: -15vh !important;'">
                      </app-custom-tooltip>
                  </div>
                </div>
                <div class="col-7 col-sm-12 col-xl-7">
                  <button [disabled]="!hasPermissions" mat-button class="btn btn-tertiary subtitle subtitle-1 semibold primary-color" (click)="fileInput.click()">
                    <mat-icon class="icon-btn">attach_file</mat-icon>Adicionar</button>
                  <input [disabled]="!hasPermissions" type="file" multiple #fileInput style="display: none" (change)="onFileAdded($event)" accept=".pdf, .doc, .docx, .xls, .xlsx, .csv" />
                </div>
              </div>
              <div class="row mx-0 mt-2">
                <label class="paragraph paragraph-1 black-font">Inclua aqui os materiais que deseja compartilhar.</label>
              </div>
              <div class="row col-12" class="d-block" style="max-height: 240px; overflow-y: scroll">
                <div class="anexo-file" *ngFor="let file of attachments; let i = index" (click)="downloadImportedFile(file)">
                  <mat-icon class="icon-file">attach_file</mat-icon>
                  <label class="paragraph paragraph-1 semibold click" style="margin-top: 14px">{{ file.name }}</label>
                  <mat-icon class="icon-file click" (click)="hasPermissions ? removeFile(i) : $event.preventDefault()">close</mat-icon>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-sm-4 col-xl-3 mb-4">
          <div class="anexo-area">
            <div class="margin-15">
              <div class="row">
                <div class="col-12 d-flex align-items-center">
                  <label class="subtitle subtitle-2 semibold">Logomarca</label>
                  <div class="ml-2">
                      <app-custom-tooltip
                      [text]="'Utilizar somente a logo branca com fundo transparente, pois será exibida no APP.\nFormatos: JPEG e PNG.\nTamanho máximo: 15MB.'"
                      [tooltipStyleClass]="'tooltip-info'"
                      [tooltipPosition]="'right'"
                      [styleTooltip]="'margin-top: -3rem;'"
                      [styleResponsive]="'margin-left: -30vw; width: 85vw; top: -20vh !important;'">
                      </app-custom-tooltip>
                  </div>
                </div>
                <div class="col-2"></div>
                <div class="col-8">
                  <div class="container-img" (dragover)="onDragOver($event)" (drop)="onDrop($event)">
                    <div class="anexo-area" style="
                        min-width: 100%;
                        margin-bottom: -20px;
                        padding-bottom: 20px;
                      ">
                      <div class="col-12">
                        <div class="row justify-content-end" *ngIf="selectedImage">
                          <mat-icon class="icon-file click" (click)="
                              hasPermissions
                                ? removeImg(imgInput)
                                : $event.preventDefault()
                            ">close</mat-icon>
                        </div>
                      </div>
                      <div class="img-anexo">
                        <div class="d-flex flex-column" *ngIf="!selectedImage">
                          <img src="../../../../assets/img/images.png" />
                          <p class="subtitle-1 text-center">
                            Arraste a imagem aqui ou
                          </p>
                        </div>
                        <img *ngIf="selectedImage" (click)="downloadImage(selectedImage, getFileName())"
                          [src]="sanitizeImageUrl(selectedImage)" alt="Imagem selecionada" class="img-responsive" />
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-12">
                        <button mat-button class="btn btn-secondary subtitle subtitle-1 semibold primary-color"
                          [disabled]="!hasPermissions" (click)="imgInput.click()">
                          Selecione o arquivo</button>&nbsp;
                        <input [disabled]="!hasPermissions" type="file" multiple #imgInput style="display: none"
                          (change)="onImgAdded($event)" accept="image/png, image/jpeg" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="form-div" formGroupName="complementaryData">
      <div class="row mt-20 mb-15">
        <div class="col-sm-3">
          <label class="subtitle subtitle-2 semibold extended-secondary-darker">Dados Complementares</label>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-6 mat-chip-area" *ngIf="!isUserClient()">
          <mat-form-field appearance="outline">
            <mat-label class="paragraph paragraph-2 mat-label">Domínio</mat-label>
            <mat-chip-list #chipList formControlName="domains">
              <span *ngFor="
                  let domain of formValidator
                    .get('complementaryData')
                    ?.get('domains')?.value;
                  let index = index
                ">
                <mat-chip [disabled]="!hasPermissions" [removable]="true" (removed)="removeLabel(index)"
                  [disabled]="isUserClient()" class="custom-label-input">
                  {{ domain.domain }} <mat-icon matChipRemove>cancel</mat-icon>
                </mat-chip>
                <span *ngIf="
                    formValidator
                      .get('complementaryData')
                      ?.get('domains')
                      ?.value.at(index + 1)
                  ">,</span>
              </span>

              <input [disabled]="!hasPermissions || isUserClient()" matInput [matChipInputFor]="chipList"
                [matChipInputAddOnBlur]="true" (matChipInputTokenEnd)="addLabel($event)"
                (keydown.enter)="$event.preventDefault()" />
            </mat-chip-list>
          </mat-form-field>
        </div>
      </div>
    </div>

    <div class="form-div">
      <div style="margin-top: 20px">
        <div class="row">
          <div class="col-12 col-sm-10">
            <label
              class="subtitle subtitle-2 semibold extended-secondary-darker"
              [ngClass]="validatorRequired ? 'validatorRequired' : ''"
              >Contatos *</label
            >
          </div>
          <div class="col-12 col-sm-2 d-flex justify-content-end">
            <button [ngClass]="{
              'w-100': globalFunctions.isMobile(),
              'my-3': globalFunctions.isMobile(),
              validatorRequired: validatorRequired
            }" mat-button class="btn btn-secondary subtitle subtitle-1 semibold primary-color" style="width: auto"
            (click)="showContactModal()" [disabled]="!hasPermissions">
            <mat-icon [ngClass]="
            validatorRequired ? 'validatorRequired' : ''
              " class="icon-btn">add</mat-icon>Novo Contato
          </button>
          </div>
        </div>

        <div class="row" *ngIf="!globalFunctions.isMobile()">
          <div class="col-12">
            <table mat-table [dataSource]="contactDataSource" class="mat-elevation-z8 w-100"
              style="border-bottom: none !important" matSort>
              <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef
                  class="column-30 semibold extended-secondary-darker header-content">
                  Nome
                </th>
                <td mat-cell *matCellDef="let contact" class="column-20">
                  <div [pTooltip]="contact.name" tooltipPosition="left"
                    class="d-flex justify-content-between pr-5 column-category truncate-words">
                    {{ contact.name }}
                    <mat-icon *ngIf="contact.type == 'PRIMARY'" class="icon-secondary-color">star</mat-icon>
                    <mat-icon *ngIf="contact.type == 'SECONDARY'" class="icon-secondary-color">star_border</mat-icon>
                  </div>
                </td>
              </ng-container>

              <ng-container matColumnDef="phone">
                <th mat-header-cell *matHeaderCellDef
                  class="column-15 semibold extended-secondary-darker header-content">
                  Telefone
                </th>
                <td [pTooltip]="
                    contact.phone
                      | mask : globalFunctions.getPhoneMask(contact.phone)
                  " tooltipPosition="left" mat-cell *matCellDef="let contact"
                  class="column-15 column-category truncate-words">
                  {{
                  contact.phone
                  | mask : globalFunctions.getPhoneMask(contact.phone)
                  }}
                </td>
              </ng-container>

              <ng-container matColumnDef="role">
                <th mat-header-cell *matHeaderCellDef
                  class="column-15 semibold extended-secondary-darker header-content">
                  Cargo
                </th>
                <td [pTooltip]="contact.role" tooltipPosition="left" mat-cell *matCellDef="let contact"
                  class="column-15 column-category truncate-words">
                  {{ contact.role }}
                </td>
              </ng-container>

              <ng-container matColumnDef="email">
                <th mat-header-cell *matHeaderCellDef
                  class="column-25 semibold extended-secondary-darker header-content">
                  E-mail
                </th>
                <td [pTooltip]="contact.email" tooltipPosition="left" mat-cell *matCellDef="let contact"
                  class="column-25 column-category truncate-words">
                  {{ contact.email }}
                </td>
              </ng-container>

              <ng-container matColumnDef="actions">
                <th mat-header-cell *matHeaderCellDef class="column-10"></th>
                <td mat-cell *matCellDef="let contact" class="column-10 d-flex justify-content-end">
                  <button mat-icon-button [disabled]="!hasPermissions" pTooltip="Editar" tooltipPosition="top"
                    (click)="showContactModal(contact)">
                    <mat-icon class="icon-secondary-color material-icons-outlined" [ngClass]="{'icon-disabled':!hasPermissions}">edit</mat-icon>
                  </button>
                  <button mat-icon-button [disabled]="!hasPermissions" pTooltip="Excluir" tooltipPosition="top"
                    (click)="deleteContactModal(contact)">
                    <mat-icon class="icon-secondary-color material-icons-outlined" [ngClass]="{'icon-disabled':!hasPermissions}">delete</mat-icon>
                  </button>
                </td>
              </ng-container>
              <tr mat-header-row *matHeaderRowDef="contactDisplayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: contactDisplayedColumns"></tr>
            </table>
          </div>
        </div>

        <div class="row" *ngIf="globalFunctions.isMobile()">
          <div class="col-12">
            <div class="mobile-session" *ngFor="let contact of contactDataSource.data; let i = index;">
              <div class="card-row-mobile" *ngIf="paginationCardContact > i" (click)="showContactModal(contact)">
                <div class="card-mobile-content">
                  <div class="row margin-btm first-row">
                    <div class="col-9" style="padding: 0px !important;">
                      <label class="paragraph paragraph-1 semibold truncate-words">{{ contact.name }}</label>
                    </div>
                    <div class="col-3">
                      <mat-icon
                      *ngIf="contact.type == 'PRIMARY'"
                      class="icon-secondary-color"
                      >star</mat-icon
                    >
                    <mat-icon
                      *ngIf="contact.type == 'SECONDARY'"
                      class="icon-secondary-color"
                      >star_border</mat-icon>
                    </div>
                  </div>
                  <div class="row margin-btm">
                    <label class="paragraph paragraph-1 truncate-words">{{ contact.phone | mask : globalFunctions.getPhoneMask(contact.phone) }}</label>
                  </div>
                  <div class="row margin-btm">
                    <label class="paragraph paragraph-1 truncate-words">{{ contact.contactApp }}</label>
                  </div>
                </div>
              </div>
            </div>
            <div class="row" class="pagination-card" *ngIf="contactDataSource.data.length > paginationCardContact">
              <label class="paragraph paragraph-2 semibold primary-color"
                (click)="paginationCardContact = paginationCardContact + 10;">Mostrar mais</label>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="form-div" formGroupName="link">
      <div class="row mt-20 mb-15">
        <div class="col-sm-3">
          <label class="subtitle subtitle-2 semibold extended-secondary-darker">Vínculo</label>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-2">
          <mat-form-field appearance="outline" class="mat-input">
            <mat-label class="paragraph paragraph-2 mat-label">Vínculo</mat-label>
            <mat-select [disabled]="!hasPermissions" formControlName="clientTypeLink" [disableOptionCentering]="true"
              panelClass="mat-select-panel-below">
              <mat-option *ngFor="let link of links" [value]="link.enumVal">
                {{ link.label }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="col-sm-6">
          <label class="subtitle subtitle-1 extended-secondary-darker semibold" style="width: 100%">{{
            formValidator.get("link")?.get("clientTypeLink")?.value ==
            "GRUPO_ECONOMICO"
            ? "Empresa Administradora"
            : "Matriz"
            }}</label>
          <mat-checkbox id="admin" [disabled]="disableIsOwnCheckbox()"
            style="margin-top: -6px" formControlName="isOwn"
            (click)="cleanClient()">
            <label for="admin" class="paragraph paragraph-2 label-checkbox">A própria</label>
          </mat-checkbox>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-4">
          <mat-form-field appearance="outline" class="mat-input">
            <mat-label class="paragraph paragraph-2 mat-label">Cliente</mat-label>
            <mat-select formControlName="client" [disableOptionCentering]="true" [disabled]="
                formValidator.get('link')?.get('isOwn')?.value ||
                !hasPermissions
              " panelClass="mat-select-panel-below" (openedChange)="searchClientFormControl.setValue('')">
              <div class="search-select" *ngIf="this.generalData.companies.length > 5">
                <mat-form-field appearance="outline" class="mat-input p-0">
                  <mat-icon matPrefix class="icon-search">search</mat-icon>
                  <mat-label>Buscar cliente</mat-label>
                  <input matInput [formControl]="searchClientFormControl" (keydown.space)="$event.stopPropagation()" />
                </mat-form-field>
              </div>
              <mat-option *ngFor="let company of filteredClients" [value]="company.id">
                {{ company.corporateName }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>

      <div class="d-flex align-items-center justify-content-between"
        *ngIf="formValidator.get('link')?.get('isOwn')?.value">
        <label class="subtitle subtitle-2 semibold extended-secondary-darker">Clientes Associados</label>
      </div>
      <div class="row" *ngIf="formValidator.get('link')?.get('isOwn')?.value">
        <div class="col-12" *ngIf="!globalFunctions.isMobile()">
          <table
            mat-table
            #clientsTableSort="matSort"
            [dataSource]="associatesClientsDataSource"
            class="mat-elevation-z8 w-100"
            style="border-bottom: none !important"
            matSort
          >

            <ng-container matColumnDef="cnpj">
              <th mat-header-cell *matHeaderCellDef mat-sort-header
                class="column-20 semibold extended-secondary-darker">
                CNPJ
              </th>
              <td [pTooltip]="client.cnpj | mask : cnpjMask" tooltipPosition="left" mat-cell *matCellDef="let client"
                class="column-20 column-category truncate-words">
                {{ client.cnpj | mask : cnpjMask }}
              </td>
            </ng-container>

            <ng-container matColumnDef="corporateName">
              <th mat-header-cell *matHeaderCellDef mat-sort-header
                class="column-25 semibold extended-secondary-darker">
                Razão Social
              </th>
              <td [pTooltip]="client.corporateName" tooltipPosition="left" mat-cell *matCellDef="let client"
                class="column-25 column-category truncate-words">
                {{ client.corporateName }}
              </td>
            </ng-container>

            <ng-container matColumnDef="city">
              <th mat-header-cell *matHeaderCellDef class="column-20 semibold extended-secondary-darker"
                mat-sort-header>
                Cidade
              </th>
              <td [pTooltip]="address(client.addresses).city" tooltipPosition="left" mat-cell *matCellDef="let client"
                class="column-20 column-category truncate-words">
                {{ address(client.addresses).city }}
              </td>
            </ng-container>

            <ng-container matColumnDef="state">
              <th mat-header-cell *matHeaderCellDef class="column-10 semibold extended-secondary-darker"
                mat-sort-header>
                Estado
              </th>
              <td [pTooltip]="address(client.addresses).uf" tooltipPosition="left" mat-cell *matCellDef="let client"
                class="column-10 column-category truncate-words">
                {{ address(client.addresses).uf }}
              </td>
            </ng-container>

            <ng-container matColumnDef="isActive">
              <th mat-header-cell *matHeaderCellDef class="column-10 semibold extended-secondary-darker"
                mat-sort-header>
                Situação
              </th>
              <td mat-cell *matCellDef="let client" class="column-10">
                <div class="d-flex align-items-center">
                  <input type="checkbox" class="switchButton" checked="{{ client.isActive ? 'checked' : '' }}"
                    disabled />
                  <label class="label-st">{{
                    client.isActive ? "Ativo" : "Inativo"
                    }}</label>
                </div>
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="associatesClientsDisplayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: associatesClientsDisplayedColumns"></tr>
          </table>
        </div>

        <div class="col-12" *ngIf="globalFunctions.isMobile()">
          <div class="mobile-session" *ngFor="let obj of associatesClientsDataSource.data; let i = index;">
            <div class="card-row-mobile" *ngIf="paginationCardClient> i">
              <div class="card-mobile-content">
                <div class="row margin-btm">
                  <label class="paragraph paragraph-1 semibold truncate-words">{{ obj.corporateName }}</label>
                </div>
                <div class="row margin-btm">
                  <label class="paragraph paragraph-1 truncate-words">{{ obj.cnpj | mask : cnpjMask }}</label>
                </div>
                <div class="row margin-btm">
                  <label class="paragraph paragraph-1 truncate-words">{{ obj.email }}</label>
                </div>
              </div>
            </div>
          </div>
          <div class="row" class="pagination-card" *ngIf="associatesClientsDataSource.data.length > paginationCardClient">
            <label class="paragraph paragraph-2 semibold primary-color"
              (click)="paginationCardClient = paginationCardClient + 10;">Mostrar mais</label>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-12 col-sm-2">
          <button
          mat-button
          class="btn btn-tertiary subtitle subtitle-1 semibold primary-color"
          (click)="confirmDelete()"
          [style]="globalFunctions.isMobile() ? 'margin-bottom: 25px' : ''"
          [disabled]="!canDelete"
          *ngIf="clientId && !isUserClient() && !globalFunctions.isMobile()">
          <mat-icon class="icon-btn">delete</mat-icon>Excluir Cliente
        </button>
        <div class="row" *ngIf="globalFunctions.isMobile() && clientId" style="margin-bottom: 20px;">
          <div class="col-12 justify-content-end" style="display: flex; margin-left: -12px">
            <button mat-mini-fab (click)="confirmDelete()"
              class="btn btn-tertiary bg-white subtitle subtitle-1 semibold primary-color">
              <mat-icon class="icon-btn ml-0 material-icons-outlined">delete</mat-icon>
            </button>
          </div>
        </div>
      </div>
      <div class="col-sm-4"></div>
      <div class="col-12 col-sm-2">
          <button
            mat-button
            (click)="saveClient()"
            [disabled]="!hasPermissions"
            class="btn btn-primary subtitle subtitle-1 semibold white"
            *ngIf="globalFunctions.isMobile()"
          >
            Salvar</button
          >&nbsp;
      </div>
      <div class="col-12 col-sm-2">
        <button
          mat-button
          (click)="redirectTo('/clients')"
          class="btn btn-secondary subtitle subtitle-1 semibold primary-color"
        >
          Cancelar</button
        >&nbsp;
      </div>
      <div class="col-12 col-sm-2">
        <button
          mat-button
          (click)="saveClient()"
          [disabled]="!hasPermissions"
          class="btn btn-primary subtitle subtitle-1 semibold white"
          *ngIf="!globalFunctions.isMobile()"
        >
          Salvar</button
        >&nbsp;
      </div>
    </div>
  </div>
</div>

<p-dialog [formGroup]="addNewContactFormGroup" [(visible)]="showModalContact" class="medium" [modal]="true"
  [draggable]="false" [resizable]="false">
  <ng-template pTemplate="header" class="mt-3">
    <label class="subtitle subtitle-2 semibold extended-secondary-darker p-2 mt-2">
      {{ isEditContact ? "Editar Contato" : "Novo Contato" }}
    </label>
  </ng-template>

  <div class="mb-4 p-2">
    <div class="row">
      <div class="col-12">
        <mat-form-field appearance="outline" class="mat-input">
          <mat-label class="paragraph paragraph-2 mat-label">Nome</mat-label>
          <input [required]="true" matInput formControlName="name" />
        </mat-form-field>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-6">
        <mat-form-field appearance="outline" class="mat-input">
          <mat-label class="paragraph paragraph-2 mat-label">Telefone</mat-label>
          <input [required]="true" matInput formControlName="phone" [mask]="phoneMask" />
        </mat-form-field>
      </div>
      <div class="col-sm-6">
        <mat-form-field appearance="outline" class="mat-input">
          <mat-label class="paragraph paragraph-2 mat-label">Cargo</mat-label>
          <input [required]="true" matInput formControlName="role" />
        </mat-form-field>
      </div>
    </div>

    <div class="row">


      <div class="col-sm-12">
        <mat-form-field appearance="outline" class="mat-input">
          <mat-label class="paragraph paragraph-2 mat-label">E-mail</mat-label>
          <input [required]="true" matInput formControlName="email" />
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <mat-radio-group aria-label="Selecione a opção" formControlName="type" [disabled]="lockContactTypeEdition">
          <mat-radio-button value="PRIMARY" class="mr-5">Contato Principal</mat-radio-button>
          <mat-radio-button value="SECONDARY" class="mr-5">Contato Secundário</mat-radio-button>
          <mat-radio-button value="DEFAULT">Contato Padrão</mat-radio-button>
        </mat-radio-group>
      </div>
    </div>
  </div>

    <div class="d-flex" *ngIf="!globalFunctions.isMobile()">
      <div class="col-sm-4">
        <button
        *ngIf="isEditContact"
        mat-button
        class="btn btn-tertiary subtitle subtitle-1 semibold primary-color"
        (click)="deleteContactModal(addNewContactFormGroup.value)"
      >
        <mat-icon class="icon-btn">delete</mat-icon> Excluir Contato</button
      >&nbsp;&nbsp;
      </div>
      <div class="col-sm-2"></div>
      <div class="col-sm-3">
        <button
          mat-button
          class="btn btn-secondary subtitle subtitle-1 semibold primary-color"
          (click)="closeContactModal()"
        >
          Cancelar</button
        >&nbsp;
      </div>
      <div class="col-sm-3">
        <button
          mat-button
          class="btn btn-primary subtitle subtitle-1 semibold white"
          (click)="confirmContactModal()"
          [disabled]="!addNewContactFormGroup.valid"
        >
          {{ isEditContact ? "Atualizar" : "Adicionar" }}</button
        >&nbsp;
      </div>
    </div>

    <div class="col-12" *ngIf="globalFunctions.isMobile()">

      <div class="row justify-content-end" style="margin-bottom: 20px;" *ngIf="isEditContact">
          <button mat-mini-fab (click)="deleteContactModal(addNewContactFormGroup.value)"
          class="btn btn-tertiary bg-white subtitle subtitle-1 semibold primary-color">
          <mat-icon class="icon-btn ml-0 material-icons-outlined">delete</mat-icon>
        </button>
      </div>

      <div class="row">
        <button
          mat-button
          class="btn btn-primary subtitle subtitle-1 semibold white"
          (click)="confirmContactModal()"
          [disabled]="!addNewContactFormGroup.valid"
        >{{ isEditContact ? "Atualizar" : "Adicionar" }}</button>&nbsp;
      </div>

      <div class="row">
        <button
        mat-button
        class="btn btn-secondary subtitle subtitle-1 semibold primary-color"
        (click)="closeContactModal()"
        >Cancelar</button>&nbsp;
      </div>
    </div>
</p-dialog>

<p-dialog header="Header" [(visible)]="showModal" class="medium" [draggable]="false" [resizable]="false">
  <ng-template pTemplate="header">
    <div class="col-11"></div>
  </ng-template>
  <div class="d-block justify-content-center">
    <div class="container-img">
      <img src="./../../../../assets/img/alert.png" />
    </div>
    <p class="text-center semibold">
      Você pode realizar uma nova consulta dentro de {{ count }} segundos.
    </p>
    <p class="text-center">Por favor, aguarde...</p>
  </div>
</p-dialog>

<app-confirm-modal #confirmModal></app-confirm-modal>
