import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ConfirmModalComponent } from 'src/app/shared/components/confirm-modal/confirm-modal.component';
import { Group } from 'src/app/shared/domains/group';
import { ProfileService } from 'src/app/shared/services/profile.service';
import { GlobalFunctions } from 'src/app/shared/utils/global-functions';
@Component({
  selector: 'app-user-client-profile-modal',
  templateUrl: './user-client-profile-modal.component.html',
  styleUrls: ['./user-client-profile-modal.component.scss']
})
export class UserClientProfileModalComponent implements OnInit {
  @Input()
  visible: boolean = false;

  @Input()
  user?: { userId: number, clientId: number };

  @Output()
  onClose = new EventEmitter<boolean>()

  @ViewChild('confirmModal') confirmModal: ConfirmModalComponent | undefined;

  displayedColumns: string[] = ['name', 'isActive'];

  dataSource = new MatTableDataSource<Group>();
  
  constructor(
    public globalFunctions: GlobalFunctions, 
    private profileService: ProfileService
  ) { }

  async ngOnInit() {
    this.getProfiles()
  }

  async getProfiles() {
    let profiles = await this.profileService.getByUserAndClient(
      this.user?.userId ?? 0,
      this.user?.clientId ?? 0
    );
    this.dataSource = new MatTableDataSource(profiles);
  }

  close() {
    this.onClose.emit()
  }
}
