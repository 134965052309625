<div class="container-fluid">
  <div style="margin: 10px; margin-top: 20px;">
    <div class="row">
      <div class="col-9 col-md-4">
        <label class="titles title-2 semibold extended-secondary-darker">
          {{globalFunctions.isUserClient() ? 'Dados Cliente' : 'Clientes'}}
        </label>
      </div>
      <div class="col-3 d-flex justify-content-end" *ngIf="!globalFunctions.isUserClient()">
        <button mat-mini-fab class="btn btn-primary subtitle subtitle-1 semibold white" (click)="redirectToNewClient()"
          *ngIf="globalFunctions.isMobile()">
          <mat-icon class="icon-btn ml-0 mt-1">add</mat-icon>
        </button>
      </div>
    </div>
    <div class="row align-items-center justify-content-between" style="margin-top: 20px;">
      <div class="col-md-3">
        <mat-form-field appearance="outline" class="mat-input">
          <mat-icon matPrefix class="icon-search">search</mat-icon>
          <mat-label class="paragraph paragraph-2 mat-label">Pesquisar</mat-label>
          <input matInput value="" [formControl]="searchClientFormControl">
        </mat-form-field>
      </div>
      <div class="col-9 col-sm-2 d-flex justify-content-end" *ngIf="!globalFunctions.isMobile()">
        <button mat-button class="btn fit-content btn-primary subtitle subtitle-1 semibold white" y
          style="width: auto !important;" [disabled]="!canCreate" *ngIf="!globalFunctions.isUserClient()"
          (click)="redirectToNewClient()">
          <mat-icon class="icon-btn">add</mat-icon>Novo Cliente
        </button>
      </div>
    </div>
    <div class="row" *ngIf="!globalFunctions.isMobile()">
      <div class="col-12">
        <table mat-table #clientTableSort="matSort" [dataSource]="dataSource" class="mat-elevation-z8"
          style="width: 100%;" matSort>
          <ng-container matColumnDef="cnpj">
            <th mat-header-cell *matHeaderCellDef mat-sort-header class="column-15">CNPJ
            </th>
            <td mat-cell *matCellDef="let client" class="column-15 column-category truncate-words">
              <span [pTooltip]="client.cnpj | mask: '00.000.000/0000-00'" tooltipPosition="right">
                {{client.cnpj | mask: '00.000.000/0000-00'}}
              </span>
            </td>
          </ng-container>

          <ng-container matColumnDef="corporateName">
            <th mat-header-cell *matHeaderCellDef mat-sort-header class="column-20">Razão Social
            </th>
            <td mat-cell *matCellDef="let client" class="column-20 column-category truncate-words">
              <span [pTooltip]=client.corporateName tooltipPosition="right">
                {{client.corporateName}}
              </span>
            </td>
          </ng-container>

          <ng-container matColumnDef="tradingName">
            <th mat-header-cell *matHeaderCellDef mat-sort-header class="column-15">Nome Fantasia
            </th>
            <td mat-cell *matCellDef="let client" class="column-15 column-category truncate-words">
              <span [pTooltip]=client.tradingName tooltipPosition="right">
                {{client.tradingName}}
              </span>
            </td>
          </ng-container>

          <ng-container matColumnDef="phone">
            <th mat-header-cell *matHeaderCellDef mat-sort-header class="column-10">Telefone
            </th>
            <td mat-cell *matCellDef="let client" class="column-10 column-category truncate-words">
              <span [pTooltip]="client.phone | mask: globalFunctions.getPhoneMask(client.phone)"
                tooltipPosition="right">
                {{client.phone | mask: globalFunctions.getPhoneMask(client.phone)}}
              </span>
            </td>
          </ng-container>

          <ng-container matColumnDef="email">
            <th mat-header-cell *matHeaderCellDef mat-sort-header class="column-15">E-mail
            </th>
            <td mat-cell *matCellDef="let client" class="column-15 column-category truncate-words">
              <span [pTooltip]=client.email tooltipPosition="right">
                {{client.email}}
              </span>
            </td>
          </ng-container>

          <ng-container matColumnDef="isActive">
            <th mat-header-cell *matHeaderCellDef mat-sort-header class="column-10">Situação</th>
            <td mat-cell *matCellDef="let client" class="column-10">
              <div class="d-flex align-items-center">
                <input [(ngModel)]="client.isActive" [disabled]="!canEdit || globalFunctions.isUserClient()"
                  type="checkbox" name="togle" id="togle" class="switchButton" (click)="confirmChange($event, client)">
                <label class="paragraph paragraph-1 label-st">{{client.isActive ? 'Ativo' : 'Inativo'}}</label>
              </div>
            </td>
          </ng-container>

          <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let client" class="d-flex  justify-content-end pr-2">
              <button *ngIf="canOpenPayrollModal || canOpenModal" mat-icon-button (click)="redirectToLogs(client.id)" pTooltip="Visualização de logs" tooltipPosition="top">
                <mat-icon class="icon-secondary-color material-icons-outlined">ballot</mat-icon>
              </button>
              <button *ngIf="canOpenPayrollModal" mat-icon-button (click)="showPayrollModal(client.id)" pTooltip="Holerite" tooltipPosition="top">
                <mat-icon class="icon-secondary-color material-icons-outlined">payment</mat-icon>
              </button>
              <button *ngIf="canOpenModal" mat-icon-button (click)="showInformeRendimentosModal(client.id)" pTooltip="Informe de Rendimentos" tooltipPosition="top">
                <mat-icon class="icon-secondary-color material-icons-outlined">payments</mat-icon>
              </button>
              <button mat-icon-button *ngIf="canGoToSettings"
                (click)="!client.isActive || !canGoToSettings ? false : redirectToConfigIntegration(client.id)"
                pTooltip="Configurar Integração" tooltipPosition="top">
                <mat-icon class="icon-secondary-color material-icons-outlined"
                  [ngClass]="{'icon-disabled': !client.isActive}">settings</mat-icon>
              </button>
              <button mat-icon-button (click)="showContacts(client.id)" pTooltip="Contatos" tooltipPosition="top">
                <mat-icon class="icon-secondary-color material-icons-outlined">contact_phone</mat-icon>
              </button>
              <button mat-icon-button (click)="redirectToEditClient(client.id)" pTooltip="Editar" tooltipPosition="top"
                *ngIf="canEdit">
                <mat-icon class="icon-secondary-color material-icons-outlined">edit</mat-icon>
              </button>
              <button mat-icon-button (click)="redirectToViewClient(client.id)" pTooltip="Visualizar"
                tooltipPosition="top" *ngIf="!canEdit">
                <mat-icon class="icon-secondary-color material-icons-outlined">visibility</mat-icon>
              </button>
              <button *ngIf="canDelete || !globalFunctions.isUserClient()" mat-icon-button
                (click)="confirmDelete(client)" [disabled]="!canDelete || globalFunctions.isUserClient()"
                pTooltip="Excluir" tooltipPosition="top">
                <mat-icon class="icon-secondary-color material-icons-outlined">delete</mat-icon>
              </button>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
        <mat-paginator [pageSize]="10" [pageSizeOptions]="[5, 10, 25, 100]" [showFirstLastButtons]="true"
          style="margin-bottom: 100px;">
        </mat-paginator>
      </div>
    </div>

    <div class="row" *ngIf="globalFunctions.isMobile()">
      <div class="col-12">
        <div class="mobile-session" *ngFor="let obj of dataSource.data; let i = index;">
          <div class="card-row-mobile" *ngIf="paginationCard > i"
            (click)="canEdit ? redirectToEditClient(obj.id) : redirectToViewClient(obj.id)">
            <div class="card-mobile-content">
              <div class="row margin-btm first-row">
                <label class="paragraph paragraph-1 semibold truncate-words">{{ obj.corporateName }}</label>
              </div>
              <div class="row margin-btm">
                <label class="paragraph paragraph-1 truncate-words">{{ obj.tradingName }}</label>
              </div>
              <div class="row margin-btm">
                <label class="paragraph paragraph-1 truncate-words">{{ obj.cnpj | mask: '00.000.000/0000-00'}}</label>
              </div>
              <div class="row margin-btm">
                <label class="paragraph paragraph-1 truncate-words">{{ obj.phone | mask:
                  globalFunctions.getPhoneMask(obj.phone)}}</label>
              </div>
              <div class="row margin-btm">
                <label class="paragraph paragraph-1 truncate-words">{{ obj.email }}</label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row" class="col-12 pagination-card justify-content-center"
        *ngIf="dataSource.data.length > paginationCard">
        <label class="paragraph paragraph-2 semibold primary-color"
          (click)="paginationCard = paginationCard + 10;">Mostrar mais</label>
      </div>
    </div>
  </div>
</div>

<app-confirm-modal #confirmModal></app-confirm-modal>

<p-dialog [(visible)]="contactModalVisible" [modal]="true" class="large" [style]="{ height: '76vh' }"
  [draggable]="false" [resizable]="false">
  <ng-template pTemplate="header" class="titles title-2 semibold extended-secondary-darker">
    <label class="subtitle subtitle-2 semibold extended-secondary-darker p-2 mt-2">
      Contatos
    </label>
  </ng-template>

  <div class="row">
    <div class="col-12">
      <table mat-table [dataSource]="contactDataSource" class="mat-elevation-z8" style="width: 100%;" matSort>
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef class="column-15 semibold extended-secondary-darker header-content">Nome
          </th>
          <td mat-cell *matCellDef="let contact" class="column-15">
            <div class="d-flex justify-content-between pr-5">
              {{contact.name}}
              <mat-icon *ngIf="contact.type == 'PRIMARY'" class="icon-secondary-color">star</mat-icon>
              <mat-icon *ngIf="contact.type == 'SECONDARY'" class="icon-secondary-color">star_border</mat-icon>
            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="phone">
          <th mat-header-cell *matHeaderCellDef class="column-15 semibold extended-secondary-darker header-content">
            Telefone</th>
          <td mat-cell *matCellDef="let contact" class="column-15">{{contact.phone | mask:
            globalFunctions.getPhoneMask(contact.phone)}}</td>
        </ng-container>

        <ng-container matColumnDef="role">
          <th mat-header-cell *matHeaderCellDef class="column-15 semibold extended-secondary-darker header-content">
            Cargo</th>
          <td mat-cell *matCellDef="let contact" class="column-15">{{contact.role}}</td>
        </ng-container>

        <ng-container matColumnDef="email">
          <th mat-header-cell *matHeaderCellDef class="column-15 semibold extended-secondary-darker header-content">
            E-mail</th>
          <td mat-cell *matCellDef="let contact" class="column-15">{{contact.email}}</td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="contactDisplayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: contactDisplayedColumns;"></tr>
      </table>
    </div>
  </div>
</p-dialog>
 <app-associate-collaborator-modal
    *ngIf="showModalAssociateCollaborators && clientIdSelectedFromRow"
    [visible]="showModalAssociateCollaborators"
    (onSelectValues)="onSelectCollaborators($event)"
    (onClose)="showModalAssociateCollaborators = false"
    [clientId]="clientIdSelectedFromRow"
  ></app-associate-collaborator-modal>

<app-payroll-select
  *ngIf="showModalPayroll && clientIdSelectedFromPayroll"
  [visible]="showModalPayroll"
  (onClose)="showModalPayroll = false"
  [clientId]="clientIdSelectedFromPayroll"
></app-payroll-select>
