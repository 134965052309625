<div class="container-fluid">
  <div class="margin-15">
    <div class="d-flex align-items-center justify-content-between">
      <label class="titles title-2 semibold extended-secondary-darker">
        <span *ngIf="!isMyAccount && !isEditionMode">Novo Usuário</span>
        <span *ngIf="!isMyAccount && isEditionMode && canEdit">Editar Usuário</span>
        <span *ngIf="!isMyAccount && isEditionMode && !canEdit">Visualizar Usuário</span>
        <span *ngIf="isMyAccount">Minha Conta</span>
      </label>
    </div>
    <form [formGroup]="form">
      <div formGroupName="userData">
        <div class="row mt-3">
          <div class="col-sm-4">
            <mat-form-field appearance="outline" class="mat-input">
              <mat-label class="paragraph paragraph-2 mat-label">Cliente</mat-label>
              <input matInput formControlName="clientName" />
            </mat-form-field>
          </div>
        </div>

        <div class="row">
          <div class="col-12 col-sm-4">
            <mat-form-field appearance="outline" class="mat-input">
              <mat-label class="paragraph paragraph-2 mat-label">Nome</mat-label>
              <input matInput formControlName="name" />
            </mat-form-field>
          </div>
          <div class="col-12 col-sm-3">
            <mat-form-field appearance="outline" class="mat-input">
              <mat-label class="paragraph paragraph-2 mat-label">CPF</mat-label>
              <input matInput formControlName="cpf" [mask]="masks.cpfMask" />
            </mat-form-field>
          </div>

          <div class="col-12 col-sm-5">
            <div class="row">
              <div class="col-12 col-sm-9">
                <mat-form-field appearance="outline" class="mat-input">
                  <mat-label class="paragraph paragraph-2 mat-label">E-mail</mat-label>
                  <input matInput formControlName="email" />
                </mat-form-field>
              </div>


              <div class="col-12 col-sm-3" *ngIf="!globalFunctions.isMobile() && !isMyAccount">
                <div class="paragraph paragraph-2 switchPosition">
                  <label class="pass-13 secondary-neutral">Situação</label>
                </div>
                <div class="d-flex align-items-center">
                  <input type="checkbox" name="togle" id="togle" class="switchButton" formControlName="situation" />
                  <label for="togle" class="label-st">
                    {{
                    form.get("userData")?.get("situation")?.value
                    ? "Ativo"
                    : "Inativo"
                    }}
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row form-div" *ngIf="isMyAccount">
          <div class="col-sm-3 mb-4">
            <div class="anexo-area">
              <div class="margin-15">
                <div class="row">
                  <div class="col-12 d-flex align-items-center">
                    <label class="subtitle subtitle-2 semibold extended-secondary-darker">Foto</label>
                    <div class="ml-2">
                      <app-custom-tooltip [text]="'Formato: JPEG ou PNG.\nTamanho máximo: 15MB.'"
                        [tooltipStyleClass]="'info-img'" [tooltipPosition]="'right'"
                        [styleTooltip]="'margin-top: -3rem;'"
                        [styleResponsive]="'margin-left: -15vw; width: 85vw; top: -10vh !important;'">
                      </app-custom-tooltip>
                    </div>
                  </div>
                  <div class="col-2"></div>
                  <div class="col-8">
                    <div class="container-img" (dragover)="onDragOver($event)" (drop)="onDrop($event)">
                      <div class="anexo-area" style="min-width: 100%; margin-bottom: -20px; padding-bottom: 20px;">
                        <div class="col-12">
                          <div class="row justify-content-end" *ngIf="selectedImage">
                            <mat-icon class="icon-file click"
                              (click)="hasPermissions ? removeImg(imgInput) : $event.preventDefault()">close</mat-icon>
                          </div>
                        </div>
                        <div class="img-anexo">
                          <div class="d-flex flex-column" *ngIf="!selectedImage">
                            <img src="../../../../assets/img/images.png" />
                            <p class="subtitle-1 text-center">Arraste a imagem aqui ou</p>
                          </div>
                          <img *ngIf="selectedImage" (click)="downloadImage(selectedImage, getFileName())"
                            [src]="sanitizeImageUrl(selectedImage)" alt="Imagem selecionada" class="img-responsive" />
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-12">
                          <button mat-button class="btn btn-secondary subtitle subtitle-1 semibold primary-color"
                            [disabled]="!hasPermissions" (click)="imgInput.click()">Selecione o arquivo</button>&nbsp;
                          <input [disabled]="!hasPermissions" type="file" multiple #imgInput style="display: none"
                            (change)="onImgAdded($event)" accept="image/png, image/jpeg" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row" *ngIf="!isMyAccount && !globalFunctions.isMobile()">
          <div class="col-6">
            <div class="row">
              <div class="col-12">
                <label class="subtitle subtitle-1 semibold extended-secondary-darker">Perfis associados ao usuário:</label>
              </div>
  
              <div class="col-12" *ngIf="!globalFunctions.isMobile()">
                <table mat-table #profileSort="matSort" [dataSource]="profileDataSource" class="mat-elevation-z8 col-12"
                  w-100 matSort>
                  <ng-container matColumnDef="checkbox">
                    <th mat-header-cell *matHeaderCellDef
                      style="width: 6vw !important; max-width: 6vw !important; align-items: start;">
                      <mat-checkbox [checked]="checkAll" (change)="checkAllprofiles($event.checked)" [disabled]="
                          allProfilesByClients.length == 0 || !canChangeProfile
                        ">
                      </mat-checkbox>
                    </th>
                    <td mat-cell *matCellDef="let group"
                      style="width: 6vw !important; max-width: 6vw !important; align-items: start;">
                      <mat-checkbox [checked]="group.checked" (change)="checkProfile(group, $event.checked)"
                        [disabled]="!canChangeProfile">
                      </mat-checkbox>
                    </td>
                  </ng-container>
  
                  <ng-container matColumnDef="name">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="column-95">
                      Perfil
                    </th>
                    <td mat-cell *matCellDef="let group" class="truncate-words">
                      {{ group.name }}
                    </td>
                  </ng-container>
                  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                  <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
                </table>
              </div>
            </div> 
          </div>

          <!-- #####################  INICIO  ###################### -->

          <div class="col-6">
            <div class="row">
              <div class="col-12">
                <label class="subtitle subtitle-1 semibold extended-secondary-darker">Grupos de conteúdo:</label>
              </div>
    
              <div class="col-12" *ngIf="!globalFunctions.isMobile()">
                <table mat-table #profileSort="matSort" [dataSource]="contentGroupDataSource" class="mat-elevation-z8 col-12"
                  w-100 matSort>
                  <ng-container matColumnDef="checkbox">
                    <th mat-header-cell *matHeaderCellDef
                      style="width: 6vw !important; max-width: 6vw !important; align-items: start;">
                      <mat-checkbox [checked]="checkAllContentGroups" (change)="checkAllGroups($event.checked)" [disabled]="
                          allContentGroups.length == 0 || !canChangeProfile
                        ">
                      </mat-checkbox>
                    </th>
                    <td mat-cell *matCellDef="let group"
                      style="width: 6vw !important; max-width: 6vw !important; align-items: start;">
                      <mat-checkbox [checked]="group.userIsInTheGroup" (change)="checkContentGroup(group, $event.checked)">
                      </mat-checkbox>
                    </td>
                  </ng-container>
    
                  <ng-container matColumnDef="name">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="column-95">
                      Grupo de conteúdo
                    </th>
                    <td mat-cell *matCellDef="let group" class="truncate-words">
                      {{ group.name }}
                    </td>
                  </ng-container>
                  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                  <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
                </table>
              </div>
            </div>
          </div>
        </div>   
        
        
        <div class="row" *ngIf="globalFunctions.isMobile() && !isMyAccount">
          <div class="col-12">
            <label class="subtitle subtitle-1 semibold extended-secondary-darker">Perfis associados ao usuário:</label>
          </div>

          <div class="col-12 pl-2" *ngIf="globalFunctions.isMobile() && !isMyAccount">
            <div *ngFor="let group of profileDataSource.data">
              <div class="d-flex justify-content-between my-3">
                <label class="truncate-words">{{ group.name }}</label>
                <mat-checkbox [checked]="group.checked" (change)="checkProfile(group, $event.checked)">
                </mat-checkbox>
              </div>
            </div>
          </div>

          <div class="col-12">
            <label class="subtitle subtitle-1 semibold extended-secondary-darker">Grupos de conteúdo:</label>
          </div>

          <div class="col-12 pl-2" *ngIf="globalFunctions.isMobile() && !isMyAccount">
            <div *ngFor="let group of contentGroupDataSource.data">
              <div class="d-flex justify-content-between my-3">
                <label class="truncate-words">{{ group.name }}</label>
                <mat-checkbox [checked]="group.userIsInTheGroup" (change)="checkContentGroup(group, $event.checked)">
                </mat-checkbox>
              </div>
            </div>
          </div>
        </div>

        <div class="col-sm-3 d-flex justify-content-between mt-5 p-0" *ngIf="globalFunctions.isMobile() && !isMyAccount">
          <div>
            <div class="paragraph paragraph-2">
              <label class="pass-13 secondary-neutral">Situação</label>
            </div>
            <div class="d-flex align-items-center">
              <input type="checkbox" name="togle" id="togle" class="switchButton" formControlName="situation" />
              <label for="togle" class="label-st">
                {{
                form.get("userData")?.get("situation")?.value
                ? "Ativo"
                : "Inativo"
                }}
              </label>
            </div>
          </div>
          <!-- <div *ngIf="isEditionMode" class="d-flex align-items-end justify-content-end">
            <button mat-mini-fab (click)="confirmDelete()" [disabled]="!canDelete"
              class="btn btn-tertiary subtitle subtitle-1 semibold primary-color bg-white">
              <mat-icon class="icon-btn ml-0 material-icons-outlined">delete</mat-icon>
            </button>
          </div> -->
        </div>
      </div>
    </form>

    <!-- Botões não mobile -->
    <div class="row mt-5" *ngIf="!globalFunctions.isMobile()">
      <div class="col-sm-2">
        <button *ngIf="isEditionMode && !isMyAccount" mat-button
          class="btn btn-tertiary subtitle subtitle-1 semibold primary-color" [disabled]="!canResetPass"
          (click)="resetPassWord()">
          <mat-icon class="icon-btn">key</mat-icon> Resetar Senha
        </button>
      </div>
      <div class="col-sm-2">
        <!-- <button [disabled]="!canDelete" mat-button class="btn btn-tertiary subtitle subtitle-1 semibold primary-color"
          (click)="confirmDelete()" *ngIf="!this.isMyAccount && isEditionMode">
          <mat-icon class="icon-btn">delete</mat-icon>Excluir Usuário
        </button> -->
      </div>
      <div class="col-sm-4"></div>
      <div class="col-sm-2">
        <button mat-button class="btn btn-secondary subtitle subtitle-1 semibold primary-color"
          (click)="confirmCancel()">
          Cancelar</button>&nbsp;
      </div>
      <div class="col-sm-2">
        <button mat-button class="btn btn-primary subtitle subtitle-1 semibold white" (click)="saveOrUpdate()">
          {{!isMyAccount ? 'Salvar' : 'Atualizar'}}</button>&nbsp;
      </div>&nbsp;
    </div>

    <!-- Botões mobile -->
    <div *ngIf="globalFunctions.isMobile()" class="d-block justify-content-between mt-5">
      <div class="row">
        <div class="col-12">
          <button mat-button class="btn btn-primary subtitle subtitle-1 semibold white" (click)="saveOrUpdate()">
            {{!isMyAccount ? 'Salvar' : 'Atualizar'}}</button>&nbsp;
        </div>
        <div class="col-12">
          <button mat-button class="btn btn-secondary subtitle subtitle-1 semibold primary-color"
            (click)="confirmCancel()">
            Cancelar</button>&nbsp;
        </div>
      </div>
    </div>
  </div>
</div>

<app-confirm-modal #confirmModal></app-confirm-modal>
