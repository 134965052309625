<p-dialog
  [(visible)]="visible"
  [style]="{ width: '1300px', height: '700px' }"
  class="medium-l"
  (onHide)="close(false)"
  [modal]="true"
  [draggable]="false"
  [resizable]="false"
  [formGroup]="formPartner"
  [ngClass]="{ 'medium-mobile': globalFunctions.isMobile() }"
>
  <ng-template pTemplate="header" class="mt-3 py-0">
    <span class="pl-2 subtitle subtitle-2 mt-2">
      <b>
        {{
          registerMode === "edit"
            ? "Editar parceiro"
            : registerMode === "view"
            ? "Visualizar parceiro"
            : "Novo parceiro"
        }}
      </b>
    </span>
  </ng-template>

  <mat-stepper
    [linear]="true"
    labelPosition="bottom"
    #stepper
    [orientation]="(stepperOrientation | async)!"
    [style]="globalFunctions.isMobile() ? 'margin-left: -30px;' : ''"
    [class]="!isClientView ? 'admin-header' : ''"
  >
    <ng-container *ngIf="isClientView">
      <mat-step [completed]="selectedClients!.length > 0">
        <ng-template matStepLabel><b>Destinatários</b></ng-template>
        <form>
          <app-search-table
            [columnsConfigurations]="columnsConfiguration"
            [tableSourceData]="clientDataSource"
            (onFilter)="searchClientControl.patchValue($event)"
            [showAddButton]="false"
            [disablePagination]="true"
            [onlySearchComponent]="true"
          >
          </app-search-table>
          <div class="container-fluid d-sm-none">
            <div style="margin: 0px 10px 10px 10px">
              <div class="row">
                <div class="col-12">
                  <ng-container>
                    <div
                      class="mobile-session"
                      *ngFor="
                        let client of clientDataSource.data;
                        let i = index
                      "
                    >
                      <div
                        class="card-mobile"
                        [ngClass]="client.checked ? 'card-selected' : ''"
                        (click)="client.checked = !client.checked"
                      >
                        <div class="margin-btm">
                          <label
                            class="paragraph paragraph-1 semibold truncate-line"
                            >{{ client.name }}</label
                          >
                        </div>
                      </div>
                    </div>
                  </ng-container>
                </div>
              </div>
            </div>
          </div>
        </form>
        <div class="buttons">
          <div>
            <button
              (click)="goNext(stepper)"
              mat-button
              class="btn btn-primary subtitle subtitle-1 semibold white"
            >
              Próximo
            </button>
          </div>
        </div>
      </mat-step>
    </ng-container>

    <mat-step [stepControl]="formPartner" [completed]="formPartner.valid">
      <ng-template matStepLabel><b>Configuração</b></ng-template>
      <form class="container-fluid" [formGroup]="formPartner">
        <div
          class="row"
          [ngClass]="globalFunctions.isMobile() ? 'mb-5' : 'mb-3'"
        >
          <div [ngClass]="globalFunctions.isMobile() ? 'col-12 p-0' : ''">
            <div class="col-12">
              <label
                class="subtitle subtitle-1 semibold extended-secondary-darker"
                >Tipo *</label
              >
            </div>
            <div class="col-12 py-2" style="padding-left: 32px">
              <mat-radio-group
                aria-label="Selecione a opção"
                formControlName="type"
                [ngClass]="
                  globalFunctions.isMobile() ? 'd-flex flex-column' : ''
                "
              >
                <mat-radio-button
                  [ngClass]="globalFunctions.isMobile() ? 'mt-3' : ''"
                  value="PF"
                  class="mr-5"
                  >Pessoa Física
                </mat-radio-button>
                <mat-radio-button
                  [ngClass]="globalFunctions.isMobile() ? 'mt-3 mb-3' : ''"
                  value="PJ"
                  class="mr-5"
                  >Pessoa Jurídica</mat-radio-button
                >
              </mat-radio-group>
            </div>
          </div>
        </div>

        <div
          formGroupName="cpfData"
          *ngIf="formPartner.get('type')?.value == 'PF'"
        >
          <div class="row">
            <div class="col-sm-2">
              <mat-form-field appearance="outline" class="mat-input">
                <mat-label class="paragraph paragraph-2 mat-label"
                  >CPF</mat-label
                >
                <input matInput formControlName="cpf" [mask]="masks.cpfMask" />
              </mat-form-field>
            </div>

            <div class="col-sm-2">
              <mat-form-field appearance="outline" class="mat-input">
                <mat-label class="paragraph paragraph-2 mat-label"
                  >Data de nasc.</mat-label
                >
                <input
                  matInput
                  [matDatepicker]="picker"
                  formControlName="birthDate"
                  appMaskDate
                  maxLength="10"
                />
                <mat-datepicker-toggle
                  matSuffix
                  [for]="picker"
                ></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
              </mat-form-field>
            </div>

            <div class="col-sm-2" *ngIf="!globalFunctions.isMobile()">
              <div class="paragraph paragraph-2 switchPosition">
                <label class="pass-13 secondary-neutral">Situação</label>
              </div>
              <div class="d-flex align-items-center">
                <input
                  type="checkbox"
                  name="togle"
                  id="togle"
                  class="switchButton"
                  formControlName="situation"
                />
                <label for="togle" class="label-st">
                  {{
                    formPartner.get("cpfData")?.get("situation")?.value
                      ? "Ativo"
                      : "Inativo"
                  }}
                </label>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-sm-6">
              <mat-form-field appearance="outline" class="mat-input">
                <mat-label class="paragraph paragraph-2 mat-label"
                  >Nome Completo</mat-label
                >
                <input matInput formControlName="fullName" />
              </mat-form-field>
            </div>

            <div class="col-sm-6">
              <mat-form-field appearance="outline" class="mat-input">
                <mat-label class="paragraph paragraph-2 mat-label"
                  >Nome Comercial</mat-label
                >
                <input matInput formControlName="tradingName" />
              </mat-form-field>
            </div>
          </div>
        </div>

        <div
          formGroupName="cnpjData"
          *ngIf="formPartner.get('type')?.value == 'PJ'"
        >
          <div class="row">
            <div class="col-sm-2">
              <mat-form-field appearance="outline" class="mat-input">
                <mat-label class="paragraph paragraph-2 mat-label"
                  >CNPJ</mat-label
                >
                <input matInput formControlName="cnpj" [mask]="masks.cnpj" />
                <mat-hint
                  *ngIf="
                    formPartner.get('cnpjData')?.get('cnpj')?.invalid &&
                    formPartner.get('cnpjData')?.get('cnpj')?.dirty
                  "
                  class="pass-13 mat-label-error"
                  >Número Inválido.</mat-hint
                >
                <mat-icon
                  *ngIf="count >= 0"
                  matSuffix
                  class="mat-icon click"
                  (click)="retryGetCnpjInfo()"
                  [class]="count > 0 ? 'cnpj-reload-disabled' : 'cnpj-reload'"
                  >refresh</mat-icon
                >
              </mat-form-field>
            </div>

            <div class="col-sm-2">
              <mat-form-field appearance="outline" class="mat-input">
                <mat-label class="paragraph paragraph-2 mat-label"
                  >Inscrição Estadual</mat-label
                >
                <input
                  matInput
                  placeholder=""
                  [maxlength]="17"
                  formControlName="registerState"
                  type="text"
                  [matAutocomplete]="auto"
                  (keypress)="
                    globalFunctions.isOnlyNumberRegisterState(
                      $event,
                      this.formPartner.get('cnpjData')?.get('registerState')
                    )
                  "
                  (keydown)="
                    globalFunctions.isExemptRegisterState(
                      $event,
                      this.formPartner.get('cnpjData')?.get('registerState')
                    )
                  "
                />
                <mat-autocomplete #auto="matAutocomplete">
                  <mat-option *ngFor="let op of exempt" [value]="op">
                    {{ op }}
                  </mat-option>
                </mat-autocomplete>
              </mat-form-field>
            </div>

            <div class="col-sm-2">
              <mat-form-field appearance="outline" class="mat-input">
                <mat-label class="paragraph paragraph-2 mat-label"
                  >Data de Abertura</mat-label
                >
                <input
                  matInput
                  [matDatepicker]="picker"
                  formControlName="openingDate"
                />
                <mat-datepicker-toggle
                  matSuffix
                  [for]="picker"
                ></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
              </mat-form-field>
            </div>

            <div class="col-sm-2" *ngIf="!globalFunctions.isMobile()">
              <div class="paragraph paragraph-2 switchPosition">
                <label class="pass-13 secondary-neutral">Situação</label>
              </div>
              <div class="d-flex align-items-center">
                <input
                  type="checkbox"
                  name="togle"
                  id="togle"
                  class="switchButton"
                  formControlName="situation"
                />
                <label for="togle" class="label-st">
                  {{
                    formPartner.get("cnpjData")?.get("situation")?.value
                      ? "Ativo"
                      : "Inativo"
                  }}
                </label>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-sm-6">
              <mat-form-field appearance="outline" class="mat-input">
                <mat-label class="paragraph paragraph-2 mat-label"
                  >Razão Social</mat-label
                >
                <input matInput formControlName="corporateName" />
              </mat-form-field>
            </div>

            <div class="col-sm-6">
              <mat-form-field appearance="outline" class="mat-input">
                <mat-label class="paragraph paragraph-2 mat-label"
                  >Nome Fantasia</mat-label
                >
                <input matInput formControlName="fantasyName" />
              </mat-form-field>
            </div>
          </div>
        </div>

        <div class="form-div" formGroupName="businessData">
          <div class="row">
            <div class="col-sm-4">
              <mat-form-field appearance="outline" class="mat-input">
                <mat-label class="paragraph paragraph-2 mat-label"
                  >Site</mat-label
                >
                <input matInput formControlName="site" />
              </mat-form-field>
            </div>

            <div class="col-sm-4">
              <mat-form-field appearance="outline" class="mat-input">
                <mat-label class="paragraph paragraph-2 mat-label"
                  >Telefone</mat-label
                >
                <input
                  matInput
                  formControlName="phone"
                  [mask]="masks.phoneMask"
                />
              </mat-form-field>
            </div>

            <div class="col-sm-4">
              <mat-form-field appearance="outline" class="mat-input">
                <mat-label class="paragraph paragraph-2 mat-label"
                  >E-mail</mat-label
                >
                <input matInput formControlName="email" />
              </mat-form-field>
            </div>
          </div>

          <div class="row">
            <div class="col-sm-2">
              <mat-form-field appearance="outline" class="mat-input">
                <mat-label class="paragraph paragraph-2 mat-label"
                  >CEP</mat-label
                >
                <input matInput formControlName="cep" [mask]="masks.cepMask" />
              </mat-form-field>
            </div>

            <div class="col-sm-4">
              <mat-form-field appearance="outline" class="mat-input">
                <mat-label class="paragraph paragraph-2 mat-label"
                  >Logradouro</mat-label
                >
                <input matInput formControlName="streetAddress" />
              </mat-form-field>
            </div>

            <div class="col-sm-2">
              <mat-form-field appearance="outline" class="mat-input">
                <mat-label class="paragraph paragraph-2 mat-label"
                  >Número</mat-label
                >
                <input matInput formControlName="number" />
              </mat-form-field>
            </div>

            <div class="col-sm-4">
              <mat-form-field appearance="outline" class="mat-input">
                <mat-label class="paragraph paragraph-2 mat-label"
                  >Complemento</mat-label
                >
                <input matInput formControlName="complement" />
              </mat-form-field>
            </div>
          </div>

          <div class="row">
            <div class="col-sm-2">
              <mat-form-field appearance="outline" class="mat-input">
                <mat-label class="paragraph paragraph-2 mat-label"
                  >Bairro</mat-label
                >
                <input matInput formControlName="district" />
              </mat-form-field>
            </div>

            <div class="col-sm-4">
              <mat-form-field appearance="outline" class="mat-input">
                <mat-label class="paragraph paragraph-2 mat-label"
                  >Cidade</mat-label
                >
                <input matInput formControlName="city" />
              </mat-form-field>
            </div>

            <div class="col-sm-2">
              <mat-form-field appearance="outline" class="mat-input">
                <mat-label class="paragraph paragraph-2 mat-label"
                  >Estado</mat-label
                >
                <mat-select
                  formControlName="state"
                  [disableOptionCentering]="true"
                  panelClass="mat-select-panel-below"
                  (openedChange)="searchStateFormControl.setValue('')"
                >
                  <div class="search-select">
                    <mat-form-field appearance="outline" class="mat-input p-0">
                      <mat-icon matPrefix class="icon-search">search</mat-icon>
                      <mat-label>Buscar Estado</mat-label>
                      <input matInput [formControl]="searchStateFormControl" />
                    </mat-form-field>
                  </div>
                  <mat-option
                    *ngFor="let state of filteredStates"
                    [value]="state"
                  >
                    {{ state }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
        </div>

        <div class="text-area col-12 p-0 d-sm-none">
          <mat-form-field appearance="outline" class="mat-input">
            <mat-label class="paragraph paragraph-2 mat-label"
              >Apresentação</mat-label
            >
            <textarea
              matInput
              style="min-height: 236px"
              formControlName="presentation"
              cdkTextareaAutosize
              maxLength="400"
              (input)="updatePresentationLength($any($event.target).value)"
            ></textarea>
            <mat-hint
              *ngIf="formPartner.get('presentation')?.invalid"
              class="pass-13 mat-label-error"
            >
              Mínimo de 60 caracteres.
            </mat-hint>
          </mat-form-field>
          <span
            class="d-flex justify-content-end mb-4 pass-13"
            style="margin-top: -21px; color: var(--neutral-600)"
            >{{ presentationLength }}/400</span
          >
        </div>

        <div class="form-div" formGroupName="correspondenceAddress">
          <div class="row mt-20 mb-15">
            <div class="col-sm-4">
              <label
                class="subtitle subtitle-2 semibold extended-secondary-darker"
                >Endereço de Correspondência</label
              >
            </div>

            <div class="col-12 col-sm-2">
              <mat-checkbox
                id="checkbox4"
                style="margin-top: -6px"
                [checked]="formPartner.get('isSameRegistrationAddress')?.value"
                (change)="changeIsSameRegistrationAddress($event.checked)"
                [disabled]="!canEdit"
              >
                <label
                  for="checkbox4"
                  class="paragraph paragraph-2 label-checkbox click"
                  (click)="
                    changeIsSameRegistrationAddress(
                      !formPartner.get('isSameRegistrationAddress')?.value
                    )
                  "
                >
                  Mesmo endereço
                  {{
                    formPartner.get("type")?.value == "PJ"
                      ? "do CNPJ"
                      : "de cadastro"
                  }}
                </label>
              </mat-checkbox>
            </div>
          </div>

          <div
            class="row"
            [style]="globalFunctions.isMobile() ? 'margin-top: 20px;' : ''"
          >
            <div class="col-sm-2">
              <mat-form-field appearance="outline">
                <mat-label class="paragraph paragraph-2 mat-label"
                  >CEP</mat-label
                >
                <input
                  matInput
                  placeholder=""
                  formControlName="cep"
                  [mask]="masks.cepMask"
                />
              </mat-form-field>
            </div>

            <div class="col-sm-4">
              <mat-form-field appearance="outline" class="mat-input">
                <mat-label class="paragraph paragraph-2 mat-label"
                  >Logradouro</mat-label
                >
                <input
                  matInput
                  placeholder=""
                  formControlName="streetAddress"
                />
              </mat-form-field>
            </div>

            <div class="col-sm-2">
              <mat-form-field appearance="outline" class="mat-input">
                <mat-label class="paragraph paragraph-2 mat-label"
                  >Número</mat-label
                >
                <input matInput placeholder="" formControlName="number" />
              </mat-form-field>
            </div>

            <div class="col-sm-4">
              <mat-form-field appearance="outline" class="mat-input">
                <mat-label class="paragraph paragraph-2 mat-label"
                  >Complemento</mat-label
                >
                <input matInput placeholder="" formControlName="complement" />
              </mat-form-field>
            </div>
          </div>

          <div class="row">
            <div class="col-sm-2">
              <mat-form-field appearance="outline" class="mat-input">
                <mat-label class="paragraph paragraph-2 mat-label"
                  >Bairro</mat-label
                >
                <input matInput placeholder="" formControlName="district" />
              </mat-form-field>
            </div>

            <div class="col-sm-4">
              <mat-form-field appearance="outline" class="mat-input">
                <mat-label class="paragraph paragraph-2 mat-label"
                  >Cidade</mat-label
                >
                <input matInput placeholder="" formControlName="city" />
              </mat-form-field>
            </div>

            <div class="col-sm-2">
              <mat-form-field appearance="outline" class="mat-input">
                <mat-label class="paragraph paragraph-2 mat-label"
                  >Estado</mat-label
                >
                <mat-select
                  formControlName="state"
                  [disableOptionCentering]="true"
                  panelClass="mat-select-panel-below"
                  (openedChange)="searchStateFormControl.setValue('')"
                >
                  <div class="search-select">
                    <mat-form-field appearance="outline" class="mat-input p-0">
                      <mat-icon matPrefix class="icon-search">search</mat-icon>
                      <mat-label>Buscar Estado</mat-label>
                      <input matInput [formControl]="searchStateFormControl" />
                    </mat-form-field>
                  </div>
                  <mat-option
                    *ngFor="let state of filteredStates"
                    [value]="state"
                  >
                    {{ state }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
        </div>

        <div class="form-div">
          <div style="margin-top: 20px">
            <div
              class="d-flex align-items-center justify-content-between"
              [ngClass]="{ 'flex-column': globalFunctions.isMobile() }"
            >
              <label
                [ngClass]="{
                  'align-self-start': globalFunctions.isMobile(),
                  validatorContactsRequired: validatorContactsRequired
                }"
                class="subtitle subtitle-2 semibold extended-secondary-darker"
              >
                Contatos *
              </label>

              <ng-container *ngIf="globalFunctions.isMobile()">
                <div
                  [ngClass]="{ 'w-100': globalFunctions.isMobile() }"
                  class="mobile-session mb-2"
                  *ngFor="let element of contactDataSource.data; let i = index"
                >
                  <div
                    class="card-row-mobile"
                    *ngIf="paginationCard > i"
                    (click)="canEdit ? showContactModal(element) : null"
                  >
                    <div class="card-mobile-content">
                      <div class="row margin-btm first-row">
                        <label
                          class="paragraph paragraph-1 semibold truncate-words"
                          >{{ element.name }}</label
                        >
                      </div>
                      <div class="row margin-btm">
                        <label class="paragraph paragraph-1 truncate-words">{{
                          element.phone | mask : masks.phoneMask
                        }}</label>
                      </div>
                      <div class="row margin-btm">
                        <label class="paragraph paragraph-1 truncate-words">{{
                          element.role
                        }}</label>
                      </div>
                      <div
                        class="row margin-btm d-flex justify-content-between"
                      >
                        <label class="paragraph paragraph-1 truncate-words">{{
                          element.email
                        }}</label>
                        <div class="mr-4">
                          <mat-icon
                            *ngIf="element.type == 'PRIMARY'"
                            class="icon-secondary-color"
                            >star</mat-icon
                          >
                          <mat-icon
                            *ngIf="element.type == 'SECONDARY'"
                            class="icon-secondary-color"
                            >star_border</mat-icon
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="row"
                  class="pagination-card"
                  *ngIf="contactDataSource.data.length >= paginationCard"
                >
                  <label
                    class="paragraph paragraph-2 semibold primary-color"
                    (click)="paginationCard = paginationCard + 10"
                    >Mostrar mais</label
                  >
                </div>
              </ng-container>
              <button
                [ngClass]="{
                  'w-100': globalFunctions.isMobile(),
                  'my-3': globalFunctions.isMobile(),
                  validatorContactsRequired: validatorContactsRequired
                }"
                mat-button
                class="btn btn-secondary subtitle subtitle-1 semibold primary-color"
                style="width: auto"
                (click)="$event.preventDefault(); showContactModal()"
                [disabled]="!canEdit"
              >
                <mat-icon
                  [ngClass]="
                    validatorContactsRequired ? 'validatorContactsRequired' : ''
                  "
                  class="icon-btn"
                  >add</mat-icon
                >Novo Contato
              </button>
            </div>

            <div class="row" *ngIf="!globalFunctions.isMobile()">
              <div class="col-12">
                <table
                  mat-table
                  [dataSource]="contactDataSource"
                  class="mat-elevation-z8 w-100"
                  style="border-bottom: none !important"
                  matSort
                >
                  <ng-container matColumnDef="name">
                    <th
                      mat-header-cell
                      *matHeaderCellDef
                      class="column-30 semibold extended-secondary-darker header-content"
                    >
                      Nome
                    </th>
                    <td mat-cell *matCellDef="let contact" class="column-20">
                      <div
                        [pTooltip]="contact.name"
                        tooltipPosition="left"
                        class="d-flex justify-content-between pr-5 column-category truncate-words"
                      >
                        {{ contact.name }}
                        <mat-icon
                          *ngIf="contact.type == 'PRIMARY'"
                          class="icon-secondary-color"
                          >star</mat-icon
                        >
                        <mat-icon
                          *ngIf="contact.type == 'SECONDARY'"
                          class="icon-secondary-color"
                          >star_border</mat-icon
                        >
                      </div>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="phone">
                    <th
                      mat-header-cell
                      *matHeaderCellDef
                      class="column-15 semibold extended-secondary-darker header-content"
                    >
                      Telefone
                    </th>
                    <td
                      [pTooltip]="
                        contact.phone
                          | mask : globalFunctions.getPhoneMask(contact.phone)
                      "
                      tooltipPosition="left"
                      mat-cell
                      *matCellDef="let contact"
                      class="column-15 column-category truncate-words"
                    >
                      {{
                        contact.phone
                          | mask : globalFunctions.getPhoneMask(contact.phone)
                      }}
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="role">
                    <th
                      mat-header-cell
                      *matHeaderCellDef
                      class="column-15 semibold extended-secondary-darker header-content"
                    >
                      Cargo
                    </th>
                    <td
                      [pTooltip]="contact.role"
                      tooltipPosition="left"
                      mat-cell
                      *matCellDef="let contact"
                      class="column-15 column-category truncate-words"
                    >
                      {{ contact.role }}
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="email">
                    <th
                      mat-header-cell
                      *matHeaderCellDef
                      class="column-25 semibold extended-secondary-darker header-content"
                    >
                      E-mail
                    </th>
                    <td
                      [pTooltip]="contact.email"
                      tooltipPosition="left"
                      mat-cell
                      *matCellDef="let contact"
                      class="column-25 column-category truncate-words"
                    >
                      {{ contact.email }}
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="actions">
                    <th
                      mat-header-cell
                      *matHeaderCellDef
                      class="column-10"
                    ></th>
                    <td
                      mat-cell
                      *matCellDef="let contact"
                      class="column-10 p-0 d-flex justify-content-end"
                    >
                      <button
                        mat-icon-button
                        pTooltip="Editar"
                        tooltipPosition="top"
                        (click)="showContactModal(contact)"
                        [disabled]="!canEdit"
                      >
                        <mat-icon
                          class="icon-secondary-color material-icons-outlined"
                          >edit</mat-icon
                        >
                      </button>
                      <button
                        mat-icon-button
                        pTooltip="Excluir"
                        tooltipPosition="top"
                        (click)="deleteContactModal(contact)"
                        [disabled]="!canEdit"
                      >
                        <mat-icon
                          class="icon-secondary-color material-icons-outlined"
                          >delete</mat-icon
                        >
                      </button>
                    </td>
                  </ng-container>
                  <tr
                    mat-header-row
                    *matHeaderRowDef="contactDisplayedColumns"
                  ></tr>
                  <tr
                    mat-row
                    *matRowDef="let row; columns: contactDisplayedColumns"
                  ></tr>
                </table>
              </div>
            </div>
          </div>
        </div>

        <div class="row" style="margin-top: 16px; margin-bottom: 15px">
          <div class="col-12">
            <label
              class="subtitle subtitle-2 semibold extended-secondary-darker"
              >Apresentação</label
            >
          </div>
        </div>
        <div class="form-div">
          <div class="row">
            <div class="col-xl-6">
              <div class="anexo-area mb-4" style="max-height: 336px">
                <div class="margin-15">
                  <div class="row d-flex align-items-start">
                    <div class="col-5 d-flex align-items-center">
                      <label class="subtitle subtitle-2 semibold">Anexos</label>
                      <div class="ml-1">
                        <app-custom-tooltip
                          [text]="
                            'Formatos: PDF, DOC, DOCx, XLSx e CSV.\nTamanho máximo: 15MB.'
                          "
                          [tooltipStyleClass]="'info-img'"
                          [tooltipPosition]="'right'"
                          [styleTooltip]="'margin-top: -3rem;'"
                          [styleResponsive]="
                            'margin-left: -20vw; width: 85vw; top: -13vh !important;'
                          "
                        >
                        </app-custom-tooltip>
                      </div>
                    </div>
                    <div class="col-7 col-sm-12 col-xl-7">
                      <button
                        mat-button
                        class="btn btn-tertiary subtitle subtitle-1 semibold primary-color"
                        (click)="fileInput.click()"
                        [disabled]="!canEdit"
                      >
                        <mat-icon class="icon-btn">attach_file</mat-icon
                        >Adicionar</button
                      >&nbsp;
                      <input
                        type="file"
                        multiple
                        #fileInput
                        style="display: none"
                        (change)="onFileAdded($event)"
                        accept=".pdf, .doc, .docx, .xls, .xlsx, .csv"
                      />
                    </div>
                  </div>

                  <div class="row mx-0 mt-2">
                    <label class="paragraph paragraph-1 black-font"
                      >Inclua aqui os materiais que deseja compartilhar.</label
                    >
                  </div>

                  <div
                    class="row col-12"
                    class="d-block"
                    style="max-height: 160px; overflow-y: scroll"
                  >
                    <div
                      class="anexo-file"
                      *ngFor="let file of attachments; let i = index"
                      (click)="downloadImportedFile(file)"
                    >
                      <mat-icon class="icon-file">attach_file</mat-icon>
                      <label
                        class="paragraph paragraph-1 semibold click"
                        style="margin-top: 14px"
                        >{{ file.name }}</label
                      >
                      <mat-icon class="icon-file click" (click)="removeFile(i)"
                        >close</mat-icon
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-xl-6 mb-4">
              <div class="anexo-area">
                <div class="margin-15">
                  <div class="row">
                    <div class="col-12 d-flex align-items-center">
                      <label class="subtitle subtitle-2 semibold"
                        >Foto ou Logomarca</label
                      >
                      <div class="ml-2">
                        <app-custom-tooltip
                          [text]="
                            'Formatos: JPEG e PNG.\nTamanho máximo: 15MB.'
                          "
                          [tooltipStyleClass]="'info-img'"
                          [tooltipPosition]="'right'"
                          [styleTooltip]="'margin-top: -3rem;'"
                          [styleResponsive]="
                            'margin-left: -45vw; width: 85vw; top: -10vh !important;'
                          "
                        >
                        </app-custom-tooltip>
                      </div>
                    </div>
                    <div class="col-2"></div>
                    <div class="col-8">
                      <div
                        class="container-img"
                        (dragover)="onDragOver($event)"
                        (drop)="onDrop($event)"
                      >
                        <div
                          class="anexo-area"
                          style="
                            min-width: 100%;
                            margin-bottom: -20px;
                            padding-bottom: 20px;
                          "
                        >
                          <div class="col-12">
                            <div
                              class="row justify-content-end"
                              *ngIf="selectedImage"
                            >
                              <mat-icon
                                class="icon-file click"
                                (click)="removeImg(imgInput)"
                                >close</mat-icon
                              >
                            </div>
                          </div>
                          <div class="img-anexo">
                            <div
                              class="d-flex flex-column"
                              *ngIf="!selectedImage"
                            >
                              <img src="../../../../assets/img/images.png" />
                              <p class="subtitle-1 text-center">
                                Arraste a imagem aqui ou
                              </p>
                            </div>
                            <img
                              *ngIf="selectedImage"
                              class="click"
                              (click)="
                                downloadImage(selectedImage, getFileName())
                              "
                              [src]="sanitizeImageUrl(selectedImage)"
                              alt="Imagem selecionada"
                              class="img-responsive"
                            />
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-12">
                            <button
                              mat-button
                              class="btn btn-secondary subtitle subtitle-1 semibold primary-color"
                              (click)="imgInput.click()"
                              [disabled]="!canEdit"
                            >
                              Selecione o arquivo</button
                            >&nbsp;
                            <input
                              type="file"
                              multiple
                              #imgInput
                              style="display: none"
                              (change)="onImgAdded($event)"
                              accept="image/png, image/jpeg"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-12 text-area d-none d-sm-flex flex-column">
              <mat-form-field appearance="outline" class="mat-input">
                <mat-label class="paragraph paragraph-2 mat-label"
                  >Apresentação</mat-label
                >
                <textarea
                  matInput
                  style="min-height: 345px"
                  formControlName="presentation"
                  cdkTextareaAutosize
                  maxLength="400"
                  (input)="updatePresentationLength($any($event.target).value)"
                ></textarea>
                <mat-hint
                  *ngIf="formPartner.get('presentation')?.invalid"
                  class="pass-13 mat-label-error"
                >
                  Mínimo de 60 caracteres.
                </mat-hint>
              </mat-form-field>
              <span
                class="d-flex justify-content-end mb-3 pass-13"
                style="margin-top: -21px; color: var(--neutral-600)"
                >{{ presentationLength }}/400</span
              >
            </div>
          </div>
        </div>

        <div class="row" style="margin-top: 16px; margin-bottom: 15px">
          <div class="col-12">
            <label
              class="subtitle subtitle-2 semibold extended-secondary-darker"
              >Redes Sociais</label
            >
          </div>
        </div>

        <div class="form-div" formGroupName="socialNetworks">
          <div class="row">
            <div class="col-sm-4">
              <mat-form-field appearance="outline" class="mat-input">
                <i
                  matPrefix
                  class="pi pi-instagram icon-social"
                  style="font-size: 1.5rem"
                ></i>
                <mat-label class="paragraph paragraph-2 mat-label"
                  >Instagram</mat-label
                >
                <input matInput formControlName="instagram" />
              </mat-form-field>
            </div>

            <div class="col-sm-4">
              <mat-form-field appearance="outline" class="mat-input">
                <i
                  matPrefix
                  class="pi pi-facebook icon-social"
                  style="font-size: 1.5rem"
                ></i>
                <mat-label class="paragraph paragraph-2 mat-label"
                  >Facebook</mat-label
                >
                <input matInput formControlName="facebook" />
              </mat-form-field>
            </div>

            <div class="col-sm-4">
              <mat-form-field appearance="outline" class="mat-input">
                <i
                  matPrefix
                  class="pi pi-linkedin icon-social"
                  style="font-size: 1.5rem"
                ></i>
                <mat-label class="paragraph paragraph-2 mat-label"
                  >LinkedIn</mat-label
                >
                <input matInput formControlName="linkedin" />
              </mat-form-field>
            </div>
          </div>
        </div>
        <div class="form-div">
          <div style="margin-top: 20px">
            <div
              class="d-flex align-items-center justify-content-between"
              [ngClass]="{ 'flex-column': globalFunctions.isMobile() }"
            >
              <label
                [ngClass]="{ 'align-self-start': globalFunctions.isMobile() }"
                class="subtitle subtitle-2 semibold extended-secondary-darker"
              >
                Benefícios
              </label>
            </div>
            <div class="row align-items-center" style="margin-top: 20px">
              <div class="col-md-3">
                <mat-form-field appearance="outline" class="mat-input">
                  <mat-icon matPrefix class="icon-search">search</mat-icon>
                  <mat-label class="paragraph paragraph-2 mat-label"
                    >Pesquisar</mat-label
                  >
                  <input
                    matInput
                    value=""
                    [formControl]="searchBenefitFormControl"
                  />
                </mat-form-field>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <ng-container *ngIf="!globalFunctions.isMobile()">
                  <table
                    mat-table
                    #benefitTableSort="matSort"
                    [dataSource]="benefitDataSource"
                    class="mat-elevation-z8"
                    w-100
                    matSort
                  >
                    <ng-container matColumnDef="benefit">
                      <th
                        mat-header-cell
                        *matHeaderCellDef
                        mat-sort-header
                        class="column-75 semibold extended-secondary-darker header-content"
                      >
                        Benefício
                      </th>
                      <td
                        [pTooltip]="benefit.benefit"
                        tooltipPosition="left"
                        mat-cell
                        *matCellDef="let benefit"
                        class="column-15 column-category truncate-words"
                      >
                        <div class="d-flex justify-content-between pr-5">
                          {{ benefit.benefit }}
                        </div>
                      </td>
                    </ng-container>

                    <ng-container matColumnDef="validity">
                      <th
                        mat-header-cell
                        *matHeaderCellDef
                        mat-sort-header
                        class="column-75 semibold extended-secondary-darker header-content"
                      >
                        Validade
                      </th>
                      <td
                        [pTooltip]="
                          benefit.startValidityFormatted +
                          ' a ' +
                          (!benefit.endValidity
                            ? '00/00/0000'
                            : benefit.endValidityFormatted)
                        "
                        tooltipPosition="left"
                        mat-cell
                        *matCellDef="let benefit"
                        class="column-20 column-category truncate-words"
                      >
                        {{ benefit.startValidityFormatted }} a
                        {{
                          !benefit.endValidity
                            ? "00/00/0000"
                            : benefit.endValidityFormatted
                        }}
                      </td>
                    </ng-container>

                    <ng-container matColumnDef="isActive">
                      <th
                        mat-header-cell
                        *matHeaderCellDef
                        mat-sort-header
                        class="column-15 semibold extended-secondary-darker header-content"
                      >
                        Situação
                      </th>
                      <td mat-cell *matCellDef="let benefit" class="column-10">
                        <div class="d-flex align-items-center">
                          <input
                            checked="{{ benefit.isActive ? 'checked' : '' }}"
                            type="checkbox"
                            name="togle"
                            id="togle"
                            class="switchButton"
                            (click)="confirmSituationChange($event, benefit)"
                          />
                          <label class="paragraph paragraph-1 label-st">{{
                            benefit.isActive ? "Ativo" : "Inativo"
                          }}</label>
                        </div>
                      </td>
                    </ng-container>

                    <tr
                      mat-header-row
                      *matHeaderRowDef="displayedBenefitColumns"
                    ></tr>
                    <tr
                      mat-row
                      *matRowDef="let row; columns: displayedBenefitColumns"
                    ></tr>
                  </table>
                  <mat-paginator
                    class="mb-3"
                    [pageSize]="10"
                    [pageSizeOptions]="[5, 10, 25, 100]"
                    [showFirstLastButtons]="true"
                  >
                  </mat-paginator>
                </ng-container>
                <ng-container *ngIf="globalFunctions.isMobile()">
                  <div
                    class="mobile-session"
                    *ngFor="
                      let benefit of benefitDataSource.data;
                      let i = index
                    "
                  >
                    <div class="card-row-mobile" *ngIf="paginationCard > i">
                      <div class="card-mobile-content">
                        <div class="row margin-btm first-row truncate-words">
                          <label
                            class="paragraph paragraph-1 semibold truncate-words"
                            >{{ benefit.benefit }}</label
                          >
                        </div>
                        <div class="row margin-btm">
                          <label class="paragraph paragraph-1">
                            {{ benefit.startValidity | date : "dd/MM/yyyy" }} a
                            {{
                              !benefit.endValidity
                                ? "00/00/0000"
                                : (benefit.endValidity | date : "dd/MM/yyyy")
                            }}
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="row"
                    class="pagination-card"
                    *ngIf="benefitDataSource.data.length > paginationCard"
                  >
                    <label
                      class="paragraph paragraph-2 semibold primary-color"
                      (click)="paginationCard = paginationCard + 10"
                      >Mostrar mais</label
                    >
                  </div>
                </ng-container>
              </div>
            </div>
          </div>
        </div>

        <div
          class="row my-5"
          formGroupName="{{
            formPartner.get('type')?.value == 'PF' ? 'cpfData' : 'cnpjData'
          }}"
          *ngIf="globalFunctions.isMobile()"
        >
          <div class="col-6">
            <div class="paragraph paragraph-2 switchPosition">
              <label class="pass-13 secondary-neutral">Situação</label>
            </div>
            <div class="d-flex align-items-center">
              <input
                type="checkbox"
                name="togle"
                id="togle"
                class="switchButton"
                formControlName="situation"
              />
              <label for="togle" class="label-st">
                {{
                  formPartner.get("type")?.value == "PF"
                    ? formPartner.get("cpfData")?.get("situation")?.value
                      ? "Ativo"
                      : "Inativo"
                    : formPartner.get("cnpjData")?.get("situation")?.value
                    ? "Ativo"
                    : "Inativo"
                }}
              </label>
            </div>
          </div>

          <div
            *ngIf="isEditionMode"
            class="col-6 d-flex align-items-end justify-content-end"
          >
            <button
              mat-mini-fab
              (click)="confirmDelete()"
              [disabled]="!canDelete"
              class="btn btn-tertiary subtitle subtitle-1 semibold primary-color bg-white"
            >
              <mat-icon class="icon-btn ml-0 material-icons-outlined"
                >delete</mat-icon
              >
            </button>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-2" *ngIf="!globalFunctions.isMobile()">
            <button
              mat-button
              class="btn btn-tertiary subtitle subtitle-1 semibold primary-color"
              (click)="confirmDelete()"
              *ngIf="isEditionMode"
              [disabled]="!canDelete"
            >
              <mat-icon class="icon-btn">delete</mat-icon>Excluir Parceiro
            </button>
          </div>

          <div class="col-sm-6"></div>

          <div class="col-sm-2" *ngIf="!globalFunctions.isMobile()">
            <button
              mat-button
              (click)="confirmCancel()"
              class="btn btn-secondary subtitle subtitle-1 semibold primary-color"
            >
              Cancelar</button
            >&nbsp;
          </div>

          <div class="col-sm-2">
            <button
              mat-button
              (click)="saveOrUpdate()"
              class="btn btn-primary subtitle subtitle-1 semibold white"
            >
              Salvar</button
            >&nbsp;
          </div>

          <div class="col-sm-2" *ngIf="globalFunctions.isMobile()">
            <button
              mat-button
              (click)="confirmCancel()"
              class="btn btn-secondary subtitle subtitle-1 semibold primary-color"
            >
              Cancelar</button
            >&nbsp;
          </div>
        </div>
      </form>
    </mat-step>
  </mat-stepper>
</p-dialog>

<app-confirm-modal #confirmModal></app-confirm-modal>

<p-dialog
  header="Header"
  [(visible)]="showCnpjSearchErrorModal"
  class="medium"
  [draggable]="false"
  [resizable]="false"
>
  <ng-template pTemplate="header">
    <div class="col-11"></div>
  </ng-template>
  <div class="d-block justify-content-center">
    <div class="container-img">
      <img src="./../../../../assets/img/alert.png" />
    </div>
    <p class="text-center semibold">
      Você pode realizar uma nova consulta dentro de
      {{ this.count }} segundos.
    </p>
    <p class="text-center">Por favor, aguarde...</p>
  </div>
</p-dialog>

<p-dialog
  [formGroup]="addNewContactFormGroup"
  [(visible)]="showModalContact"
  class="medium"
  [modal]="true"
  [draggable]="false"
  [resizable]="false"
>
  <ng-template pTemplate="header" class="mt-3">
    <label
      class="subtitle subtitle-2 semibold extended-secondary-darker p-2 mt-2"
    >
      {{ isEditContact ? "Editar Contato" : "Novo Contato" }}
    </label>
  </ng-template>

  <div class="p-2" [ngClass]="{ 'mb-4': !globalFunctions.isMobile() }">
    <div class="row">
      <div class="col-12">
        <mat-form-field appearance="outline" class="mat-input">
          <mat-label class="paragraph paragraph-2 mat-label">Nome</mat-label>
          <input
            [required]="true"
            matInput
            placeholder=""
            formControlName="name"
          />
        </mat-form-field>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-6">
        <mat-form-field appearance="outline" class="mat-input">
          <mat-label class="paragraph paragraph-2 mat-label"
            >Telefone</mat-label
          >
          <input
            [required]="true"
            matInput
            placeholder=""
            formControlName="phone"
            [mask]="masks.phoneMask"
          />
        </mat-form-field>
      </div>
      <div class="col-sm-6">
        <mat-form-field appearance="outline" class="mat-input">
          <mat-label class="paragraph paragraph-2 mat-label">Cargo</mat-label>
          <input
            [required]="true"
            matInput
            placeholder=""
            formControlName="role"
          />
        </mat-form-field>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-12">
        <mat-form-field appearance="outline" class="mat-input">
          <mat-label class="paragraph paragraph-2 mat-label">E-mail</mat-label>
          <input
            [required]="true"
            matInput
            placeholder=""
            formControlName="email"
          />
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <mat-radio-group
          aria-label="Selecione a opção"
          formControlName="type"
          [disabled]="lockContactTypeEdition"
          [ngClass]="{ 'd-flex flex-column': globalFunctions.isMobile() }"
        >
          <mat-radio-button
            [ngClass]="globalFunctions.isMobile() ? 'mt-3' : ''"
            value="PRIMARY"
            class="mr-5"
            >Contato Principal</mat-radio-button
          >
          <mat-radio-button
            [ngClass]="globalFunctions.isMobile() ? 'mt-3 mb-3' : ''"
            value="SECONDARY"
            class="mr-5"
            >Contato Secundário</mat-radio-button
          >
          <mat-radio-button value="DEFAULT">Contato Padrão</mat-radio-button>
        </mat-radio-group>
      </div>
    </div>

    <div class="row mt-4" *ngIf="isEditContact && globalFunctions.isMobile()">
      <div class="col-sm-12 d-flex align-items-end justify-content-end">
        <button
          mat-mini-fab
          (click)="deleteContactModal(addNewContactFormGroup.value)"
          class="btn btn-tertiary subtitle subtitle-1 semibold primary-color bg-white"
        >
          <mat-icon class="icon-btn ml-0 material-icons-outlined"
            >delete</mat-icon
          >
        </button>
      </div>
    </div>
  </div>

  <ng-template pTemplate="footer">
    <div
      [ngClass]="
        !globalFunctions.isMobile() ? 'd-flex justify-content-between' : ''
      "
    >
      <button
        *ngIf="isEditContact && !globalFunctions.isMobile()"
        mat-button
        class="btn btn-tertiary subtitle subtitle-1 semibold primary-color"
        (click)="deleteContactModal(addNewContactFormGroup.value)"
      >
        <mat-icon class="icon-btn">delete</mat-icon> Excluir contato</button
      >&nbsp;&nbsp;

      <div
        *ngIf="!globalFunctions.isMobile()"
        class="d-flex justify-content-end"
      >
        <button
          mat-button
          class="btn btn-secondary subtitle subtitle-1 semibold primary-color"
          (click)="closeContactModal()"
        >
          Cancelar</button
        >&nbsp;

        <button
          mat-button
          class="btn btn-primary subtitle subtitle-1 semibold white"
          (click)="confirmContactModal()"
          [disabled]="!addNewContactFormGroup.valid"
        >
          {{ isEditContact ? "Atualizar" : "Adicionar" }}</button
        >&nbsp;
      </div>

      <div *ngIf="globalFunctions.isMobile()" class="d-flex row">
        <div class="col-sm-12">
          <button
            mat-button
            class="btn btn-primary subtitle subtitle-1 semibold white w-100"
            (click)="confirmContactModal()"
            [disabled]="!addNewContactFormGroup.valid"
          >
            {{ isEditContact ? "Atualizar" : "Adicionar" }}</button
          >&nbsp;
        </div>

        <div class="col-sm-12">
          <button
            mat-button
            class="btn btn-secondary subtitle subtitle-1 semibold primary-color w-100"
            (click)="closeContactModal()"
          >
            Cancelar</button
          >&nbsp;
        </div>
      </div>
    </div>
  </ng-template>
</p-dialog>
